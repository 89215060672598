import React, {ReactNode, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {BackgroundWipe, IconScrollTop} from '../icons';
import {Header} from '../components/header';
import {Footer} from '../components/footer';
import {sendYandexAction} from '../helpers/sendYandexAction';

interface IMainLayoutProps {
    children: ReactNode;
    title: string;
    form?: JSX.Element;
}

export const MainLayout = ({children, title, form}: IMainLayoutProps) => {
    const location = useLocation() as any;
    const [showScrollTop, setShowScrollTop] = useState<boolean>(false);

    useEffect(() => {
        if (location.pathname !== location?.state?.prevPathName) {
            sendYandexAction('hit', window.location.href);
        }

        switch (location.pathname) {
            case '/company':
                sendYandexAction('reachGoal', 'company-head');
                break;
            case '/licence':
                sendYandexAction('reachGoal', 'licence-head');
                break;
            case '/kp':
                sendYandexAction('reachGoal', 'ads-head');
                break;
            case '/feedback':
                sendYandexAction('reachGoal', 'contact-head');
                break;
            default:
                sendYandexAction('reachGoal', 'main');
                break;
        }
    }, [location]);

    useEffect(() => {
        handleScrollTop();
    }, []);

    const handleScrollTop = () => {
        window.addEventListener('scroll', function () {
            this.pageYOffset > 400 ? setShowScrollTop(true) : setShowScrollTop(false);
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {/*<FavoriteMessage/>*/}
            <div className={'content'}>
                <BackgroundWipe/>
                {/*<UpdateMessage />*/}
                <Header/>
                <div className={location.pathname === '/' ? 'page page--home' : 'page'}>
                    <div className={'page__content'}>
                        <div className={'page-head-wrapper'}>
                            <h2 className={'page-head'}>{title}</h2>
                        </div>
                        {children}
                    </div>
                </div>
                <Footer/>
                {location.pathname !== '/' && showScrollTop ? <span className={'scroll-top'} onClick={handleScrollTop}>
                    <IconScrollTop/>
                </span> : null}
            </div>
            {form ? form : null}
        </>
    );
};
