import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import {IconOffer1, IconOffer2, IconOffer3} from '../../icons';
import {useResizeWindow} from '../../hooks';
import {Badge} from './components/badge';
import {Form} from './components/form';
import {Banner} from './components/banner';
import {Bars} from './components/bars';
import {Offer} from './components/offer';
import {TabsDesktop} from './components/tabs/tabsDesktop';
import {TabsMobile} from './components/tabs/tabsMobile';
import './style.scss';
export const Partner = () => {
    const width = useResizeWindow();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <MainLayout title={''}>
            <div className={'partner'}>
                <div className={'container'}>
                    {/*<h1 className={'partner__title'}>*/}
                    {/*    <span className={'partner__title--insert'}>Умный Эколог – сервис,</span> где заказчики находят*/}
                    {/*    исполнителей*/}
                    {/*</h1>*/}
                    <h1 className={'partner__title'}>
                        <span className={'partner__title--insert'}>Размещайтесь там,</span> где вас ищут!
                    </h1>

                    <div className={'presentation'}>
                        <div className={'presentation__inner'}>
                            <div className={'presentation__box'}>
                                <p className={'presentation__text-big'}>51 000</p>
                                <p className={'presentation__text presentation__text--w50'}>посетителей пользуются сервисом ежемесячно</p>
                            </div>
                            <div className={'presentation__box'}>
                                <p className={'presentation__text-big'}>
                                    2 года</p>
                                <p className={'presentation__text'}>приводим клиентов для компаний в сфере обращения с
                                    отходами без затрат на маркетинг</p>
                            </div>
                        </div>
                    </div>
                    <Badge/>
                </div>
                    <Banner />
                <div className={'container'}>
                    {width > 991 ? <TabsDesktop/> : <TabsMobile />}
                    <Bars />
                    <Offer />
                </div>
                <div className={'offer'}>
                    <div className={'container'}>
                        <div className={'offer__inner'}>
                            <div className={'offer__box'}>
                                <h3 className={'offer__title'}>Привлекайте новых клиентов!</h3>
                                <ul className={'offer__list'}>
                                    <li className={'offer__item'}>
                                        <div className={'offer__icon'}>
                                            <IconOffer1/>
                                        </div>
                                        <p className={'offer__text'}>
                                            <span className={'offer__text--insert'}>Карточка </span>
                                            вашей компании
                                            <span className={'offer__text--insert'}> вверху </span>
                                            поисковой выдачи
                                        </p>
                                    </li>
                                    <li className={'offer__item'}>
                                        <div className={'offer__icon'}>
                                            <IconOffer2/>
                                        </div>
                                        <p className={'offer__text'}>
                                            <span className={'offer__text--insert'}>Вы заметнее конкурентов </span>
                                            благодаря заполненной карточке
                                        </p>
                                    </li>
                                    <li className={'offer__item'}>
                                        <div className={'offer__icon'}>
                                            <IconOffer3/>
                                        </div>
                                        <p className={'offer__text'}>
                                            <span className={'offer__text--insert'}>Персональный помощник </span>
                                            по любым вопросам на весь период размещения
                                        </p>
                                    </li>
                                </ul>
                                <p className={'offer__subtext'}>
                                    Оставьте заявку – мы вышлем вам счет на размещение
                                    в течение 30 минут
                                </p>
                            </div>
                            <div className={'offer__box'}>
                                <Form/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </MainLayout>
    );
};
