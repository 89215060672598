import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {GoBack} from '../../components/goback';
import {clearCurrentUtilise} from '../../components/card/store/card.slice';
import {Card} from '../../components/card';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {MainLayout} from '../../layouts';

export const CardPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {scrollToLicense, currentUtilize} = useAppSelector(state => state.card);

    useEffect(() => {
        if(scrollToLicense !== '') {
            const element = currentUtilize?.licensesList.find(i => i.number === scrollToLicense);

            if(element) {
                const block = document.getElementById(element.id.toString())?.getBoundingClientRect();
                window.scrollTo({
                    top: block?.top ? block?.top - 10 : 0,
                    behavior: 'smooth',
                });
            }
        } else {
            window.scrollTo({
                top:  0,
                behavior: 'smooth',
            });
        }
    }, [scrollToLicense]);


    const handleGoBack = () => {
        dispatch(clearCurrentUtilise());
        navigate(-1);
    };

    return (
        <MainLayout title={''}>
            <div className={'container'}>
                <div className={'card-page'}>
                    <GoBack onClick={handleGoBack}/>
                    <Card/>
                </div>
            </div>
        </MainLayout>
    );
};
