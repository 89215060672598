import React from 'react';
import {IIconProps} from './types';

export const IconVerification = ({width, height, color}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.3742 4.77155C19.328 4.75175 19.2774 4.73635 19.2224 4.72535C15.8036 4.05215 13.0074 2.41535 10.2552 0.320954C10.0836 0.188954 9.73816 0.184554 9.56656 0.316554C8.30816 1.28455 7.01016 2.22835 5.62856 3.00935C4.11496 3.86515 2.26477 4.26775 0.597165 4.70995C0.152765 4.82655 0.0383652 5.24675 0.152765 5.58775C0.291365 8.76015 0.810565 12.0888 2.13276 14.9774C3.52976 18.0266 6.82316 20.3102 9.75797 21.7006C9.85917 21.749 9.96037 21.771 10.055 21.7732C10.1782 21.7886 10.3102 21.7688 10.4466 21.7006C13.3902 20.2574 16.4086 17.9804 17.898 14.9796C19.317 12.1218 19.7592 8.59295 19.8868 5.42495C19.9 5.04215 19.658 4.82435 19.3742 4.77155ZM16.7188 14.2822C15.4846 16.975 12.6972 19.01 10.099 20.341C7.49856 19.0562 4.84757 17.1444 3.45497 14.5638C2.09317 12.0382 1.67517 8.77115 1.53436 5.88695C3.01057 5.49315 4.48676 5.07075 5.88596 4.45255C7.32916 3.81235 8.66236 2.80035 9.92297 1.82355C12.5586 3.73315 15.2712 5.28635 18.4964 5.99255C18.3424 8.79315 17.8804 11.7478 16.7188 14.2822Z"
                fill={color}/>
            <path
                d="M14.4356 7.51267C12.6514 9.31667 10.8672 11.1185 9.08296 12.9225C8.13696 11.9677 7.19096 11.0107 6.24496 10.0559C5.62236 9.42667 4.65656 10.4013 5.27916 11.0305C6.36816 12.1305 7.45716 13.2305 8.54616 14.3305C8.56156 14.3459 8.57916 14.3613 8.59456 14.3767C8.83436 14.6253 9.23696 14.7177 9.55156 14.3987C11.5008 12.4275 13.45 10.4585 15.4014 8.48727C16.024 7.86027 15.0582 6.88347 14.4356 7.51267Z"
                fill={color}/>
        </svg>
    );
};
