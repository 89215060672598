import React from 'react';
const image = require('../../../../../../assets/images/img-tab3.jpg');
export const Tab3 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-3'}>
                <h3 className={'desktop-tab__title'}>Укажем актуальные контактные данные: телефон, почту,
                    ссылку на сайт</h3>
                <p className={'desktop-tab__text'}>Заказчики быстро свяжутся со специалистами вашей компании.</p>
            </div>
            <div className={'desktop-tab__box'}>
                 <img src={image} alt={'tab-3'} loading={'lazy'} />
            </div>
        </div>
    );
};
