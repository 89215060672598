import React from 'react';

export const IconPen = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.9364 1.61448C15.7916 2.43588 15.7916 3.76764 14.9364 4.58904L5.12929 14.0085L1 15L2.03232 11.0898L11.8434 1.61827C12.6517 0.837948 13.9402 0.795869 14.8003 1.49427L14.9364 1.61448Z"
                  stroke="#3252B1" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.74121 14.9998H16.9998" stroke="#3252B1" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.3877 4.09277L14.42 5.08429" stroke="#3252B1" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
