import React from 'react';
import {IIconProps} from './types';

export const IconPlacing = ({width, height, color = '#04BF8A'}:IIconProps) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill={color}/>
            <path
                d="M12.1382 19.4055L13.0741 24.6049H23.369C23.5423 22.9758 23.9097 19.6342 23.9929 19.3014C24.0969 18.8855 23.7849 18.8855 23.369 18.5735C23.0362 18.3239 22.8143 18.6775 22.745 18.8855C22.7104 18.9201 22.537 19.0518 22.1211 19.3014C21.7051 19.551 21.0465 19.1974 20.7692 18.9894C20.5266 18.8855 19.9581 18.6567 19.6253 18.5735C19.2094 18.4695 18.7934 18.8855 18.3774 18.8855C18.0447 18.8855 17.8922 19.3014 17.8575 19.5094C17.6495 19.6134 17.1296 19.759 16.7136 19.5094C16.2977 19.2598 16.5403 18.7122 16.7136 18.4696C17.0256 18.1576 17.6911 17.5337 17.8575 17.5337C18.0655 17.5337 18.0655 16.8057 17.7535 16.7017C17.5039 16.6185 17.2336 16.7363 17.1296 16.8057L16.7136 17.2216C16.5472 17.388 15.8817 17.2216 15.5697 17.1176C15.0151 16.979 13.8435 16.6601 13.5939 16.4938C13.3444 16.3274 13.2126 16.4244 13.178 16.4938C13.1433 16.7364 13.0741 17.2841 13.0741 17.5337C13.0741 17.8456 12.6581 18.3656 12.3462 18.4696C12.0966 18.5528 12.1035 19.1282 12.1382 19.4055Z"
                fill="white"/>
            <path
                d="M20.0413 17.1176C19.8749 17.284 20.1106 17.6029 20.2493 17.7416C20.4572 17.7416 20.7692 17.9495 20.9772 18.0535C21.1852 18.1575 21.7051 18.2615 22.1211 18.1575C22.4538 18.0743 22.537 17.8456 22.537 17.7416C22.5024 17.5683 22.4538 17.1592 22.537 16.9097C22.6202 16.6601 22.3637 16.459 22.2251 16.3897C22.0517 16.3204 21.6219 16.1817 21.2892 16.1817C20.9564 16.1817 20.8039 16.3204 20.7692 16.3897L20.0413 17.1176Z"
                fill="white"/>
            <path
                d="M17.3376 14.4139C17.2544 14.9962 17.6495 15.1418 17.8575 15.1418C18.1348 15.2458 18.7726 15.5162 19.1054 15.7657C19.4381 16.0153 19.7986 15.6617 19.9373 15.4538L21.1852 14.2059C21.6011 13.7899 21.7051 13.374 21.8091 13.062C21.8923 12.8124 21.7051 12.1954 21.6011 11.9182C21.6011 11.8488 21.5179 11.7102 21.1852 11.7102C20.8524 11.7102 20.6999 11.9181 20.6652 12.0221L19.6253 12.2301C19.1054 12.3341 18.8974 12.1261 18.6894 11.9182C18.4814 11.7102 18.1695 11.5022 17.9615 11.7102C17.7951 11.8766 17.8228 12.4728 17.8575 12.7501C17.7188 13.062 17.4207 13.8316 17.3376 14.4139Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.9023 10.3641C29.7013 10.0159 29.2561 9.89663 28.908 10.0976L25.9108 11.8281C25.6598 11.973 25.5278 12.2447 25.5489 12.5155C25.5445 12.5351 25.5408 12.555 25.538 12.5751L23.7159 25.5399H12.3062L10.4775 12.5096C10.4704 12.4587 10.4581 12.4097 10.4413 12.3632C10.4042 12.1604 10.2817 11.9746 10.0892 11.8635L7.09201 10.133C6.74385 9.93203 6.29866 10.0513 6.09765 10.3995C5.89663 10.7476 6.01592 11.1928 6.36409 11.3938L9.07007 12.9562L10.9494 26.3472C11.0013 26.7173 11.323 26.9831 11.6878 26.9738V26.9958H24.395V26.982C24.7336 26.9616 25.0223 26.7055 25.0713 26.3566L26.9622 12.9021L29.6359 11.3585C29.9841 11.1574 30.1034 10.7122 29.9023 10.3641Z"
                  fill="white"/>
        </svg>
    );
};
