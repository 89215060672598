import React from 'react';
import {tabsConfig} from '../config';
import {CardItem} from './components';
import './style.scss';

export const TabsMobile = () => {
    return (
        <div className={'tabs-mobile'}>
            <h2  className={'tabs-mobile__title'}>Посмотрите, какой будет карточка вашей компании в глазах клиента</h2>
            {tabsConfig.map((i, index) =>
                <CardItem key={index} color={i.color} title={i.title} text={i.text} description={i.description} icon={i.icon} image={i.image} textMobile={i.textMobile} />)}
        </div>
    );
};
