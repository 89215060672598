import React from 'react';
import {IIconProps} from './types';

export const IconCopy = ({width = 16, height = 19, color}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.467 16.2857H2.64509C2.66964 16.2857 2.69147 16.2773 2.71429 16.2717V17.5089C2.7143 18.3311 3.39847 19 4.23961 19H14.4588C15.3 19 15.9841 18.3311 15.9841 17.5089V3.60225C15.9841 2.77997 15.3 2.11111 14.4588 2.11111H12.6667V1.43782C12.6667 0.644991 12.0087 0 11.1997 0H1.467C0.65796 0 -2.86102e-06 0.644991 -2.86102e-06 1.43782V14.8479C-2.86102e-06 15.6407 0.65796 16.2857 1.467 16.2857ZM15.381 3.60225V17.5089C15.381 17.9983 14.9672 18.3968 14.4588 18.3968H4.23961C3.73127 18.3968 3.31746 17.9983 3.31746 17.5089V3.60225C3.31746 3.11276 3.73127 2.71427 4.23961 2.71427H14.4588C14.9672 2.71427 15.381 3.11276 15.381 3.60225ZM0.603188 1.43782C0.603188 0.977489 0.990758 0.603161 1.467 0.603161H11.1997C11.6759 0.603161 12.0635 0.977489 12.0635 1.43782V2.11111H4.23961C3.39847 2.11111 2.7143 2.77997 2.7143 3.60225V15.6965C2.69147 15.691 2.66964 15.6825 2.64509 15.6825H1.467C0.990758 15.6825 0.603188 15.3082 0.603188 14.8479V1.43782Z"
                fill={color}/>
            <path
                d="M6.03199 15.0791H12.6669C12.8336 15.0791 12.9685 14.9442 12.9685 14.7775C12.9685 14.6108 12.8336 14.4759 12.6669 14.4759H6.03199C5.8653 14.4759 5.7304 14.6108 5.7304 14.7775C5.7304 14.9442 5.8653 15.0791 6.03199 15.0791Z"
                fill={color}/>
            <path
                d="M6.03199 12.667H12.6669C12.8336 12.667 12.9685 12.5321 12.9685 12.3654C12.9685 12.1987 12.8336 12.0638 12.6669 12.0638H6.03199C5.8653 12.0638 5.7304 12.1987 5.7304 12.3654C5.7304 12.5321 5.8653 12.667 6.03199 12.667Z"
                fill={color}/>
            <path
                d="M6.03199 10.2539H12.6669C12.8336 10.2539 12.9685 10.119 12.9685 9.95231C12.9685 9.78562 12.8336 9.65072 12.6669 9.65072H6.03199C5.8653 9.65072 5.7304 9.78562 5.7304 9.95231C5.7304 10.119 5.8653 10.2539 6.03199 10.2539Z"
                fill={color}/>
            <path
                d="M9.04786 7.84082H12.6669C12.8336 7.84082 12.9685 7.70592 12.9685 7.53923C12.9685 7.37253 12.8336 7.23763 12.6669 7.23763H9.04786C8.88116 7.23763 8.74629 7.37253 8.74629 7.53923C8.74629 7.70592 8.88116 7.84082 9.04786 7.84082Z"
                fill={color}/>
        </svg>
    );
};
