import React, {ReactNode} from 'react';
import {
    IconTab1,
    IconTab2,
    IconTab3,
    IconTab4,
    IconTab5,
    IconTab6,
    IconTab7
} from './icons';
const img1 = require('../../../../assets/images/img-tab1.jpg');
const img2 = require('../../../../assets/images/img-tab2.jpg');
const img3 = require('../../../../assets/images/img-tab3.jpg');
const img4 = require('../../../../assets/images/img-tab4.jpg');
const img5 = require('../../../../assets/images/img-tab5.jpg');
const img6 = require('../../../../assets/images/img-tab6.jpg');
const img7 = require('../../../../assets/images/img-tab7.jpg');

export interface ITab {
    title: string;
    text: string;
    icon: ReactNode;
    textMobile?: string;
    description?: string;
    image?: string;
    color?: string;
}

export const tabsConfig: ITab[] = [
    {
        title: 'Поднимем',
        text: 'в результатах поиска',
        textMobile: 'Покажем карточку вашей компании  в результатах поиска выше компаний, размещенных бесплатно',
        description: 'Вспомните, когда вы ищете информацию в поисковых системах, как часто\n' +
            '                    вы переходите на вторую, третью страницы поисковой выдачи? Как правило, вы решаете свой вопрос на\n' +
            '                    первой странице. На нашем сервисе так же - на первые карточки в выдаче приходится в 8 раз больше\n' +
            '                    кликов',
        icon: <IconTab1/>,
        image: img1,
        color: '#E0F7FA'
    },
    {
        title: 'Выделим',
        text: 'в поисковой выдаче',
        textMobile: 'На этапе запроса карточка вашей компании будет выглядеть привлекательней карточки бесплатного размещения',
        description: 'Уже на этапе запроса карточка вашей компании будет выглядеть\n' +
            '                    привлекательней карточки бесплатного размещения. Клиенты будут выбирать вас чаще',
        icon: <IconTab2/>,
        image: img2,
        color: '#E1F5FE'
    },
    {
        title: 'Укажем',
        text: 'актуальные контакты',
        textMobile: 'В карточке компании будут указаны телефон, почта, ссылка на сайт',
        description: 'Заказчики быстро свяжутся со специалистами вашей компании',
        icon: <IconTab3/>,
        image: img3,
        color: '#E3F2FD'
    },
    {
        title: 'Добавим',
        text: 'логотип',
        textMobile: 'Разместим логотип вашей компании в карточке',
        description: 'С логотипом в карточке клиентам вас будет легче запомнить',
        icon: <IconTab4/>,
        image: img4,
        color: '#E8EAF6'
    },
    {
        title: 'Загрузим',
        text: 'фото мест осуществления деятельности',
        textMobile: 'Для всех лицензий компании Добавим фотографии фактических мест осуществления деятельности',
        description: 'Фотографии покажут фактическое существование мест осуществления\n' +
            '                    деятельности. Значит, клиенты могут быть уверены в добросовестном исполнении работ. Это повысит\n' +
            '                    доверие к вашей компании',
        icon: <IconTab5/>,
        image: img5,
        color: '#EDE7F6'
    },
    {
        title: 'Добавим',
        text: 'возможность написания отзывов',
        textMobile: 'Клиенты смогут оставлять в карточке отзывы о сотрудничестве с вашей компанией',
        description: 'Вашу компанию будут выбирать по рекомендациям. Все отзывы тщательно\n' +
            '                    проверяются нашими специалистами перед размещением. На Умном Экологе будет формироваться рейтинг\n' +
            '                    вашей компании по оценкам.',
        icon: <IconTab6/>,
        image: img6,
        color: '#F3E5F5'
    },
    {
        title: 'Разместим',
        text: 'отзывы и благодарственные письма',
        textMobile: 'Загрузим примеры успешно выполненных работ, которые лучше всего расскажут о профессионализме вашей компании',
        description: 'Заказчики читают отзывы, прежде чем выбрать исполнителя. Примеры\n' +
            '                    успешно выполненных работ лучше всего расскажут об уровне профессионализма ваших сотрудников.\n' +
            '                    Хорошие отзывы помогут клиентам сделать выбор в вашу пользу.',
        icon: <IconTab7/>,
        image: img7,
        color: '#FCE4EC'
    },
];
