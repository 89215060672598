import React from 'react';
import {IIconProps} from './types';

export const IconUtil = ({width = 36, height = 36, color= '#04BF8A'}:IIconProps) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill={color}/>
            <path d="M15.0388 17.4966C15.2263 18.3091 14.3357 18.9458 13.6286 18.5005L12.6794 17.9067L10.6911 21.0876C10.1704 21.9204 10.7694 23.0009 11.7517 23.0009H13.0025C13.6915 23.0009 14.2517 23.5603 14.2517 24.2493C14.2517 24.9384 13.6915 25.5013 13.0025 25.5013H11.7556C8.81185 25.5013 7.01732 22.2653 8.57318 19.7669L10.5587 16.5864L9.60716 15.9888C8.89974 15.5435 9.08021 14.4653 9.89349 14.2778L13.465 13.4517C13.8005 13.3794 14.1404 13.5825 14.2185 13.9224L15.0388 17.4966ZM19.0583 11.0849L20.6697 13.6685L19.7224 14.2575C19.013 14.6993 19.1923 15.7786 20.0067 15.9673L23.577 16.7935C23.9138 16.8714 24.2501 16.6612 24.3274 16.3243L25.1493 12.7528C25.3363 11.94 24.4481 11.3056 23.7403 11.7466L22.7887 12.3403L21.1794 9.76378C19.7115 7.41456 16.2888 7.413 14.8201 9.76137L14.531 10.2232C14.1677 10.8032 14.3474 11.5778 14.9255 11.9419C15.5083 12.3075 16.2837 12.1328 16.6486 11.5497L16.9393 11.0853C17.4372 10.2888 18.5818 10.3181 19.0583 11.0849ZM27.4255 19.77L26.7618 18.7052C26.3972 18.12 25.6267 17.9411 25.0415 18.3064C24.4579 18.6703 24.279 19.445 24.6439 20.0282L25.3076 21.0888C25.829 21.9216 25.2301 23.0028 24.2474 23.0028H20.4978L20.4987 21.8802C20.4987 21.045 19.4889 20.6267 18.8983 21.2173L16.3042 23.8122C16.0611 24.0553 16.0611 24.4532 16.3043 24.6962L18.8989 27.2876C19.4895 27.8775 20.4985 27.4589 20.4985 26.6243L20.4976 25.504H24.2421C27.1872 25.5044 28.9841 22.2661 27.4255 19.77Z" fill="white"/>
        </svg>

    );
};
