import React from 'react';

export const IconOffer3 = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M49.9963 24.9982C49.9963 38.8041 38.8045 49.9961 24.9985 49.9963V39.75C24.9989 39.75 24.9992 39.75 24.9996 39.75C29.8135 39.75 33.7592 36.0324 34.1223 31.3115C37.2885 30.9522 39.7484 28.2646 39.7484 25.0023C39.7484 21.7402 37.2886 19.0527 34.1227 18.6932C33.7619 13.9701 29.8151 10.25 24.9996 10.25C24.9992 10.25 24.9989 10.25 24.9985 10.25V0C38.8045 0.000218484 49.9963 11.1922 49.9963 24.9982ZM24.9985 11.75V38.25C24.9989 38.25 24.9992 38.25 24.9996 38.25C29.2245 38.25 32.6496 34.825 32.6496 30.6V19.4C32.6496 15.175 29.2245 11.75 24.9996 11.75C24.9992 11.75 24.9989 11.75 24.9985 11.75Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 24.9982C1.20691e-06 11.1928 11.1908 0.00127672 24.9958 0V10.2502C20.1821 10.2523 16.2375 13.9716 15.8767 18.6933C12.7104 19.0525 10.2502 21.7402 10.2502 25.0026C10.2502 28.5096 13.0932 31.3526 16.6002 31.3526V31.3502C17.0143 31.35 17.3498 31.0143 17.3498 30.6002V30.2502V28.5002V25.0002V19.4002C17.3498 15.1766 20.7727 11.7524 24.9958 11.7502L24.9958 38.2502C24.5834 38.2524 24.2498 38.5874 24.2498 39.0002C24.2498 39.4131 24.5834 39.748 24.9958 39.7502V49.9963C11.1908 49.995 -1.20689e-06 38.8035 0 24.9982Z"
                  fill="#F2F2F2"/>
        </svg>
    );
};
