import React from 'react';
import {ILicense} from '../types';

interface ILicenseProps extends ILicense{
    onClick: (license:string) => void;
}

export const License = (
    {items, onClick}:ILicenseProps
) => {
    return (
        <div className={'license'}>
            <p className={'license__text'}>Лицензия: </p>
            {items.map(i => (
                <span key={i} className={'license__item'} onClick={() => onClick(i)}>{i}</span>
            ))}
        </div>
    );
};
