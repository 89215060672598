import React from 'react';
import {IIconProps} from './types';

export const IconScrollTop = ({width = 52, height = 52, color = '#3252B1'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2 26C2 12.7452 12.7452 2 26 2C39.2548 2 50 12.7452 50 26C50 39.2548 39.2548 50 26 50C12.7452 50 2 39.2548 2 26ZM26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0ZM34.9081 32.4766C35.2987 32.8672 35.9318 32.8672 36.3223 32.4766C36.7129 32.0861 36.7129 31.4529 36.3223 31.0624L26.707 21.447L25.9999 20.7399L25.2927 21.447L16.4786 30.2611L15.6774 31.0624C15.2868 31.4529 15.2868 32.0861 15.6774 32.4766C16.0679 32.8672 16.701 32.8672 17.0916 32.4766L17.8921 31.6761L17.8929 31.6754L25.9998 23.5684L34.9081 32.4766Z"
                  fill={color}/>
        </svg>
    );
};
