import React from 'react';
import {IIconProps} from './types';

export const IconPhone = ({width, height, color}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.8429 18.7705C14.6561 18.77 14.4699 18.7495 14.2874 18.7094C10.8133 17.9907 7.61272 16.3057 5.05405 13.8483C2.54997 11.3854 0.813266 8.24971 0.0540471 4.82049C-0.0354743 4.39311 -0.0135022 3.94988 0.11785 3.53344C0.249201 3.11701 0.485512 2.74139 0.804047 2.44271L3.02627 0.303824C3.13977 0.196484 3.27464 0.114285 3.42206 0.0625922C3.56948 0.0108992 3.72614 -0.00912571 3.88182 0.00382425C4.04325 0.0206648 4.19902 0.072665 4.3382 0.156168C4.47737 0.239671 4.59656 0.352649 4.68738 0.487158L7.46516 4.59271C7.56933 4.75202 7.61961 4.94055 7.60861 5.13057C7.59761 5.32058 7.5259 5.50205 7.40405 5.64827L6.0096 7.31494C6.56496 8.54408 7.35146 9.65498 8.32627 10.5872C9.29477 11.5532 10.4395 12.3245 11.6985 12.8594L13.4263 11.4872C13.5714 11.3725 13.748 11.3047 13.9325 11.2928C14.1171 11.2809 14.301 11.3254 14.4596 11.4205L18.654 14.1316C18.798 14.2175 18.9206 14.3349 19.0127 14.475C19.1049 14.6151 19.1641 14.7742 19.186 14.9404C19.2079 15.1066 19.1919 15.2756 19.1393 15.4347C19.0866 15.5939 18.9986 15.7391 18.8818 15.8594L16.7152 18.0038C16.469 18.2487 16.1769 18.4426 15.8556 18.5741C15.5343 18.7057 15.1901 18.7724 14.8429 18.7705ZM3.79294 1.10382L1.57071 3.24271C1.39152 3.40969 1.25887 3.62042 1.18582 3.8542C1.11276 4.08798 1.10181 4.33675 1.15405 4.57605C1.86 7.7939 3.48278 10.7384 5.82627 13.0538C8.23416 15.3658 11.2461 16.9507 14.5152 17.626C14.7626 17.6778 15.019 17.6673 15.2614 17.5955C15.5038 17.5238 15.7246 17.393 15.904 17.2149L18.0707 15.0705L14.0207 12.4538L12.1652 13.9316C12.0941 13.9878 12.0104 14.0258 11.9213 14.0423C11.8322 14.0587 11.7405 14.0532 11.654 14.026C10.1133 13.4584 8.71708 12.5573 7.56516 11.3872C6.37369 10.2745 5.44884 8.90716 4.8596 7.38716C4.83467 7.29514 4.83385 7.19825 4.8572 7.10582C4.88055 7.01339 4.92729 6.92852 4.99294 6.85938L6.49294 5.06494L3.79294 1.10382Z"
                fill={color}/>
        </svg>
    );
};
