import React from 'react';
import './style.scss';
import {useResizeWindow} from '../../../../hooks';
import {BarItem, IBarItemProps} from './components/BarItem';
import {BarItemMobile} from './components';

const config: IBarItemProps[] = [
    {activeWidth: 226, notActiveWidth: 15, text: 'В 15,1 раза больше просмотров', textMobile: 'раза больше', xNum: 'x15,1', description: 'Просмотры'},
    {activeWidth: 183, notActiveWidth: 15, text: 'В 12,2 раза больше звонков',textMobile: 'раза больше', xNum: 'x12,2', description: 'Звонки'},
    {activeWidth: 69, notActiveWidth: 15, text: 'В 7,1 раза больше сообщений',textMobile: 'раза больше', xNum: 'x7,1', description: 'Письма'},
];

export const Bars = () => {
    const width = useResizeWindow();
    return (
        <>
            <div className={'bars'}>
                {width > 689
                    ? (<>
                        <h2 className={'bars__title'}>Как действует на просмотры, звонки и письма</h2>
                        <div className={'bars__body'}>
                            {config.map(i => (
                                    <BarItem
                                        key={i.activeWidth}
                                        activeWidth={i.activeWidth}
                                        notActiveWidth={i.notActiveWidth}
                                        xNum={i.xNum}
                                        text={i.text}
                                        textMobile={i.textMobile}
                                        description={i.description}
                                    />
                            ))}
                        </div>
                    </>) : (
                        <div className={'bars-mobile'}>
                            <p className={'bars-mobile__title'}>Как действует на просмотры, звонки и письма </p>
                            {config.map(i => (
                                <BarItemMobile
                                    key={i.activeWidth}
                                    activeWidth={i.activeWidth}
                                    notActiveWidth={i.notActiveWidth}
                                    xNum={i.xNum}
                                    text={i.text}
                                    textMobile={i.textMobile}
                                    description={i.description}
                                />
                            ))}
                        </div>
                    )}

            </div>
            <p className={'bars__text'}>На графике показаны усредненные значения прироста всех категорий за 2021 год</p>
        </>
    );
};
