import React, {useState} from 'react';
import {IconSearch} from '../../../../icons';
const defaultLetter = require('../../../../assets/images/default-letters.png');

interface ILettersItemProps {
    title: string;
    date:string;
    src:string;
    handleClick:() => void;
}

export const LettersItem = ({title, date, src, handleClick}:ILettersItemProps) => {
    const [source, setSource] = useState<string>(src);
    return (
        <div className={'letters-item'} onClick={handleClick}>
            <div className={'letters-item__bg'}>
                <IconSearch color={'#ffffff'} width={20} height={20} />
            </div>
            <div className={'letters-item__wrap-img'}>
                <img
                    className={'letters-item__img'}
                    src={source}
                    onError={error => error.type === 'error' && setSource(defaultLetter)}
                    alt={'img'}
                />
            </div>

            <div className={'letters-item__footer'}>
                <p className={'letters-item__title'}>{title}</p>
                <span className={'letters-item__date'}>{date}</span>
            </div>

        </div>
    );
};
