import React, {useCallback, useEffect, useState} from 'react';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {SingleValue} from 'react-select';
import {NavLink, useLocation} from 'react-router-dom';
import {Button, CustomSelect, Input, InputPhone} from '../../../../fields';
import {IOptionsSelect} from '../../../../fields/CustomSelect';
import './style.scss';
import {buttonTypes} from '../../../../fields/types';
import {useAppDispatch, useAppSelector, useDebounce} from '../../../../hooks';
import {
    clear,
    getAutocomplete,
    getOfferPeriod, getTariffs,
    ISendMailReq,
    sendMail,
    setAutoComplete
} from '../../store/partner.slice';
import {InfinityResultList} from '../../../../components/search';
import {IAutoComplete, IRealTimeParams} from '../../../../components/search/types';
import {ConfirmationCheckbox} from '../../../../components/confirmationCheckbox';

const schema = yup.object({
    company: yup.string().required(),
    inn: yup.string().required(),
    period: yup.number().required(),
    email: yup.string().required(),
    phone: yup.string().required(),
});

export const Form = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const {autocomplete, next, offerPeriods, allTariffs} = useAppSelector(state => state.partner);
    const [realTimeValue, setRealtimeValue] = useState<string>('');
    const debouncedSearchTerm = useDebounce({value: realTimeValue, delay: 300});

    const [inputType, setInputType] = useState<string>('');
    const [isDisable, setDisable] = useState<boolean>(false);

    const [phone, setPhone] = useState<string>('');
    const [valuePeriod, setValueSelectedPeriod] = useState<SingleValue<IOptionsSelect>>(null);
    const [valueTariff, setValueSelectedTariff] = useState<SingleValue<IOptionsSelect>>(null);

    useEffect(() => {
        if (debouncedSearchTerm) {
            debouncedSearchTerm.length > 0
                ? dispatch(getAutocomplete({search: debouncedSearchTerm}))
                : dispatch(setAutoComplete([]));
        }
    }, [debouncedSearchTerm]);

    const {control, register, setValue, handleSubmit, formState} = useForm({
        resolver: yupResolver(schema)
    });

    const handleSelectPeriod = useCallback((item: SingleValue<IOptionsSelect>) => {
        setValueSelectedPeriod(item);
        setValue('period', Number(item?.value));
    }, []);

    const handleSelectTariff = useCallback((item: SingleValue<IOptionsSelect>) => {
        setValueSelectedTariff(item);
        setValue('period', Number(item?.value));
    }, []);


    const handlePhone = useCallback((phone: string) => {
        setPhone(phone);
        setValue('phone', phone);
    }, [formState]);

    const submit = handleSubmit(values => {
        const data: ISendMailReq = {
            inn: values.inn,
            period_id: values.period,
            phone_number: values.phone,
            email: values.email,
            currentPath: location.pathname
        };
        dispatch(sendMail({data}));
        handleClear();
    });

    const handleClear = () => {
        setValue('company', '');
        setValue('inn', '');
        setValue('period', '');
        setValue('email', '');
        setValue('phone', '');
        setValueSelectedPeriod(null);
        setValueSelectedTariff(null);
        setPhone('');
    };

    const handleRealTimeFetch = ({value, name}: IRealTimeParams) => {
        setInputType(name);
        setRealtimeValue(value);
        if (value.length === 0) {
            dispatch(setAutoComplete([]));
            dispatch(clear());
        }
    };

    const selectedUtilize = (value: IAutoComplete) => {
        setValue('company', value.name);
        setValue('inn', value.inn);
        dispatch(setAutoComplete([]));
        dispatch(clear());
    };

    const fetchNextList = useCallback(() => {
        next && dispatch(getAutocomplete({path: next}));
    }, [next]);

    return (
        <div className={'form'}>
            <div className={'form__box'}>
                <Input
                    isRequired={true}
                    placeholder={'Наименование компании'}
                    control={control}
                    register={register}
                    getRealTimeValue={handleRealTimeFetch}
                    fieldName={'company'}/>
                {inputType === 'company'
                    ? <InfinityResultList
                        preText={'ИНН'}
                        items={autocomplete}
                        handleSelect={selectedUtilize}
                        fetchNextData={fetchNextList}
                        hasMore={next !== null}
                    /> : null}
            </div>

            <div className={'form__box'}>
                <Input
                    isRequired={true}
                    placeholder={'ИНН'}
                    control={control}
                    register={register}
                    getRealTimeValue={handleRealTimeFetch}
                    fieldName={'inn'}/>
                {inputType === 'inn' ? <InfinityResultList
                    preText={'ИНН'}
                    items={autocomplete}
                    handleSelect={selectedUtilize}
                    fetchNextData={fetchNextList}
                    hasMore={next !== null}
                /> : null}
            </div>
            <div className={'form__box'}>
                <CustomSelect
                    placeholder={'Выберите тариф'}
                    options={allTariffs}
                    value={valueTariff}
                    handleOpenMenu={() => dispatch(getTariffs())}
                    handleChange={handleSelectTariff}/>
            </div>
            <div className={'form__box'}>
                <CustomSelect
                    placeholder={'Укажите срок размещения'}
                    options={offerPeriods}
                    value={valuePeriod}
                    handleOpenMenu={() => valueTariff && dispatch(getOfferPeriod({tariff_id: Number(valueTariff?.value)}))}
                    noOptionText={'Укажите тариф размещения'}
                    handleChange={handleSelectPeriod}/>
            </div>
            <div className={'form__box'}>
                <Input
                    isRequired={true}
                    placeholder={'E-mail'}
                    control={control}
                    register={register}
                    fieldName={'email'}/>
            </div>
            <div className={'form__box'}>
                <InputPhone
                    isRequired={true}
                    phone={phone}
                    onChange={handlePhone}/>
            </div>
            <div className={'form__box'}>
                <ConfirmationCheckbox
                    isChecked={isDisable}
                    handleChecked={() => setDisable(!isDisable)}
                    text={<>Нажимая кнопку «Отправить», я даю свое <NavLink to={'/policy'}>согласие на обработку моих
                        персональных данных</NavLink>, в
                        соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на
                        условиях и
                        для целей, определенных в Согласии на обработку персональных данных</>
                    }
                />
            </div>
            <div className={'form__box'}>
                <Button
                    icon={null}
                    text={'Отправить'}
                    handler={submit}
                    disabled={isDisable}
                    size={buttonTypes.xl}
                    outline={false}/>
            </div>
        </div>
    );
};
