import React from 'react';
import Select, {SingleValue} from 'react-select';

export interface IOptionsSelect {
    value: string;
    label: string;
}

interface ICustomSelectProps {
    options: IOptionsSelect[];
    placeholder: string;
    handleChange: (option: SingleValue<IOptionsSelect>) => void;
    handleOpenMenu?: () => void;
    value: SingleValue<IOptionsSelect>;
    color?: string;
    backgroundColor?: string;
    noOptionText?: string;
}


export const CustomSelect = (
    {
        placeholder,
        options,
        handleChange,
        handleOpenMenu,
        value,
        color = '#000000',
        backgroundColor = '#ffffff',
        noOptionText,
        ...props
    }: ICustomSelectProps) => {
    const customStyles = {
        control: () => ({
            width: '100%',
            background: backgroundColor,
            display: 'flex',
            border: '1px solid #F2F2F2',
            boxShadow: '0 0 10px rgb(62 83 114 / 3%)',
            borderRadius: '10px',
            padding: '7px 12px',

        }),
        input: () => ({
            display: 'none'
        }),
        placeholder: () => ({
            fontFamily: 'Averta CY,sans-serif',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            color: '#BFBFBF',
        }),
        singleValue: (provided:any, state:any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition,  color: color, whiteSpace: 'pre-wrap'};
        }
    };
    const onChange = (item: SingleValue<IOptionsSelect>) => {
        handleChange(item);
    };

    return (
        <Select
            {...props}
            styles={customStyles}
            placeholder={placeholder}
            options={options}
            value={value}
            onMenuOpen={handleOpenMenu}
            noOptionsMessage={({inputValue}) => noOptionText ? noOptionText : 'Нет элементов'}
            onChange={onChange}
        />
    );
};
