import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api} from '../../../api';
import {alertTypes, setMessage} from '../../../store/app.slice';
import {getError} from '../../../helpers/getError';

interface IHomeState {
    loading:boolean;
    reviews: any[];
    count: number;
    currentPage: number;
}

const initialState:IHomeState = {
    loading: false,
    reviews: [],
    count: 100,
    currentPage: 1
};

export const getAboutReview = createAsyncThunk(
    'home/getReview',
    async ({page}:{page:number}, {dispatch}) => {
        try {
            const res = await api.get(`/reviews/?page=${page}`);
            return {
                results : res.data.results,
                count: res.data.count,
                currentPage: page
            };
        } catch (e:any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: ''}));
            throw e;
        }
    }
);

interface ISendAboutReviewProps {
    name: string;
    phone_number: string;
    text: string;
}

export const sendAboutReview = createAsyncThunk(
    'home/sendAboutReview',
    async ({data}:{data:ISendAboutReviewProps}, {dispatch}) => {
        try {
            const res = await api.post('/reviews/', data);
            if(res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, text: 'Ваше мнение очень важно для нас!', title: 'Спасибо!'}));
            }
        } catch (e:any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: ''}));
            throw e;
        }
    }
);

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAboutReview.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAboutReview.fulfilled, (state, {payload}) => {
            state.reviews = payload.results;
            state.count = payload.count;
            state.currentPage = payload.currentPage;
        });
    }
});

export default homeSlice.reducer;
