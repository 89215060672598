import React from 'react';
import {ITooltipProps} from './types';


export const Tooltip = (
    {width, widthActive, isActive, activeText, text, children, ieRevert}: ITooltipProps
) => {
    return (
        <span className={'tooltip-wrapper'}>
               {children}
            {isActive
                ? <span className={ieRevert ? 'tooltip tooltip--revert' : 'tooltip'}
                     style={{width: `${widthActive}px`,}}
                >
                    {activeText}
                </span>
                : <span className={ieRevert ? 'tooltip tooltip--revert' : 'tooltip'}
                     style={{width: `${width}px`}}>
                    {text}
                </span>
            }
            </span>
    );
};
