import _ from 'lodash';
import {api} from '../api';

interface IProps {
    path: string;
    key: string | undefined;
    header: string;
    storageKey: string;
}

export const requestCounter = async ({storageKey, path, key, header}: IProps) => {
    const storage = sessionStorage.getItem(storageKey);

    if (storage) {
        let keys = JSON.parse(storage);
        if (_.includes(keys, key)) {
            return;
        } else {
            keys = [...keys, key];
            try {
                await api.patch(path, {
                    'request_id': header
                });
                sessionStorage.setItem(storageKey, JSON.stringify(keys));
            } catch (e) {
            }
        }
    } else {
        try {
            await api.patch(path, {
                'request_id': header
            });
            sessionStorage.setItem(storageKey, JSON.stringify([key]));
        } catch (e) {
        }
    }

};
