import React from 'react';
import './style.scss';
import {IconChevron} from '../../icons';

interface IGoBackProps {
    onClick: () => void;
}

export const GoBack = ({onClick}:IGoBackProps) => {
    return (
        <div className={'back'}>
            <span className={'back__link'} onClick={onClick}>
                    <IconChevron rotate={-90} color={'#390CA2'} />
                Назад в поиск
            </span>
        </div>
    );
};
