import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import StarRatings from 'react-star-ratings';
import {Button, Input, Textarea} from '../../fields';
import {IconClose} from '../../icons';
import {buttonTypes} from '../../fields/types';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {IReviewRequest} from '../card/types';
import {sendReview} from '../card/store/card.slice';

const schema = yup.object({
    name: yup.string().required('Поле обязательное к заполнению'),
    phone_number: yup.string().required('Поле обязательное к заполнению'),
    text: yup.string(),
    rating: yup.string().required('Поле обязательное к заполнению'),
    utilizer: yup.string().required('Поле обязательное к заполнению'),
});

interface IFeedbackFormProps {
    handleCansel: () => void
}

export const FeedbackForm = (
    {handleCansel}: IFeedbackFormProps
) => {
    const dispatch = useAppDispatch();
    const {currentUtilize} = useAppSelector(state => state.card);
    const [rating, setRating] = useState<number>(0);
    const {control, register, setValue, handleSubmit} = useForm<IReviewRequest>({
        resolver: yupResolver(schema)
    });

    const ratingChanged = (newRating: number) => {
        setRating(newRating);
        setValue('rating', newRating);
    };

    const submit = handleSubmit((values) => {
        values.utilizer = currentUtilize?.inn ?? '';
        dispatch(sendReview(values));
        handleCansel();
    });

    return (
        <div className={'feedback-form'}>
            <div className={'feedback-form__header'}>
                <p className={'feedback-form__title'}>Написать отзыв</p>
                <span onClick={handleCansel} style={{cursor: 'pointer'}}><IconClose/></span>
            </div>
            <div className={'feedback-form__box'}>
                <Input
                    control={control}
                    register={register}
                    fieldName={'utilizer'}
                    placeholder={'Компания'}
                    isRequired={true}
                />
            </div>
            <div className={'feedback-form__box'}>
                <Input
                    control={control}
                    register={register}
                    fieldName={'name'}
                    placeholder={'Имя'}
                    isRequired={true}
                />
            </div>
            <div className={'feedback-form__box'}>
                <Input
                    control={control}
                    register={register}
                    fieldName={'phone_number'}
                    placeholder={'Телефон'}
                    isRequired={true}
                />
            </div>
            <div className={'feedback-form__box'}>
                <span className={'feedback-form__text'}>Наименование компании и имя не будут отражены в отзыве без вашего согласия</span>
            </div>
            <div className={'feedback-form__box'}>
                <div className={'feedback-form__stars'}>
                    <StarRatings
                        rating={rating}
                        starDimension="30px"
                        starRatedColor={'rgb(248,169,4)'}
                        starEmptyColor={'rgb(109, 122, 130)'}
                        starHoverColor={'rgb(248,169,4)'}
                        starSpacing="7px"
                        changeRating={ratingChanged}
                    />
                </div>
            </div>
            <div className={'feedback-form__box'}>
                <span className={'feedback-form__subtitle'}>Комментарий</span>
                <Textarea
                    control={control}
                    register={register}
                    fieldName={'text'}
                    placeholder={'Поделитесь мнением о сотрудничестве с этой компанией'}/>
            </div>
            <div className={'feedback-form__box'}>
                <span className={'feedback-form__text'}>Мы свяжемся с вами для подтверждения информации</span>
            </div>
            <div className={'feedback-form__buttons'}>
                <Button icon={null} text={'Отправить'} handler={submit} size={buttonTypes.xl} outline={false}/>
                {/*<button className={'cansel-button'} onClick={handleCansel}>Отменить</button>*/}
            </div>
        </div>
    );
};
