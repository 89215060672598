import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getError} from '../../../helpers/getError';
import {alertTypes, setMessage} from '../../../store/app.slice';
import {api} from '../../../api';

const initialState = {};

interface IFeedbackProps {
    name: string;
    email: string;
    phone_number: string;
    occupation: string;
    text: string;
}

export const sendFeedback = createAsyncThunk(
    'feedback/sendIndividual',
    async ({data}:{data:IFeedbackProps}, {dispatch}) => {
        try {
            const res = await api.post('/feedback/', data);
            if(res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, title: 'Спасибо за обратную связь!', text: 'Мы рассмотрим ваше обращение в течение 30 минут и перезвоним!'}));
            }
        } catch (e:any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: ''}));
            throw e;
        }
    }
);


const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {}
});

export default feedbackSlice.reducer;
