import React from 'react';

export const IconIndicatorActive = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="7.5" r="7" stroke="#D9D9D9"/>
            <circle cx="7.5" cy="7.5" r="4.5" fill="url(#paint0_linear_643_612)"/>
            <defs>
                <linearGradient id="paint0_linear_643_612" x1="3" y1="12" x2="12" y2="3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#279FC2"/>
                    <stop offset="1" stopColor="#3252B1"/>
                </linearGradient>
            </defs>
        </svg>
    );
};
