import React from 'react';
import {Rating} from '../rating';
import {declination} from '../../helpers/declination';
import {IReviewProps} from './types';



export const Review = ({count, rating, isReviewTest}:IReviewProps) => {
    const word = declination({number: Number(count), txt: ['отзыв', 'отзыва', 'отзывлв'] });
    return (
        <div className={'review'}>
             <Rating num={rating}/>
            {isReviewTest ? <span className={'review__text'}>{count ? `${count} ${word}` : '0 отзывов'} </span> : null}
        </div>
    );
};
