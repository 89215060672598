import {createAsyncThunk, createSlice, Draft} from '@reduxjs/toolkit';
import {
    IPlacesCurrentLicenseResponse,
    IReviewRequest,
    ISearchByPlacesRequest,
    ISendApplicationRequest,
    IState
} from '../types';
import {api} from '../../../api';
import {alertTypes, setMessage} from '../../../store/app.slice';
import {createQueryParams} from '../../../helpers/createQueryParams';
import {BASE_URL} from '../../../constants';
import {sortLicensesByActive} from '../../../helpers/sortLicensesByActive';
import {getError} from '../../../helpers/getError';
import {requestCounter} from '../../../helpers/requestСounter';

const initialState: IState = {
    loading: false,
    currentUtilize: null,
    fkkos: [],
    placeCurrentLicense: [],
    count: 0,
    previous: null,
    next: null,
    currentPage: 1,
    scrollToLicense: ''
};

export const sendApplication = createAsyncThunk(
    'utilise/sendApplication',
    async ({data}: { data: ISendApplicationRequest }, {dispatch}) => {
        try {
            const res = await api.post('/utilizer/ownership', data);
            if (res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, text: 'Ваша заявка в обработке', title: ''}));
            }
        } catch (e: any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: 'Ошибка'}));
            throw e;
        }
    }
);

export const sendReview = createAsyncThunk(
    'utilise/sendReview',
    async (data: IReviewRequest, {dispatch}) => {
        try {
            const res = await api.post('/utilizer/review', data);
            if (res.status === 201) {
                dispatch(setMessage({
                    type: alertTypes.SUCCESS,
                    text: 'Мы проверим отзыв и свяжемся с вами в течение 30 минут для подтверждения информации.',
                    title: 'Спасибо, что поделились мнением о компании!'
                }));
            }
            return;
        } catch (e: any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: 'Ошибка'}));
            throw e;
        }
    }
);

export const getPlacesCurrentLicense = createAsyncThunk(
    'utilise/getPlacesCurrentLicense',
    async ({id}: { id: number }, {dispatch}) => {
        try {
            const res = await api.get<IPlacesCurrentLicenseResponse>(`/utilizer/license/${id}/places`);
            return res.data;
        } catch (e: any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: 'Ошибка'}));
            throw e;
        }
    }
);

export const getSearchByPlaces = createAsyncThunk(
    'utilise/getSearchByPlaces',
    async ({search, hazard_classes, activities, placeId, page}: ISearchByPlacesRequest, {dispatch}) => {
        try {
            dispatch(clearFkkos());
            const q = await createQueryParams({search, hazard_classes, activities, page});
            const res = await api.get(`/utilizer/place/${placeId}/fkkos?${q}`);
            return {
                fkkos: res.data.results,
                count: res.data.count,
                previous: res.data.previous,
                next: res.data.next,
                currentPage: page
            };
        } catch (e: any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: 'Ошибка'}));
            throw e;
        }
    }
);

export const getFkkosByPlaceId = createAsyncThunk(
    'utilise/getFkkosByPlaceId',
    async ({placeId}: { placeId: number }, {dispatch}) => {
        try {
            const res = await api.get(`/utilizer/place/${placeId}/fkkos`);
            return res.data;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const getLetters = createAsyncThunk(
    'utilise/getLetters',
    async ({inn}: { inn: string }, {dispatch}) => {
        try {
            const res = await api.get(`/utilizer/${inn}/letters`);
            return res.data.results;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const getReview = createAsyncThunk(
    'utilise/getReview',
    async ({inn}: { inn: string }, {dispatch}) => {
        try {
            const res = await api.get(`/utilizer/${inn}/reviews`);

            return res.data.results;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const getLicenseByInn = createAsyncThunk(
    'utilise/getLicenseByInn',
    async ({inn}: { inn: string | undefined }, {dispatch}) => {
        try {
            const res = await api.get(`/utilizer/${inn}/licenses`);
            if (res.data.length > 0) {
                inn && dispatch(getReview({inn}));
                inn && dispatch(getLetters({inn}));
            }
            await requestCounter({
                storageKey: 'requestCounterKeys',
                path: `/utilizer/${inn}`,
                key: inn,
                header: res.headers['x-request-id']
            });

            const result = sortLicensesByActive(res.data);

            result.length > 0 && dispatch(getPlacesCurrentLicense({id: result[0].id}));
            return {
                licenses: result,
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

// =========== pagination ==============
export const getFkkosNextPage = createAsyncThunk(
    'utilise/getFkkosNextPage',
    async ({nextPlaceFkkos}: { nextPlaceFkkos: string }, {dispatch}) => {
        try {
            const res = await api.get(`/${nextPlaceFkkos}`);
            return res.data;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const countEmail = createAsyncThunk(
    'card/countEmail',
    async ({
        inn
           }:{
        inn: string
    }) => {
        try {
            const res = await api.get(`/utilizer/${inn}/licenses`);

            await requestCounter({
                storageKey: 'contactCountEmailKeys',
                path: `/utilizer/${inn}/emails`,
                key: inn,
                header: res.headers['x-request-id']
            });
        } catch (e) {

        }
    }
);

export const countPhone = createAsyncThunk(
    'card/countPhone',
    async ({
               inn
           }:{
        inn: string
    }) => {
        try {
            const res = await api.get(`/utilizer/${inn}/licenses`);

            await requestCounter({
                storageKey: 'contactCountPhoneKeys',
                path: `/utilizer/${inn}/phone-numbers`,
                key: inn,
                header: res.headers['x-request-id']
            });
        } catch (e) {

        }
    }
);

const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        setCurrentUtilize: (state, {payload}) => {
            state.currentUtilize = payload;
        },
        clearCurrentUtilise: (state) => {
            state.currentUtilize = null;
        },
        clearFkkos: (state) => {
            state.fkkos = [];
        },
        clearPagination: (state) => {
            state.next = null;
            state.previous = null;
            state.count = 0;
        },
        setScrollToLicense: (state, {payload}) => {
            state.scrollToLicense = payload;
        },
        clearScrollToLicense: (state) => {
            state.scrollToLicense = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(getSearchByPlaces.pending, (state: Draft<any>) => {
            state.additionalLoading = true;
        });
        builder.addCase(getSearchByPlaces.fulfilled, (state: Draft<any>, {payload}) => {
            state.additionalLoading = false;
            state.fkkos = payload?.fkkos;
            state.count = payload?.count;
            state.previous = payload?.previous;
            state.nextPlaceFkkos = payload?.next;
            state.currentPage = payload.currentPage;
        });
        builder.addCase(getSearchByPlaces.rejected, (state: Draft<any>) => {
            state.additionalLoading = false;
        });

        builder.addCase(getPlacesCurrentLicense.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPlacesCurrentLicense.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.placeCurrentLicense = payload.results;
        });
        builder.addCase(getPlacesCurrentLicense.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(getFkkosByPlaceId.pending, (state: Draft<any>) => {
            state.loading = true;
        });
        builder.addCase(getFkkosByPlaceId.fulfilled, (state: Draft<any>, {payload}) => {
            state.loading = false;
            state.fkkos = payload?.results;
            state.count = payload?.count;
            state.previous = payload?.previous;
            state.next = payload?.next !== null ? payload?.next.split(BASE_URL)[1] : payload?.next;
            state.currentPage = 1;
        });

        builder.addCase(getFkkosNextPage.pending, (state: Draft<any>) => {
            state.loading = true;
        });
        builder.addCase(getFkkosNextPage.fulfilled, (state: Draft<any>, {payload}) => {
            state.fkkos = [...state.fkkos, ...payload?.results];
            state.next = payload?.next !== null ? payload?.next.split(BASE_URL)[1] : null;
        });

        builder.addCase(getLicenseByInn.pending, (state: Draft<any>) => {
            state.loading = true;
        });
        builder.addCase(getLicenseByInn.fulfilled, (state: Draft<any>, {payload}) => {
            state.loading = false;
            state.currentUtilize = {
                ...state.currentUtilize,
                licensesList: payload.licenses
            };
        });
        builder.addCase(getLicenseByInn.rejected, (state: Draft<any>) => {
            state.loading = false;
        });

        builder.addCase(getReview.pending, (state: Draft<any>) => {
            state.loading = true;
        });
        builder.addCase(getReview.fulfilled, (state: Draft<any>, {payload}) => {
            state.loading = false;
            state.currentUtilize = {...state.currentUtilize, reviews: payload};
        });
        builder.addCase(getReview.rejected, (state: Draft<any>) => {
            state.loading = false;
        });

        builder.addCase(getLetters.pending, (state: Draft<any>) => {
            state.loading = true;
        });
        builder.addCase(getLetters.fulfilled, (state: Draft<any>, {payload}) => {
            state.loading = false;
            state.currentUtilize = {...state.currentUtilize, letters: payload};
        });
        builder.addCase(getLetters.rejected, (state: Draft<any>) => {
            state.loading = false;
        });
    }
});

export const {
    setCurrentUtilize,
    clearFkkos,
    clearCurrentUtilise,
    setScrollToLicense,
    clearScrollToLicense
} = cardSlice.actions;

export default cardSlice.reducer;
