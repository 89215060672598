import React from 'react';
import {IIconProps} from './types';

export const IconPrev = ({width = 5, height = 11, color = '#808080'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.6001 1L1.0001 5.5M1.0001 5.5L4.6001 10M1.0001 5.5L4.3001 9.625" stroke={color} strokeLinecap="round"/>
        </svg>
    );
};
