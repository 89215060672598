import React from 'react';
import {IconClose, IconError, IconSuccess} from '../../icons';
import {useAppSelector} from '../../hooks';

export const AlertTemplate = ({style, options, message, close}: any) => {
    const {message: stateMessage} = useAppSelector(state => state.app);

    return (
        <>
            <div className={'alert-box'} style={style}>
                <div className={'alert-box__body'}>
                    <div className={'alert-box__icon'}>
                        {options.type === 'success' ? <IconSuccess/> : <IconError />}
                    </div>
                    <p className={'alert-box__title'}>{stateMessage.title}</p>
                    <p className={'alert-box__text'}>{stateMessage.text}</p>
                </div>
                <button
                    className={'alert-box__close'}
                    onClick={close}>
                    <IconClose width={30} height={30}/>
                </button>
            </div>
        </>
    );
};
