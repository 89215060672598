import React from 'react';

export const IconOffer2 = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M49.9963 24.9982C49.9963 38.8041 38.8045 49.9961 24.9985 49.9963V35.875H36.0009C36.4151 35.875 36.7509 35.5392 36.7509 35.125C36.7509 34.7108 36.4151 34.375 36.0009 34.375H24.9985V28.9171L33.5535 20.7003C35.0902 19.2243 35.0902 16.8184 33.5535 15.3424L33.5437 15.3331L33.5306 15.3213L33.335 15.1485L33.3264 15.141L33.3112 15.1283C31.7844 13.8885 29.5052 13.9606 28.0668 15.3492L24.9985 18.3113V0C38.8045 0.000218484 49.9963 11.1922 49.9963 24.9982ZM24.9985 26.8373V20.3962L29.1086 16.4284C29.9906 15.5769 31.4084 15.525 32.3543 16.2836L32.5249 16.4343C33.4365 17.3207 33.4331 18.7362 32.5145 19.6185L32.293 19.8312L31.3281 18.9044C31.0294 18.6175 30.5546 18.627 30.2677 18.9258C29.9807 19.2245 29.9903 19.6993 30.289 19.9862L31.2103 20.8711L24.9985 26.8373Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 24.9982C1.20691e-06 11.1928 11.1908 0.00127672 24.9958 0L24.9958 18.3144L13.9633 28.9651C13.8647 29.0603 13.7941 29.1807 13.7591 29.3132L12.2751 34.9343C12.2077 35.1895 12.2794 35.4613 12.464 35.6501C12.6485 35.8388 12.9187 35.9166 13.1753 35.855L19.1113 34.4297C19.2408 34.3986 19.3597 34.3335 19.4558 34.2413L24.9958 28.9202V34.3752H24.1291C23.7149 34.3752 23.3791 34.711 23.3791 35.1252C23.3791 35.5395 23.7149 35.8752 24.1291 35.8752H24.9958L24.9958 49.9963C11.1908 49.995 -1.20689e-06 38.8035 0 24.9982ZM24.9958 20.3993V26.8404L18.5634 33.0186L14.0458 34.1033L15.1559 29.8987L24.9958 20.3993Z"
                  fill="#F2F2F2"/>
        </svg>
    );
};
