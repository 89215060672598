import React, {useState} from 'react';
import {IconChevron} from '../../../icons';

interface IReviewItemProps {
    title: string;
    text: string;
    date: string;
}

export const ReviewItem = ({text, title, date}:IReviewItemProps) => {
    const [more, setMore] = useState<boolean>(false);
    return (
        <div className={
            more ? 'users-review-card users-review-card--full' : 'users-review-card'}>
            <h3 className={'users-review-card__title'}>{title}</h3>
            <span className={'users-review-card__date'}>{date}</span>
            <p
                className={
                    more
                        ? 'users-review-card__text'
                        : 'users-review-card__text users-review-card__text--hidden'
                }
            >{text}</p>
            <span className={'users-review-card__more'} onClick={() => setMore(!more)}>
                        {more ? 'Скрыть' : 'Читать далее'}
                    <IconChevron rotate={more ? 0 : 180} width={8} height={4} color={'#BFBFBF'} />
            </span>
        </div>
    );
};
