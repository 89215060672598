import React, {useState} from 'react';
import {ITab} from '../../config';
import {IconChevron} from '../../../../../../icons';
import './style.scss';
import {Modal} from '../../../../../../components/modal';

export const CardItem = ({title, text, icon, textMobile, description, image, color}:ITab) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <div className={'tab-card-item'}>
            <div className={'tab-card-item__header'}>
                <div className={'tab-card-item__icon'}>{icon}</div>
                <p className={'tab-card-item__wrapper-title'}>
                    <span className={'tab-card-item__title'}>{title}</span>
                    <span className={'tab-card-item__text'}>{text}</span>
                </p>
            </div>
            <p className={'tab-card-item__text-sm'}>{textMobile}</p>
            <button className={'tab-card-item__button'} onClick={() => setOpen(true)}>
                Посмотреть
                    <IconChevron rotate={90} width={10} height={14} color={'#3252B1'} />
            </button>
            <Modal isOpen={isOpen} handleClose={() => setOpen(false)}>
                <div className={'tab-card-item-modal'}>
                    <div className={'tab-card-item-modal__header'} style={{backgroundColor: color}}>
                        <p className={'tab-card-item__title'}>{`${title} ${text}`}</p>
                    </div>
                    <div className={'tab-card-item-modal__body'}>
                        <p className={'tab-card-item__text-sm'}>{description}</p>
                        <div className={'tab-card-item-modal__image'}>
                            <img src={image} alt={'banner'}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
