import React from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavLink} from 'react-router-dom';
import {Button, Input} from '../../../fields';
import {buttonTypes} from '../../../fields/types';
import {useAppDispatch} from '../../../hooks';
import {sendSubscribe} from '../../../store/app.slice';

const schema = yup.object({
    email: yup.string().email().required()
});

export const Form = () => {
    const dispatch = useAppDispatch();
    const {control, register, setValue, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const submit = handleSubmit(values => {
        dispatch(sendSubscribe({email : values.email}));
        setValue('email', '');
    });
    return (
        <div className={'subscribe'}>
            <h3 className={'subscribe__title'}>Узнавайте первыми о новых
                возможностях сервиса</h3>
            <form className={'subscribe-form'}>
                <Input
                    placeholder={'E-mail'}
                    control={control}
                    register={register}
                    fieldName={'email'} />
                <Button icon={null} text={'Подписаться'} handler={submit} size={buttonTypes.md} outline={false} />
            </form>
            <p className={'subscribe__text'}>
                Нажимая на кнопку "Подписаться", вы даете
                согласие <NavLink className={'subscribe__text--link'} to={'/policy'}>на
                обработку персональных данных</NavLink></p>
        </div>
    );
};
