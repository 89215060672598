import React from 'react';
import {IconTarget1, IconTarget2, IconTarget3} from '../../../icons';
import {useResizeWindow} from '../../../hooks';

export const Targets = () => {
    const width = useResizeWindow();
    const iconSize = width > 768 ? 50 : 40;
    return (
        <div className={'targets'}>
            <div className={'container'}>
                <div className={'targets__inner'}>
                    <div className={'targets__col'}>
                        <div className={'targets-item'}>
                            <div className={'targets-item__icon'}>
                                <IconTarget1 width={iconSize} height={iconSize} />
                            </div>
                            <div className={'targets-item__body'}>
                                <h3 className={'targets-item__title'}>Быстро</h3>
                                <p className={'targets-item__text'}>В один клик найдете
                                    то, что раньше казалось
                                    невозможным</p>
                            </div>
                        </div>
                    </div>
                    <div className={'targets__col'}>
                        <div className={'targets-item'}>
                            <div className={'targets-item__icon'}>
                                <IconTarget2 width={iconSize} height={iconSize}/>
                            </div>
                            <div className={'targets-item__body'}>
                                <h3 className={'targets-item__title'}>Точно</h3>
                                <p className={'targets-item__text'}>43 000 актуальных
                                    лицензий</p>
                            </div>
                        </div>
                    </div>
                    <div className={'targets__col'}>
                        <div className={'targets-item'}>
                            <div className={'targets-item__icon'}>
                                <IconTarget3 width={iconSize} height={iconSize}/>
                            </div>
                            <div className={'targets-item__body'}>
                                <h3 className={'targets-item__title'}>Удобно</h3>
                                <p className={'targets-item__text'}>Простой и интуитивно
                                    понятный поиск</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
