import React, {useState} from 'react';
import './style.scss';
import {useResizeWindow} from '../../hooks';
import {IconThreeLineMenu} from '../../icons';
import {Logo, Navigation} from './components';

export const Header = () => {
    const width = useResizeWindow();
    const [isOpen, setOpen] = useState<boolean>(false);
    return (
        <div className={'header'}>
            <div className={'container'}>
                <div className={'header__inner'}>
                    <Logo />
                    {
                        width < 1054
                            ? (<span onClick={() => setOpen(true)}><IconThreeLineMenu /></span>)
                            : null
                    }
                    <Navigation handleClose={() => setOpen(false)} isOpen={isOpen} isMobile={width < 1054} />
                </div>
            </div>
        </div>
    );
};
