import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {CardPage, ExecutorPage} from './pages/executor';
import {UtilizesPage} from './pages/utilizes';
import {useAppAlert, useAppDispatch, useAppSelector} from './hooks';
import {getAppActivities, getAppHazardClasses} from './store/app.slice';
import {Feedback} from './pages/feedback';
import {Home} from './pages/home';
import {Partner} from './pages/partner';
import {ContractOffer, OfferTariff, Policy} from './pages/rules';
import {ErrorPage} from './pages/errorpage';


const App = () => {
    const dispatch = useAppDispatch();
    const [initAlert] = useAppAlert();
    const {message} = useAppSelector(state => state.app);

    useEffect(() => {
        loadAppSettings();
    }, []);

    useEffect(() => {
        initAlert({
            type: message.type,
            text: message.text,
            title: message.title
        });
    }, [message]);

    function loadAppSettings() {
        dispatch(getAppActivities());
        dispatch(getAppHazardClasses());
    }

    return (
        <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/company'} element={<ExecutorPage/>}/>
            <Route path={'/company-card'} element={<CardPage/>}/>
            <Route path={'/licence'} element={<UtilizesPage/>}/>
            <Route path={'/kp'} element={<Partner/>}/>
            <Route path={'/feedback'} element={<Feedback/>}/>
            <Route path={'/contract'} element={<ContractOffer/>}/>
            <Route path={'/contract-tariff10'} element={<OfferTariff/>}/>
            <Route path={'/policy'} element={<Policy/>}/>
            <Route path={'/*'} element={<ErrorPage/>}/>
        </Routes>
    );
};

export default App;
