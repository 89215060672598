import {createAsyncThunk, createSlice, Draft} from '@reduxjs/toolkit';
import {createQueryParams, IQueryProps} from '../../../helpers/createQueryParams';
import {api} from '../../../api';
import {alertTypes, setMessage} from '../../../store/app.slice';
import {IGetExecutorCardListProps} from '../../../store/types';
import {requestCounter} from '../../../helpers/requestСounter';
import {IExecutorState, ISelectExecutorResponse} from './types';

const initialState: IExecutorState = {
    loading: false,
    //cardList: [],
    cards: [],
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    select_search: [],
    select_regions: [],
    regionNext: '',
    searchFkkoNext: '',
    isEmptyCards: null,
    defaultInput : {
        defaultRegions: '',
        defaultSearch: '',
        defaultActivities: '',
        currentFkko: ''
    },
    regionId : ''
};


export const uploadSelectList = createAsyncThunk(
    'executor/uploadSelectList',
    async ({search, regions, activities, inputType, path}: IGetExecutorCardListProps, {dispatch}) => {

        try {
            const q =
                path !== undefined
                    ? path
                    : await createQueryParams({
                        activities,
                        regions,
                        search
                    });
            const res = await api.get<ISelectExecutorResponse>(`/utilizer/fkkos?${q}`);

            return {
                next: res.data?.next !== null ? res.data?.next?.split('/fkkos?')[1] : null,
                [`select_${inputType}`]: res.data.results
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const uploadNextSelectList = createAsyncThunk(
    'executor/uploadNextSelectList',
    async ({path, inputType}: IGetExecutorCardListProps, {dispatch}) => {
        try {
            const res = await api.get<ISelectExecutorResponse>(`/utilizer/fkkos?${path}`);
            return {
                next: res.data?.next !== null ? res.data?.next?.split('/fkkos?')[1] : null,
                [`select_${inputType}`]: res.data.results
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const uploadRegionsSelect = createAsyncThunk(
    'executor/uploadRegionsSelect',
    async ({search, path, inputType}: IGetExecutorCardListProps, {dispatch}) => {
        try {
            const q =
                path !== undefined
                    ? path
                    : await createQueryParams({search});

            const url = `/utilizer/regions?${q}`;
            const res = await api.get(url);
            return {
                regionNext: res.data.next,
                [`select_${inputType}`]: res.data.results
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const getCardByRegions = createAsyncThunk(
    'executor/getCardByRegions',
    async ({id}: { id: number }, {dispatch}) => {
        try {
            const res = await api.get(`/utilizer/?regions=${id}`);
            return res.data;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);


export const getExecutorCards = createAsyncThunk(
    'executor/getExecutorCards',
    async ({activities, regions, fkko_codes, page}: IQueryProps, {dispatch}) => {

        try {
            const q = await createQueryParams({activities, regions, fkko_codes, page});

            const res = await api.get<ISelectExecutorResponse>(`/utilizer?${q}`);

            await requestCounter({
                storageKey: 'requestCounterKeys',
                path: `/utilizer/fkko/${fkko_codes}`,
                key: fkko_codes,
                header: res.headers['x-request-id']
            });

            return {
                count: res.data.count,
                next: res.data.next !== null ? res.data.next.split('utilizer/?')[1] : null,
                previous: res.data.previous,
                cards: res.data.results,
                currentPage: page
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);


const executorSlice = createSlice({
    name: 'executor',
    initialState,
    reducers: {
        clearExecutor: (state) => {
            state.select_search = [];
            state.select_regions = [];
            state.cards = [];
            state.count = 0;
        },
        setDefaultValue:(state, {payload}) => {
            state.defaultInput.defaultSearch = payload.defaultSearch;
            state.defaultInput.defaultRegions = payload.defaultRegions;
            state.defaultInput.defaultActivities = payload.defaultActivities;
            state.defaultInput.currentFkko = payload.currentFkko;
        },
        setRegionId: (state, {payload}) => {
            state.regionId = payload.id;
        }
    },
    extraReducers: builder => {
        builder.addCase(uploadSelectList.pending, (state) => {
           // state.loading = true;
        });
        builder.addCase(uploadSelectList.fulfilled, (state: Draft<any>, {payload}) => {
           // state.loading = false;
            //@ts-ignore
            state.select_search = payload?.select_search;
            state.searchFkkoNext = payload.next;
        });
        builder.addCase(uploadSelectList.rejected, (state) => {
           // state.loading = false;
        });

        builder.addCase(uploadNextSelectList.pending, (state) => {
           // state.loading = true;
        });
        builder.addCase(uploadNextSelectList.fulfilled, (state: Draft<any>, {payload}) => {
           // state.loading = false;
            //@ts-ignore
            state.select_search = [...state.select_search, ...payload?.select_search];
            state.searchFkkoNext = payload.next;
        });
        builder.addCase(uploadNextSelectList.rejected, (state) => {
           // state.loading = false;
        });



        builder.addCase(uploadRegionsSelect.pending, (state) => {
           // state.loading = true;
        });
        builder.addCase(uploadRegionsSelect.fulfilled, (state, {payload}) => {
           // state.loading = false;
            state.regionNext = payload?.regionNext;
            state.select_regions = payload?.select_regions;
        });
        builder.addCase(uploadRegionsSelect.rejected, (state) => {
           // state.loading = false;
        });

        builder.addCase(getExecutorCards.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getExecutorCards.fulfilled, (state: Draft<any>, {payload}) => {
            state.loading = false;
            state.count = payload.count;
            state.next = payload.next;
            state.previous = payload.previous;
            state.cards = payload.cards;
            state.isEmptyCards = payload.cards.length === 0 ? 'Ничего не найдено' : null;
            state.currentPage = payload.currentPage;
        });
        builder.addCase(getExecutorCards.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(getCardByRegions.pending, (state) => {
            //state.loading = true;
        });
        builder.addCase(getCardByRegions.fulfilled, (state: Draft<any>, {payload}) => {
           // state.loading = false;
            state.count = payload.count;
            state.next = payload.next;
            state.previous = payload.previous;
            state.cards = payload.results;
        });
        builder.addCase(getCardByRegions.rejected, (state) => {
            //state.loading = false;
        });

    }
});

export const {clearExecutor, setDefaultValue, setRegionId} = executorSlice.actions;

export default executorSlice.reducer;
