import React from 'react';
const image = require('../../../../../../assets/images/img-tab7.jpg');

export const Tab7 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-7'}>
                <h3 className={'desktop-tab__title'}>Разместим отзывы о компании и благодарственные письма</h3>
                <p className={'desktop-tab__text'}>Заказчики читают отзывы, прежде чем выбрать исполнителя. Примеры
                    успешно выполненных работ лучше всего расскажут об уровне профессионализма ваших сотрудников.
                    Хорошие отзывы помогут клиентам сделать выбор в вашу пользу.</p>
            </div>
            <div className={'desktop-tab__box'}>
                <img src={image} alt={'tab-7'} loading="lazy"/>
            </div>
        </div>
    );
};
