import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {IconChevron} from '../icons';
import {ISelectorProps} from './types';

export const Selector = (
    {
        options,
        placeholder,
        handle,
        name,
        defaultValue
    }: ISelectorProps
) => {
    const [selected, setSelected] = useState<string[]>([]);
    const [showList, setShowList] = useState<boolean>(false);

    useEffect(() => {
        const items = defaultValue.split(',');
        setSelected(items);
    }, [defaultValue]);

    const handleSelect = (value: string) => {
        if (_.includes(selected, value)) {
            const i = selected.filter(i => i !== value);
            setSelected(i);
        } else {
            setSelected([...selected, value]);
        }
    };

    const renderStr = (items: string[]) => {
        const text = options.filter(i => _.includes(items, i.value)).map(i => i.value);
        return text.join(', ');
    };

    return (
        <div className={'selector'}>
            <div className={'wrapper-input'} onClick={() => setShowList(true)}>
                <span
                    className={selected.length > 0  ? 'output' : 'output output--placeholder'}
                ><span className={'output__text'}>{selected.length > 0 ? renderStr(selected) : placeholder}</span></span>
                <span className={'wrapper-input__icon'}><IconChevron rotate={showList ? 180 : 0} color={'#000000'} /></span>
            </div>
            {
                showList
                    ? (
                        <>
                            <div className={'selector-overlay'} onClick={() => {
                                if (handle) {
                                    const str = options.filter(i => _.includes(selected, i.value)).map(i => i.value).join(',');
                                    handle({value: str, name : name});
                                }
                                setShowList(false);
                            }}/>
                            <ul className={'selector-list'}>
                                {options.map(i => (
                                    <li
                                        key={i.id}
                                        className={
                                            _.includes(selected, i.value)
                                                ? 'selector-list__item selector-list__item--active'
                                                : 'selector-list__item'}
                                        onClick={() => handleSelect(i.value)}
                                    >{i.value}</li>
                                ))}
                            </ul>
                        </>
                    ) : null
            }

        </div>
    );
};
