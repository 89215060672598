import React from 'react';
const image = require('../../../../../../assets/images/img-tab4.jpg');
export const Tab4 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-4'}>
                <h3 className={'desktop-tab__title'}>Добавим логотип</h3>
                <p className={'desktop-tab__text'}>С логотипом в карточке клиентам вас будет легче запомнить.</p>
            </div>
            <div className={'desktop-tab__box'}>
                 <img src={image} alt={'tab-4'} loading={'lazy'} />
            </div>
        </div>
    );
};
