import {Control, FieldValue, UseFormRegister} from 'react-hook-form';
import {IRealTimeParams} from '../components/search/types';
import {IOptions} from '../store/app.slice';

export interface IInputProps {
    control: Control<FieldValue<any>>;
    register: UseFormRegister<any>;
    fieldName:string;
    placeholder?: string;
    getRealTimeValue?: (({value, name}:IRealTimeParams) => void);
    isRequired?: boolean;
    isReadOnly?: boolean;
    setValue?: any;
}

export const enum buttonTypes {
    xl = 'xl',
    md = 'md'
}

export interface IButtonProps {
    icon: JSX.Element | null;
    text: string;
    handler: () => void;
    size: buttonTypes.md | buttonTypes.xl;
    outline: boolean;
    disabled?:boolean;
}



export interface ISelectorProps {
    defaultValue: string;
    name?:string;
    placeholder:string;
    options: IOptions[];
    handle: ((values:any) => void) | undefined;
}
