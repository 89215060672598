import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {IconClose} from '../../../icons';

interface IConfig {
    path: string;
    name: string;
}

const config: IConfig[] = [
    {path: '/', name: 'Главная'},
    {path: '/company', name: 'Найти компанию'},
    {path: '/licence', name: 'Проверить лицензию'},
    {path: '/kp', name: 'Разместить рекламу'},
    {path: '/feedback', name: 'Связаться с нами'},
];

interface INavigationProps {
    isMobile: boolean;
    isOpen: boolean;
    handleClose: () => void;
}

export const Navigation = ({isMobile, isOpen, handleClose}: INavigationProps) => {
    const location = useLocation();

    return (
        <>
            <ul className={
                isMobile
                    ? isOpen
                        ? 'navigation navigation--mobile navigation navigation--mobile navigation--open'
                        : 'navigation navigation--mobile'
                    : 'navigation'}>
                {isMobile ? <div className={'navigation__close'} onClick={handleClose}>
                    <IconClose width={40} height={40} color={'#000000'}/>
                </div> : null}
                {config.map(i => (
                    <li key={i.path} className={
                        location.pathname === i.path
                            ? 'navigation__item navigation__item--active'
                            : 'navigation__item'}>
                        <NavLink to={i.path} state={{prevPathName: location.pathname}}
                                 className={'navigation__link'}>{i.name}</NavLink>
                    </li>
                ))}
            </ul>
        </>
    );
};
