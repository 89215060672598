import React, {useMemo} from 'react';
import _ from 'lodash';
import {IconBox, IconEcology, IconPlacing, IconRevers, IconTransport, IconUtil} from '../../../icons';
import {IIconProps, verifyColors} from '../../../icons/types';
import {useAppSelector} from '../../../hooks';
import {IOptions} from '../../../store/app.slice';


const icons = [
    {Icon: (props:IIconProps) => <IconTransport color={props.color} />},
    {Icon: (props:IIconProps) => <IconEcology color={props.color} />},
    {Icon: (props:IIconProps) => <IconBox color={props.color} />},
    {Icon: (props:IIconProps) => <IconRevers color={props.color} />},
    {Icon: (props:IIconProps) => <IconUtil color={props.color} />},
    {Icon: (props:IIconProps) => <IconPlacing color={props.color} />},
];

interface IServicesProps {
    activities: IOptions[]
}

export const Services = ({activities}:IServicesProps) => {
    const {activitiesOptions} = useAppSelector(state => state.app);
    const actives = activities.map(i => i?.value);

    const renderService = useMemo(() => {
        return activitiesOptions.map(({id, value}, index:number) => (
            <li
                key={id}
                className={_.includes(actives, value) ? 'services__item' : 'services__item services__item--notActive'}
            >
                {/*<Icon color={_.includes(activities, text) ? verifyColors.active : verifyColors.notActive}/>*/}
                {icons[index].Icon({color: _.includes(actives, value) ? verifyColors.active : verifyColors.notActive})}
                {value}
            </li>
        ));
    }, [activitiesOptions]);

    return (
        <ul className={'services'}>
            {renderService}
        </ul>
    );
};
