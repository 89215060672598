import React from 'react';

export const BackgroundWipe = () => {
    return (
        <div className={'wipe-background'}>
            <svg width="939" height="435" viewBox="0 0 939 435" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.464111 5.29553e-06C31.7942 34.7201 59.3836 72.9983 85.9999 115.222C190.5 280.998 519 514.057 939 407.293V0L0.464111 5.29553e-06Z" fill="white"/>
            </svg>
        </div>
    );
};
