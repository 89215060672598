import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import {positions, Provider as AlertProvider, transitions} from 'react-alert';
import {store} from './store';
import App from './App';
import './index.scss';
import {AlertTemplate} from './components/alertTemplate';

const persist = persistStore(store);

const container = document.getElementById('root')!;
const root = createRoot(container);

const options = {
    // you can also just use 'bottom center'
    position: positions.MIDDLE,
    //timeout: 4000,
    offset: '-150px',
    transition: transitions.SCALE,
    containerStyle: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 100,
        height: '100vh',
        top: '0',
        backdropFilter: 'blur(2.5px)'
    }
};

root.render(
    <Provider store={store}>
        <PersistGate loading={<></>} persistor={persist}>
            <AlertProvider template={AlertTemplate} {...options}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </AlertProvider>
        </PersistGate>
    </Provider>
);

