import React from 'react';
import {IIconProps} from './types';

export const IconLocation = ({width = 12, height = 16, color = '#04BF8A'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.98543 14.9889L4.98455 14.9877C4.31156 14.1011 3.20228 12.5766 2.25896 10.9657C1.78738 10.1604 1.3518 9.32426 1.03287 8.52896C0.717032 7.74135 0.5 6.95615 0.5 6.26362C0.5 3.10487 2.93961 0.5 6 0.5C9.0615 0.5 11.5 3.10494 11.5 6.26362C11.5 6.95549 11.2848 7.74026 10.9708 8.528C10.6538 9.32334 10.2204 10.1595 9.75017 10.9651C8.80992 12.5758 7.7007 14.1005 7.01362 14.9901C6.49295 15.6704 5.50555 15.67 4.98543 14.9889ZM4.83333 6.26362C4.83333 6.97974 5.38077 7.51816 6 7.51816C6.61923 7.51816 7.16667 6.97974 7.16667 6.26362C7.16667 5.54751 6.61923 5.00908 6 5.00908C5.38077 5.00908 4.83333 5.54751 4.83333 6.26362Z" stroke={color}/>
        </svg>
    );
};
