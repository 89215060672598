import React from 'react';
import './style.scss';
import Pagination from 'rc-pagination';
import {IconNext, IconPrev, IconThreeDot} from '../../icons';

interface IPaginationProps {
    count: number;
    handle: (num:number) => void;
    defaultCurrent: number
}

export const CustomPagination = ({count, handle, defaultCurrent}:IPaginationProps) => {

    const onChangePage = (page: number) => {
        handle(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return count > 15 ? (
        <Pagination
            className={'pagination'}
            onChange={onChangePage}
            current={defaultCurrent}
            total={count}
            prevIcon={<IconPrev />}
            nextIcon={<IconNext />}
            jumpNextIcon={<IconThreeDot />}
            jumpPrevIcon={<IconThreeDot />}
            pageSize={15}
        />
    ) : <></>;
};
