import React from 'react';
import {IconEmail, IconPhone, IconVerification} from '../../../icons';
import {verifyColors} from '../../../icons/types';
import {Tooltip} from '../../../components/tooltip';

interface IVerifyProps {
    isEmailVerify: boolean;
    isPhoneVerify: boolean;
}

export const VerifyComponent = ({isEmailVerify, isPhoneVerify}: IVerifyProps) => {
    return (
        <div className={'verify-component'}>
            <span className={'verify-component__text'}>Проверенные данные: </span>
            <Tooltip ieRevert={false} isActive={true} activeText={'Лицензия'} text={'Лицензия'} widthActive={71}
                     width={71}>
                <IconVerification color={verifyColors.active} width={20} height={20}/>
            </Tooltip>
            {isPhoneVerify
                ? (
                    <Tooltip ieRevert={false} isActive={isPhoneVerify} activeText={'Номер телефона'}
                             text={'Номер телефона'} widthActive={107} width={107}>
                        <IconPhone
                            color={isPhoneVerify ? verifyColors.active : verifyColors.notActive}
                            width={20} height={20}/>
                    </Tooltip>
                )
                : (<IconPhone
                    color={isPhoneVerify ? verifyColors.active : verifyColors.notActive}
                    width={20} height={20}/>)}

            {isEmailVerify
                ? (<Tooltip ieRevert={false} isActive={isEmailVerify} activeText={'Электронный адрес'}
                            text={'Электронный адрес'} widthActive={125} width={125}>
                    <IconEmail
                        color={isEmailVerify ? verifyColors.active : verifyColors.notActive}
                        width={20} height={20}/>
                </Tooltip>)
                : (<IconEmail
                    color={isEmailVerify ? verifyColors.active : verifyColors.notActive}
                    width={20} height={20}/>)}
        </div>
    );
};
