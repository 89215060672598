import React from 'react';
import {IIconProps} from './types';

export const IconNext = ({width = 5, height = 11, color = '#808080'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 5 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.600098 10L4.2001 5.5M4.2001 5.5L0.600098 1M4.2001 5.5L0.900098 1.375" stroke={color} strokeLinecap="round"/>
        </svg>
    );
};
