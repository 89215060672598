import React from 'react';
import {IIconProps} from './types';

export const IconCamera = ({width = 20, height = 15, color = '#04BF8A'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.79679 3.05263V3.55263H6.29679V3.05263H5.79679ZM13.7914 3.05263H13.2914V3.55263H13.7914V3.05263ZM6.29679 3C6.29679 2.17157 6.96837 1.5 7.79679 1.5V0.5C6.41608 0.5 5.29679 1.61929 5.29679 3H6.29679ZM6.29679 3.05263V3H5.29679V3.05263H6.29679ZM3 3.55263H5.79679V2.55263H3V3.55263ZM1.5 5.05263C1.5 4.22421 2.17157 3.55263 3 3.55263V2.55263C1.61929 2.55263 0.5 3.67192 0.5 5.05263H1.5ZM1.5 12V5.05263H0.5V12H1.5ZM3 13.5C2.17157 13.5 1.5 12.8284 1.5 12H0.5C0.5 13.3807 1.61929 14.5 3 14.5V13.5ZM16.5882 13.5H3V14.5H16.5882V13.5ZM18.0882 12C18.0882 12.8284 17.4167 13.5 16.5882 13.5V14.5C17.9689 14.5 19.0882 13.3807 19.0882 12H18.0882ZM18.0882 5.05263V12H19.0882V5.05263H18.0882ZM16.5882 3.55263C17.4167 3.55263 18.0882 4.2242 18.0882 5.05263H19.0882C19.0882 3.67192 17.9689 2.55263 16.5882 2.55263V3.55263ZM13.7914 3.55263H16.5882V2.55263H13.7914V3.55263ZM13.2914 3V3.05263H14.2914V3H13.2914ZM11.7914 1.5C12.6199 1.5 13.2914 2.17157 13.2914 3H14.2914C14.2914 1.61929 13.1722 0.5 11.7914 0.5V1.5ZM7.79679 1.5H11.7914V0.5H7.79679V1.5Z"
                fill={color}/>
            <circle cx="9.7942" cy="8.26466" r="3.44118" stroke={color}/>
        </svg>
    );
};
