import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api} from '../api';
import {getError} from '../helpers/getError';

export const alertTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error'
};

export interface IAlert {
    type: string;
    text: string;
    title: string;
}

export interface IOptions {
    id: number;
    value: string;
}

interface IStateApp {
    appLoader: boolean;
    message: IAlert;
    activitiesOptions: IOptions[]
    hazardClasses: IOptions[]
}

const initialState: IStateApp = {
    appLoader: false,
    message: {
        type: '',
        text: '',
        title: ''
    },
    activitiesOptions: [],
    hazardClasses: []
};

export const getAppActivities = createAsyncThunk(
    'app/getAppActivities',
    async (_, {dispatch}) => {
        try {
            const res = await api.get('/utilizer/activities');
            return res.data;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Сейчас на сайте проходят технические работы.', title: ''}));
            throw e;
        }
    }
);

export const getAppHazardClasses = createAsyncThunk(
    'app/getAppHazardClasses',
    async (_, {dispatch}) => {
        try {
            const res = await api.get('/utilizer/hazard-classes');
            return res.data;
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Сейчас на сайте проходят технические работы.', title: ''}));
            throw e;
        }
    }
);

export interface ISendRequestProps {
    company_name: string;
    name: string;
    phone_number: string;
    email: string;
    referer: string;
}

export const sendRequest = createAsyncThunk(
    'app/sendRequest',
    async ({data}: { data: ISendRequestProps }, {dispatch}) => {
        try {
            const res = await api.post('/offer/', data);
            if (res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, text: 'Мы свяжемся с вами в течение 30 минут!', title: 'Ваша заявка принята!'}));
            }
        } catch (e: any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: ''}));
            throw e;
        }
    }
);

export interface IRequestLearnMore {
    company_name: string;
    name: string;
    email: string;
    phone_number: string;
}

export const sensRequestLearnMore = createAsyncThunk(
    'app/sensRequestLearnMore',
    async ({data}: { data: IRequestLearnMore }, {dispatch}) => {
        try {
            const res = await api.post('/offer/learn-more', data);
            if (res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, text: 'Мы перезвоним вам в течение 30 минут!', title: 'Спасибо за обращение!'}));
            }
        } catch (e: any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: ''}));
            throw e;
        }
    }
);

export const sendSubscribe = createAsyncThunk(
    'app/sendSubscribe',
    async ({email}: { email: string }, {dispatch}) => {

        try {
            const res = await api.post('/subscription/', {email});
            if (res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, title: 'Спасибо!', text: 'Вы подписались на наши обновления!'}));
            }
        } catch (e: any) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Адрес рассылки с таким E-mail для отправки рассылки уже существует. Пожалуйста, введите другой E-mail!', title: 'Данный E-mail уже существует!'}));
            throw e;
        }
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMessage: (state, {payload}: {payload: IAlert}) => {
            state.message.text = payload.text;
            state.message.type = payload.type;
            state.message.title = payload.title;
        },
        clearMessage: (state) => {
            state.message.text = '';
            state.message.type = '';
        }
    },
    extraReducers: builder => {
        builder.addCase(getAppActivities.pending, (state) => {
            state.appLoader = true;
        });
        builder.addCase(getAppActivities.fulfilled, (state, {payload}) => {
            state.appLoader = false;
            state.activitiesOptions = payload;
        });
        builder.addCase(getAppActivities.rejected, (state) => {
            state.appLoader = false;
        });
        builder.addCase(getAppHazardClasses.pending, (state) => {
            state.appLoader = true;
        });
        builder.addCase(getAppHazardClasses.fulfilled, (state, {payload}) => {
            state.appLoader = false;
            state.hazardClasses = payload;
        });
        builder.addCase(getAppHazardClasses.rejected, (state) => {
            state.appLoader = false;
        });
    }
});

export const {clearMessage, setMessage} = appSlice.actions;

export default appSlice.reducer;
