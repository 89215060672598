import React from 'react';
import {IIconProps} from './types';

export const IconInfo = ({width = 21, height = 21, color = '#3252B1'}: IIconProps) => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21ZM10.5 19.5999C15.5258 19.5999 19.6 15.5257 19.6 10.4999C19.6 5.47408 15.5258 1.39987 10.5 1.39987C5.47421 1.39987 1.4 5.47408 1.4 10.4999C1.4 15.5257 5.47421 19.5999 10.5 19.5999Z"
                  fill="#3252B1"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.4999 8.24733C11.1315 8.24733 11.6435 7.73534 11.6435 7.10377C11.6435 6.4722 11.1315 5.96021 10.4999 5.96021C9.86834 5.96021 9.35635 6.4722 9.35635 7.10377C9.35635 7.73534 9.86834 8.24733 10.4999 8.24733ZM9.63344 13.3069L9.63344 11.0891H9.39109C8.91263 11.0891 8.52476 10.7013 8.52476 10.2228C8.52476 9.74434 8.91263 9.35647 9.39109 9.35647H10.4998H10.5C10.9785 9.35647 11.3663 9.74434 11.3663 10.2228L11.3661 10.2428L11.3661 13.3069H11.8862C12.3646 13.3069 12.7525 13.6947 12.7525 14.1732C12.7525 14.6517 12.3646 15.0395 11.8862 15.0395H10.5125L10.4998 15.0396L10.4871 15.0395H9.1139C8.63543 15.0395 8.24756 14.6517 8.24756 14.1732C8.24756 13.6947 8.63543 13.3069 9.1139 13.3069H9.63344Z"
                  fill="#3252B1"/>
        </svg>
    );
};
