import {configureStore, combineReducers} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import utilizes from '../pages/utilizes/store/utilizes.slice';
import executor from '../pages/executor/store/executor.slice';
import card from '../components/card/store/card.slice';
import partner from '../pages/partner/store/partner.slice';
import home from '../pages/home/store/home.slice';
import feedback from '../pages/feedback/store/feedback.slice';
import app from './app.slice';

const rootReducer = combineReducers({
    app,
    utilizes,
    executor,
    card,
    partner,
    home,
    feedback
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }) //.concat( logger)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
