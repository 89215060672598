import React from 'react';

export const IconBadge2 = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25 50C38.8062 49.9989 49.9981 38.8065 49.9981 25C49.9981 11.1935 38.8062 0.00105359 25 0V25.3228L25.3274 25.6502L35.2913 15.6863C35.5016 15.5062 35.7723 15.412 36.0491 15.4227C36.3258 15.4334 36.5884 15.5481 36.7843 15.744C36.9801 15.9399 37.0949 16.2024 37.1056 16.4792C37.1162 16.756 37.0221 17.0266 36.8419 17.237L25.9872 28.1027C25.812 28.2773 25.5747 28.3753 25.3274 28.3753C25.2145 28.3753 25.1037 28.3549 25 28.3161V35.8518L25.3297 36.1815L35.2936 26.2176C35.5039 26.0374 35.7746 25.9433 36.0514 25.9539C36.3281 25.9646 36.5907 26.0794 36.7866 26.2752C36.9824 26.4711 37.0972 26.7337 37.1079 27.0104C37.1185 27.2872 37.0244 27.5579 36.8442 27.7682L25.9895 38.6339C25.8143 38.8085 25.577 38.9066 25.3297 38.9066C25.216 38.9066 25.1044 38.8859 25 38.8465V50Z"
                  fill="#F44336"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.998 0C11.1918 0.00105391 1.207e-06 11.1935 0 25C-2.18557e-06 38.8065 11.1918 49.9989 24.998 50L24.998 38.8458C24.8761 38.7995 24.764 38.7278 24.6698 38.6339L14.1011 28.0542C13.8962 27.8481 13.7812 27.5694 13.7812 27.2788C13.7812 26.9883 13.8962 26.7096 14.1011 26.5035C14.3071 26.2987 14.5858 26.1837 14.8764 26.1837C15.1669 26.1837 15.4457 26.2987 15.6517 26.5035L24.998 35.8498V28.3154C24.8752 28.2692 24.7623 28.1971 24.6675 28.1027L14.0987 17.5339C13.9957 17.4317 13.9138 17.3101 13.858 17.176C13.8022 17.042 13.7734 16.8983 13.7734 16.7531C13.7734 16.6079 13.8022 16.4642 13.858 16.3302C13.9138 16.1961 13.9957 16.0745 14.0987 15.9723C14.3048 15.7674 14.5835 15.6525 14.8741 15.6525C15.1646 15.6525 15.4434 15.7674 15.6494 15.9723L24.998 25.3209L24.998 0Z"
                  fill="#FB5F54"/>
        </svg>
    );
};
