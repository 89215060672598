import React, {useEffect} from 'react';
import {IconClose} from '../../icons';
import {IModalProps} from './types';

export const Modal = ({children, isOpen, handleClose, className}: IModalProps) => {

    useEffect(() => {
        const body = document.querySelector('body');
        const header = document.querySelector<any>('.header');
        const scrollTopBtn = document.querySelector<any>('.scroll-top');
        if(scrollTopBtn !== null && isOpen) {
            scrollTopBtn.style.zIndex = 0;
        }
        if(scrollTopBtn !== null && !isOpen){
            scrollTopBtn.style.zIndex = 9999;
        }
        if (isOpen && header) {
            body?.classList.add('body--overlay');
            header.style.zIndex = 2;
        } else {
            body?.classList.remove('body--overlay');
            header.style.zIndex = 3;
        }
    }, [isOpen]);

    return (
        <div className={isOpen ? 'modal' : 'modal--hide'}>
            <div className={'modal__overlay'} onClick={handleClose}/>
            <div
                className={`modal__content ${className}`}
                onClick={event => event.stopPropagation()}>
                <span className={'modal__close'} onClick={handleClose}><IconClose width={20} height={20} color={'#000000'}/></span>
                {children}</div>
        </div>
    );
};
