import React from 'react';
import {IIconProps} from './types';

export const IconRight = ({width, height, color}:IIconProps) => {
    return (
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L8 7.5L1 14" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
