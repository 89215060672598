import React from 'react';

interface IAdvancedSearchProps {
    handle: () => void;
    text: string
}

export const AdvancedSearch = ({handle, text}:IAdvancedSearchProps) => {
    return (
        <div className={'advanced-search'}>
            <button className={'advanced-search__button'} onClick={handle}>{text}</button>
        </div>
    );
};
