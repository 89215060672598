import React from 'react';
import {IIconProps} from './types';

export const IconSearch = ({width, height, color}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6351 12.4755L13.1183 12.6041L13.6129 10.7456L12.2758 12.1278L12.6351 12.4755ZM12.9416 12.1367L12.5597 11.8139L11.3177 13.2833L13.1179 12.6046L12.9416 12.1367ZM18.1802 16.9594L18.5337 16.6058L18.5337 16.6058L18.1802 16.9594ZM17.4731 17.6665L17.8266 17.3129L17.4731 17.6665ZM12.7647 12.9581L12.4112 13.3117L12.7647 12.9581ZM7.76933 14.0387C11.2318 14.0387 14.0387 11.2318 14.0387 7.76933H13.0387C13.0387 10.6795 10.6795 13.0387 7.76933 13.0387V14.0387ZM1.5 7.76933C1.5 11.2318 4.30687 14.0387 7.76933 14.0387V13.0387C4.85916 13.0387 2.5 10.6795 2.5 7.76933H1.5ZM7.76933 1.5C4.30687 1.5 1.5 4.30687 1.5 7.76933H2.5C2.5 4.85916 4.85916 2.5 7.76933 2.5V1.5ZM14.0387 7.76933C14.0387 4.30687 11.2318 1.5 7.76933 1.5V2.5C10.6795 2.5 13.0387 4.85916 13.0387 7.76933H14.0387ZM7.76933 15.0387C9.82021 15.0387 11.6735 14.1887 12.9945 12.8231L12.2758 12.1278C11.1353 13.3067 9.53824 14.0387 7.76933 14.0387V15.0387ZM0.5 7.76933C0.5 11.7841 3.75459 15.0387 7.76933 15.0387V14.0387C4.30687 14.0387 1.5 11.2318 1.5 7.76933H0.5ZM7.76933 0.5C3.75459 0.5 0.5 3.75459 0.5 7.76933H1.5C1.5 4.30687 4.30687 1.5 7.76933 1.5V0.5ZM15.0387 7.76933C15.0387 3.75459 11.7841 0.5 7.76933 0.5V1.5C11.2318 1.5 14.0387 4.30687 14.0387 7.76933H15.0387ZM13.3234 12.4595C14.3933 11.1938 15.0387 9.55627 15.0387 7.76933H14.0387C14.0387 9.31121 13.4827 10.7219 12.5597 11.8139L13.3234 12.4595ZM13.1179 12.6046L13.1183 12.6046L13.8254 11.8975C13.5381 11.6102 13.1203 11.535 12.7652 11.6688L13.1179 12.6046ZM13.1183 12.6046L17.8266 17.3129L18.5337 16.6058L13.8254 11.8975L13.1183 12.6046ZM17.8266 17.3129H17.8266L18.5337 18.02C18.9242 17.6295 18.9242 16.9963 18.5337 16.6058L17.8266 17.3129ZM17.8266 17.3129L17.1195 18.02C17.51 18.4105 18.1432 18.4105 18.5337 18.02L17.8266 17.3129ZM17.8266 17.3129L13.1183 12.6046L12.4112 13.3117L17.1195 18.02L17.8266 17.3129ZM13.1183 12.6046L13.1183 12.6041L12.1519 12.3469C12.0636 12.6788 12.1492 13.0497 12.4112 13.3117L13.1183 12.6046Z"
                fill={color}/>
        </svg>
    );
};
