import React from 'react';
import {IconPrise} from '../../../../icons';
import './style.scss';
import {useResizeWindow} from '../../../../hooks';

export const Offer = () => {
    const width = useResizeWindow();
    return (
        <div className={'offer-block'}>
            <h2 className={'offer-block__title'}>
                Тарифы размещения
            </h2>
            <p className={'offer-block__text'}>
                3 месяца <span className={'offer-block__text--insert'}>
                    <span className={'offer-block__title--num'}>59 900 ₽</span>
                </span>
            </p>
            <p className={'offer-block__text'}>
                6 месяцев <span className={'offer-block__text--insert'}><span
                className={'offer-block__title--num'}>119 400 ₽</span>
                </span>
            </p>
            <p className={'offer-block__text'}>
                12 месяцев
                <span className={'offer-block__text--insert'}><span
                    className={'offer-block__title--num'}>238 800 ₽</span>
                </span>
            </p>
            <p className={'offer-block__text'}>Оплата за месяц составит 19 900 ₽ в месяц</p>
            <div className={'offer-block-prise'}>
                <div className={'offer-block-prise__icon'}>
                    <IconPrise/>
                </div>
                <div className={'offer-block-prise__body'}>
                    <p className={'offer-block-prise__text'}>Бонусы для тех, кто размещается впервые:</p>
                </div>
            </div>
            <div className={'offer-block__wrap-text'}>
                <p className={'offer-block__text'}>
                    3 месяца + <strong>1 месяц в подарок</strong> = 59 900 ₽ + <strong>19 900 ₽ в подарок</strong>
                </p>
                <p className={'offer-block__text'}>То есть платите 59 900 ₽ вместо 79 600 ₽</p>
            </div>
            <div className={'offer-block__wrap-text'}>
                <p className={'offer-block__text'}>
                    6 месяцев + <strong>2 месяца в подарок</strong> = 119 400 ₽ + <strong>39 800 ₽ в подарок</strong>
                </p>
                <p className={'offer-block__text'}>То есть платите 119 400 ₽ вместо 159 200 ₽</p>
            </div>
            <div className={'offer-block__wrap-text offer-block__wrap-text--p0'}>
                <p className={'offer-block__text'}>
                    12 месяцев + <strong>3 месяца в подарок</strong> = 238 800 ₽ + <strong>59 700 ₽ в подарок</strong>
                </p>
                <p className={'offer-block__text'}>То есть платите 238 800 ₽ вместо 298 500 ₽</p>
            </div>
            <div className={'offer-block-box'}>
                <p className={'offer-block-box__subtitle'}>
                    <strong>Специальное предложение</strong> для тех, кто готов получать заказы
                    по всей России!
                </p>
                {width > 768
                    ? (
                        <p className={'offer-block-box__title'}>
                            Покажем вашу компанию
                            в первых строчках поиска<br/>
                            <strong>во всех регионах России
                                по 5 кодам ФККО </strong>
                            за <span className={'offer-block-box__title--num'}>59 900 ₽</span> в месяц!
                        </p>
                    ) : (
                        <p className={'offer-block-box__title'}>
                            Покажем вашу компанию
                            в первых строчках поиска<br/>
                            <strong>во всех регионах России
                                по 5 кодам ФККО </strong><br/>
                            за <span className={'offer-block-box__title--num'}>59 900 ₽</span> в месяц!
                        </p>
                    )}

                <p className={'offer-block-box__text'}>
                    Заполнение карточки компании <strong>в подарок!</strong>
                </p>
            </div>
        </div>
    );
};
