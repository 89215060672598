import React, {useState} from 'react';
import {IconChevron} from '../../../icons';
import {useResizeWindow} from '../../../hooks';
const tooltipImg1 = require('../../../assets/images/tooltip-img.png');
const tooltipImg2 = require('../../../assets/images/tooltip-img2.png');
const tooltipImgM1 = require('../../../assets/images/tooltip-img-m.png');
const tooltipImgM2 = require('../../../assets/images/tooltip-img-m2.png');

export const TooltipAccordion = () => {
    const width = useResizeWindow();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isHover, setHover] = useState<boolean>(false);

    return (
        <div className={'tooltip-accordion'}>
            <p className={'tooltip-accordion__pre-title'}>У вас есть отходы, которые необходимо передать сторонней
                компании
                для обработки, утилизации, обезвреживания или размещения?
                Найдите компанию, которая решит вашу задачу здесь
            </p>
            <button
                className={'tooltip-accordion__button'}
                onClick={() => setOpen(!isOpen)}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
            >
                Посмотрите, как пользоваться поиском
                <IconChevron rotate={isOpen ? 0 : 180} width={12} height={6} color={isHover ? '#3252B1' : '#390CA2'}/>
            </button>
            {isOpen
                ? (
                    <div className={'tooltip-accordion__body'}>
                        <img className={'tooltip-accordion__img'}
                             src={
                                 width > 768
                                     ? tooltipImg1
                                     : tooltipImgM1
                             }
                             alt={'tooltip'}/>
                        <img className={'tooltip-accordion__img'}
                             src={
                                 width > 768
                                     ? tooltipImg2
                                     : tooltipImgM2
                             }
                             alt={'tooltip'}/>
                    </div>
                ) : null}
        </div>
    );
};
