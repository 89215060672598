import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {MainLayout} from '../../layouts';
import {InfinityResultList} from '../../components/search';
import {useAppDispatch, useAppSelector, useDebounce, useResizeWindow} from '../../hooks';
import {IAutoComplete, IRealTimeParams} from '../../components/search/types';
import {Input} from '../../fields';
import {Tooltip} from '../../components/tooltip';
import {IconInfo} from '../../icons';
import {getLicenseByInn, setCurrentUtilize} from '../../components/card/store/card.slice';
import {Card} from '../../components/card';
import {RequestForm} from '../../components/requestForm';
import {
    clearUtilize,
    getUtilizesSelect,
} from './store/utilizes.slice';

export const UtilizesPage = () => {
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const {select_utilizes, searchInnNext} = useAppSelector(state => state.utilizes);
    const [realTimeValue, setRealtimeValue] = useState<string>('');
    const debouncedSearchTerm = useDebounce({value: realTimeValue, delay: 500});

    const [autoComplete, setAutoComplete] = useState<IAutoComplete[]>([]);
    const [inputType, setInputType] = useState<string>('');

    const [showCard, setShowCard] = useState<boolean>(false);

    const {control, register, setValue} = useForm({
        //resolver: yupResolver(schema)
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);


    useEffect(
        () => {
            if (debouncedSearchTerm) {
                dispatch(getUtilizesSelect({search: debouncedSearchTerm}));
            }
        }, [debouncedSearchTerm]);

    useEffect(() => {
        if (select_utilizes?.length > 0) {
            const items = select_utilizes.map(i => {
                return {
                    inn: i.inn,
                    name: i.name
                };
            });
            setAutoComplete(items);
        }
    }, [select_utilizes]);

    const handleRealTimeFetch = ({value, name}: IRealTimeParams) => {
        setRealtimeValue(value);
        setInputType(name);
        setShowCard(false);
        dispatch(clearUtilize());
        if (value.length === 0) {
            setAutoComplete([]);
        }
    };

    const fetchNextList = () => {
        dispatch(getUtilizesSelect({path: searchInnNext}));
    };


    const selectedUtilize = (value: IAutoComplete) => {
        const item = select_utilizes.filter(i => i.inn === value.inn).pop();
        dispatch(setCurrentUtilize(item));
        clearFields();
        if (item) {
            dispatch(getLicenseByInn({inn: item?.inn}));
            setShowCard(true);
        }
    };

    const clearFields = () => {
        setRealtimeValue('');
        setAutoComplete([]);
        setValue('search', '');
    };

    return (
        <MainLayout title={'Найдите лицензии компании'}>
            <div className={'container'}>
                <p className={'page-subtitle'}>Введите ИНН или название организации</p>
                <div className={'utilizes'}>
                    <div className={'search__box'}>
                        <Input
                            placeholder={'Название компании'}
                            control={control}
                            register={register}
                            fieldName={'search'}
                            getRealTimeValue={handleRealTimeFetch}
                        />
                        <div className={'search__box--info'}>
                            {width > 450
                                ? <Tooltip isActive={true}
                                           activeText={'В случае, если вы не нашли компанию по наименованию, попробуйте по ИНН'}
                                           text={''}
                                           widthActive={324} width={0} ieRevert={true}>
                                    <IconInfo/>
                                </Tooltip> : (
                                    <>
                                        <IconInfo/>
                                        <p className={'search__box--info-text'}>В случае, если вы не нашли компанию по
                                            наименованию, попробуйте по ИНН</p>
                                    </>
                                )}
                        </div>
                        {inputType === 'search'
                            &&
                            <InfinityResultList
                                preText={'ИНН'}
                                items={autoComplete}
                                handleSelect={selectedUtilize}
                                fetchNextData={fetchNextList}
                                hasMore={searchInnNext !== null}
                            />
                        }
                    </div>
                    {
                        showCard ? (
                            <Card/>
                        ) : null
                    }
                </div>
            </div>
            <RequestForm/>
        </MainLayout>
    );
};
