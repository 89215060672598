import React from 'react';

export const IconTab1 = () => {
    return (
        <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25.968 51.0012C39.7752 51.0009 50.9681 39.8079 50.9681 26.0006C50.9681 12.1933 39.7752 1.00028 25.968 1V20.0049L26.7676 20.8046C27.1793 21.2169 27.1793 21.8846 26.7671 22.2969C26.5614 22.5026 26.2914 22.6057 26.0212 22.6057C26.0035 22.6057 25.9857 22.6053 25.968 22.6044V25.6016H35.9797C36.4452 25.6016 36.8239 25.9797 36.8239 26.4458C36.8239 26.9118 36.4452 27.2902 35.9797 27.2902H25.968V30.625H35.9797C36.4452 30.625 36.8239 31.0031 36.8239 31.4692C36.8239 31.9358 36.4452 32.3134 35.9797 32.3134H25.968V35.6484H35.9797C36.4452 35.6484 36.8239 36.0266 36.8239 36.4926C36.8239 36.959 36.4452 37.3368 35.9797 37.3368H25.968V51.0012ZM35.9781 17.2358H28.6334C28.1668 17.2358 27.7892 16.8577 27.7892 16.3911C27.7892 15.925 28.1668 15.5469 28.6334 15.5469H35.9781C36.4442 15.5469 36.8223 15.925 36.8223 16.3911C36.8223 16.8577 36.4442 17.2358 35.9781 17.2358ZM35.9778 22.2592H30.3033C29.8367 22.2592 29.4591 21.8811 29.4591 21.4145C29.4591 20.9484 29.8367 20.5703 30.3033 20.5703H35.9778C36.4439 20.5703 36.822 20.9484 36.822 21.4145C36.822 21.8811 36.4439 22.2592 35.9778 22.2592Z"
                  fill="#26C6DA"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25.9969 0.998047C12.1906 0.999377 0.998779 12.192 0.998779 25.9987C0.998779 39.8053 12.1906 50.9979 25.9969 50.9993L25.9969 37.3439H25.3266C24.86 37.3439 24.4824 36.9663 24.4824 36.4997C24.4824 36.0336 24.86 35.6555 25.3266 35.6555H25.9969V32.3127H25.3266C24.86 32.3127 24.4824 31.9351 24.4824 31.4685C24.4824 31.0024 24.86 30.6243 25.3266 30.6243H25.9969V27.2895H25.3266C24.86 27.2895 24.4824 26.9111 24.4824 26.445C24.4824 25.979 24.86 25.6008 25.3266 25.6008H25.9969V22.6074C25.7468 22.5937 25.4998 22.4912 25.3079 22.2998L21.7863 18.7778V36.5874C21.7863 37.1705 21.3139 37.6429 20.7313 37.6429C20.1482 37.6429 19.6758 37.1705 19.6758 36.5874V18.7794L16.155 22.2998C15.9493 22.5058 15.6786 22.6089 15.4091 22.6089C15.1387 22.6089 14.8685 22.5058 14.6623 22.2998C14.2505 21.8876 14.2505 21.2198 14.6628 20.8075L19.9489 15.5218C19.9606 15.509 19.9726 15.4964 19.985 15.484C20.1913 15.278 20.4617 15.1749 20.732 15.1751C21.0023 15.1748 21.2727 15.2778 21.479 15.484C21.4895 15.4946 21.4998 15.5052 21.5097 15.5161L25.9969 20.0039L25.9969 0.998047Z"
                  fill="#4DD0E1"/>
        </svg>
    );
};
