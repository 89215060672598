import React, { useEffect, useRef} from 'react';
import SwiperCore, {EffectCoverflow, Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';


interface IGalleryProps {
    images: string[] | null;
    initialSlide: number;
}

SwiperCore.use([EffectCoverflow, Navigation]);

export const Gallery = ({images, initialSlide}: IGalleryProps) => {
    const sliderRef = useRef() as any;
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    useEffect(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideTo(initialSlide);
    }, [initialSlide]);


    return (
        <>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                pagination={true}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper: any) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
            >
                {images !== null &&
                    images.map((i: string, index: number) =>
                        <SwiperSlide
                            key={index}>
                            <img src={i}
                                 alt={'gallery'}
                            />
                        </SwiperSlide>)}
            </Swiper>
            <div className={'gallery-controls'}>
                <div ref={navigationPrevRef} className={'gallery-controls__prev'}>◀</div>
                <div ref={navigationNextRef} className={'gallery-controls__next'}>▶</div>
            </div>
        </>
    );
};
