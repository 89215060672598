import React, {useState} from 'react';
import {IconCamera, IconChevron} from '../../icons';
import {Gallery} from '../gallery';
import {Modal} from '../modal';
import {IAccordionProps} from './types';

export const Accordion = (
    {
        children,
        title,
        isInner,
        isActive,
        handleUploadData,
        images,
        initialState,
        isPaid,
        elementId
    }: IAccordionProps) => {
    const [isOpenGallery, setOpenGallery] = useState<boolean>(false);

    const clickByButton = (isPhoto: boolean) => {
        isPhoto
            ? setOpenGallery(true)
            : handleUploadData && handleUploadData();
    };

    return (
        <div className={isActive ? 'accordion' : 'accordion accordion--notActive'} id={elementId?.toString()}>
            <button
                className={
                    isInner ?
                        'accordion__button accordion__button--inner'
                        : 'accordion__button'
                }
                onClick={(event) => clickByButton(false)}
            >
                <span className={'accordion__button--text'}>{title}</span>
                {(isInner && isPaid) && (
                    <>
                        {images?.length ?
                            (
                                <span className={'show-photo-button'} onClick={(event) => {
                                    event.stopPropagation();
                                    clickByButton(true);
                                }}>
                                    <IconCamera/>
                                    Посмотреть<br /> фото объекта
                                </span>
                            )
                            : null}
                        <Modal isOpen={isOpenGallery} handleClose={() => setOpenGallery(false)}>
                            <div className={'gallery-in-modal'}>

                                <Gallery
                                    initialSlide={0}
                                    images={images}
                                />
                            </div>
                        </Modal>
                    </>
                )}
                <span className={'accordion__button--icon'}>
                    <IconChevron
                        rotate={initialState ? 0 : 180}
                        color={'#1A1A1A'}/>
                </span>
            </button>
            {initialState ? <div className={'accordion__body'}>
                {children}
            </div> : null}
        </div>
    );
};
