import React from 'react';
import {IIconProps} from './types';

export const IconBox = ({width = 36, height = 36, color= '#04BF8A'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill={color} />
            <path
                d="M9.25 25.875C9.25 26.9102 10.0895 27.75 11.125 27.75H24.875C25.9105 27.75 26.75 26.9105 26.75 25.875V15.25H9.25V25.875ZM14.25 18.2188C14.25 17.9609 14.4609 17.75 14.7188 17.75H21.2812C21.5391 17.75 21.75 17.9609 21.75 18.2188V18.5312C21.75 18.7891 21.5391 19 21.2812 19H14.7188C14.4609 19 14.25 18.7891 14.25 18.5312V18.2188ZM26.75 10.25H9.25C8.55898 10.25 8 10.809 8 11.5V13.375C8 13.7188 8.28078 14 8.625 14H27.375C27.7188 14 28 13.7188 28 13.375V11.5C28 10.809 27.4414 10.25 26.75 10.25Z"
                fill="white"/>
        </svg>
    );
};

