import React from 'react';

export const IconWhatsapp = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM7 23L8.376 18.8723C7.67779 17.6979 7.27586 16.3274 7.27586 14.8621C7.27586 10.52 10.7959 7 15.1379 7C19.48 7 23 10.52 23 14.8621C23 19.2041 19.48 22.7241 15.1379 22.7241C13.8168 22.7241 12.5727 22.3967 11.48 21.8212L7 23ZM20.1539 17.4786C19.7909 16.7925 18.4521 16.008 18.4521 16.008C18.1492 15.8353 17.7837 15.816 17.611 16.1239C17.611 16.1239 17.176 16.6455 17.0654 16.7205C16.56 17.0629 16.0919 17.0496 15.6193 16.5771L14.5211 15.4789L13.4229 14.3807C12.9503 13.9081 12.9371 13.44 13.2794 12.9346C13.3545 12.824 13.8761 12.389 13.8761 12.389C14.184 12.216 14.1647 11.8507 13.992 11.5479C13.992 11.5479 13.2074 10.2091 12.5214 9.84606C12.2295 9.69158 11.8712 9.74564 11.6378 9.97902L11.1528 10.464C9.61434 12.0025 10.3719 13.7393 11.9103 15.2778L13.3164 16.6839L14.7225 18.0899C16.261 19.6284 17.9978 20.3859 19.5363 18.8474L20.0212 18.3625C20.2546 18.1285 20.3084 17.7702 20.1539 17.4786Z" fill="url(#paint0_linear_100_1215)"/>
            <defs>
                <linearGradient id="paint0_linear_100_1215" x1="0" y1="30" x2="30" y2="0" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#279FC2"/>
                    <stop offset="1" stopColor="#3252B1"/>
                </linearGradient>
            </defs>
        </svg>
    );
};
