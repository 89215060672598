import letters from './letters.json';

export const latinToCyrillic = (str: string) => {

    const arrS = str.split('');
    let L: string = '';
    for (const S of arrS) {
        letters[S as keyof typeof letters] !== undefined
            ? L = L.concat(letters[S as keyof typeof letters])
            : L = str;
    }
    return L;
};
