import React from 'react';
import {Oval} from 'react-loader-spinner';
import './style.scss';

export const Loader = () => {
    return (
        <div className={'loader'}>
            <Oval
                height={40}
                width={40}
                color="#3252B1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#с2с2с2"
                strokeWidth={3}
                strokeWidthSecondary={3}
            />
        </div>
    );
};
