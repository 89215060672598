import React from 'react';
import {IconChecked, IconUnChecked} from '../../icons';

import './style.scss';
interface ConfirmationCheckboxProps {
    isChecked: boolean;
    handleChecked: () => void;
    text: JSX.Element;
}

export const ConfirmationCheckbox = ({isChecked, handleChecked, text}:ConfirmationCheckboxProps) => {
    return (
        <div className={'confirmation-checkbox'} onClick={handleChecked}>
            <div className={'confirmation-checkbox__checked'} >
                {isChecked ? <IconUnChecked/> : <IconChecked/>}
            </div>
            <p className={'confirmation-checkbox__text'}>
                {text}
            </p>
        </div>
    );
};
