import React from 'react';
import {IBarItemProps} from './BarItem';

export const BarItemMobile = ({notActiveWidth, activeWidth, xNum, text, textMobile, description}: IBarItemProps) => {
    return (
        <div className={'bar-item-mobile'}>
            <div className={'bar-item-mobile__inner'}>
                <div className={'bar-item-mobile__box'}>
                    <div
                        className={'bar-item-mobile__bar bar-item-mobile__bar--notActive'}
                        style={{width: `${notActiveWidth}px`}}
                    />
                    <div className={'bar-item-mobile__numbers'}>
                        <span className={'bar-item-mobile__textXNum'}>{xNum}</span>
                        <span className={'bar-item-mobile__text'}>{textMobile}</span>
                    </div>
                </div>
                <div className={'bar-item-mobile__box'}>
                    <div
                        className={'bar-item-mobile__bar'}
                        style={{width: `${activeWidth}px`}}
                    />
                </div>
            </div>
            <span className={'bar-item-mobile__description'}>{description}</span>
        </div>
    );
};
