import React from 'react';
const image = require('../../../../../../assets/images/img-tab5.jpg');
export const Tab5 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-5'}>
                <h3 className={'desktop-tab__title'}>Добавим фото мест осуществления деятельности по лицензии</h3>
                <p className={'desktop-tab__text'}>Фотографии покажут фактическое существование мест осуществления
                    деятельности. Значит, клиенты могут быть уверены в добросовестном исполнении работ. Это повысит
                    доверие к вашей компании.</p>
            </div>
            <div className={'desktop-tab__box'}>
                <img src={image} alt={'tab-5'} loading={'lazy'}/>
            </div>
        </div>
    );
};
