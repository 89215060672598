import React from 'react';
import {IconBadge1, IconBadge2, IconInfo} from '../../../../icons';
import {Tooltip} from '../../../../components/tooltip';
import './style.scss';

export const Badge = () => {

    return (
        <div className={'badges'}>
            <div className={'badge'}>
               <div className={'badge__icon'} ><IconBadge1/></div>
                <div className={'badge__body'}>
                    <p className={'badge__text'}>
                        На Умном Экологе
                        <span className={'badge__text--insert'}> более
                        43 000 лицензий
                       </span>
                        <Tooltip
                            isActive={true}
                            activeText={'Если у вас есть действующая лицензия, выданная Росприроднадзором, то мы уже создали карточку вашей компании на Умном Экологе. В ней обязательно размещены проверенные лицензии. А вот контактные данные могут быть указаны, а могут отсутствовать - в зависимости от того, есть ли эта информация в официальных источниках.'}
                            text={''}
                            widthActive={195}
                            width={0}
                            ieRevert={true}>
                            <IconInfo />
                        </Tooltip>
                    </p>

                </div>

            </div>
            <div className={'badge'}>
                <div className={'badge__icon'} > <IconBadge2/></div>
                <div className={'badge__body'}>
                    <p className={'badge__text'}>
                        Поэтому ваша компания может
                        <span className={'badge__text--insert'}> отображаться внизу или потеряться среди конкурентов</span>
                    </p>

                </div>
            </div>
        </div>
    );
};
