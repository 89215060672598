const validateNames = {
    'общество с ограниченной ответственностью' : 'OOO',
    'Общество с Ограниченной Ответственностью' : 'OOO',
    'Общество с ограниченной ответственностью' : 'OOO',
    'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ' : 'OOO',
    'обществу с ограниченной ответственностью' : 'OOO',
    'Обществу с Ограниченной Ответственностью' : 'OOO',
    'Обществу с ограниченной ответственностью' : 'OOO',
    'ОБЩЕСТВУ С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ' : 'OOO',
    'открытое акционерное общество' : 'ОАО',
    'Открытое акционерное общество' : 'ОАО',
    'Открытое Акционерное Общество' : 'ОАО',
    'ОТКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО' : 'ОАО',
    'индивидуальный предприниматель' : 'ИП',
    'Индивидуальный предприниматель' : 'ИП',
    'Индивидуальный Предприниматель' : 'ИП',
    'ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ' : 'ИП',
    'закрытое акционерное общество' : 'ЗАО',
    'Закрытое акционерное общество' : 'ЗАО',
    'Закрытое Акционерное Общество' : 'ЗАО',
    'ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО' : 'ЗАО',
    'публичное акционерное общество': 'ПАО',
    'Публичное акционерное общество': 'ПАО',
    'Публичное Акционерное Общество': 'ПАО',
    'ПУБЛИЧНОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО': 'ПАО',
};
export const formatName = (name:string) => {
    for (let i = 0; i < Object.keys(validateNames).length; i++) {
        const key = Object.keys(validateNames)[i];
        if(name.search(key) !== -1) {
            return name.replace(key, validateNames[key as keyof typeof validateNames]);
        }
    }
    return name;
};
