import React from 'react';
import {IIconProps} from './types';

interface IChevronProps extends IIconProps{
    rotate: number
}

export const IconChevron = ({width = 12, height = 7, color, rotate}:IChevronProps) => {
    return (
        <svg style={{transform: `rotate(${rotate}deg)`}} width={width} height={height} viewBox={`0 0 ${12} ${7}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 6L6 1M6 1L1 6M6 1L1.41667 5.58333" stroke={color} strokeLinecap="round"/>
        </svg>
    );
};
