import React from 'react';
import {IconChecked, IconUnChecked} from '../../../icons';

interface IRadioButtonProps {
    name: string;
    index: number;
    isSelected: boolean;
    handleSelect: (index: number | null) => void;
}

export const RadioButton = ({name, index, isSelected, handleSelect}:IRadioButtonProps) => {

    return (
        <div className={'radio-button'} onClick={() => handleSelect(isSelected ? null : index)}>
            <div className={'radio-button__icon'}>{isSelected ? <IconChecked /> : <IconUnChecked />}</div>
            <p className={'radio-button__label'}>{name}</p>
        </div>
    );
};
