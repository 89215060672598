
export const enum InputTypes {
    search = 'search',
    activities = 'activities',
    hazard_classes = 'hazard_classes',
    fkko_codes = 'fkko_codes',
    regions = 'regions',
}

export interface IExecutorState {
    loading: boolean;
    //cardList: [];
    cards: ICard[];
    count: number
    previous: string | null
    next: string | null;
    currentPage: number;
    select_search: IExecutorSelect[];
    select_regions: IRegionSelect[];
    regionNext: string;
    searchFkkoNext: string;
    isEmptyCards : string | null;
    defaultInput : {
        defaultSearch: string;
        defaultRegions: string;
        defaultActivities: string;
        currentFkko: string;
    },
    regionId: string;
}

export interface ISelectExecutorResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: IExecutorSelect[]
}

export interface IExecutorSelect {
    number: number;
    activities: string[];
    hazard_classes: string[]
    description: string;
}

export interface IRegionSelect {
    id: number;
    name:string
}

export interface ICard {
    activities: any[]
    emails: string[]
    inn: string;
    is_paid:boolean;
    is_emails_verified: boolean;
    is_phones_verified: boolean;
    is_website_url_verified: boolean;
    legal_address: string;
    logo: string | null
    name: string;
    ogrn: string;
    rating: number | null;
    phone_numbers: string[]
    website_url: string;
    licenses: string[];
    number_of_reviews: number;
    order_issued_at: string;
    order_number: string;
}
