import React from 'react';

export const IconChecked = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_401_2)">
                <path d="M12 1.33594H2C1.17157 1.33594 0.5 2.00751 0.5 2.83594V12.8359C0.5 13.6644 1.17157 14.3359 2 14.3359H12C12.8284 14.3359 13.5 13.6644 13.5 12.8359V2.83594C13.5 2.00751 12.8284 1.33594 12 1.33594Z" fill="white" stroke="#CCCCCC"/>
                <path d="M3 7L6 10L12 4" stroke="#CCCCCC"/>
            </g>
            <defs>
                <clipPath id="clip0_401_2">
                    <rect width="14" height="15" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};
