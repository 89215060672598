import React  from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {CustomPagination} from '../../../components/pagination';
import {getAboutReview} from '../store/home.slice';
import {ReviewItem} from './ReviewItem';


export const Review = () => {
    const dispatch = useAppDispatch();
    const {count, reviews, currentPage} = useAppSelector(state => state.home);

    const handlePagination = (page:number) => {
        dispatch(getAboutReview({page}));
    };

    return (
        <div className={'users-review'}>
            <h2 className={'users-review__title'}>Воспользовавшись нашим сервисом один раз, компании пользуются им постоянно</h2>
            {reviews.map((i, index) => (
               <ReviewItem key={index} title={i.name} date={i.created_at?.split('T')[0]} text={i.text} />
            ))}
            <CustomPagination
                defaultCurrent={currentPage}
                count={count}
                handle={handlePagination}
            />
        </div>
    );
};
