import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface IInputPhoneProps {
    onChange: (phone:string) => void;
    isRequired: boolean;
    phone: string;
}

export const InputPhone = ({onChange, isRequired, phone}:IInputPhoneProps) => {
    //const [phone, setPhone] = useState<string>('');

    const handleChange = (phone:string) => {
        //setPhone(phone);
        onChange(phone);
    };
    return (
        <div className={'wrapper-input'}>
            <PhoneInput
                country={'ru'}
                placeholder={'Телефон'}
                containerStyle={{
                    width: '100%',
                    background: '#ffffff',
                    display: 'flex',
                    border: '1px solid #F2F2F2',
                    boxShadow: '0 0 10px rgb(62 83 114 / 3%)',
                    borderRadius: '10px',
                    padding: '7px 0px'
                }}
                inputStyle={{
                    border: 'none'
                }}
                value={phone}
                onChange={handleChange}
            />
            {isRequired && <span className={'input--required'}/>}
        </div>

    );
};
