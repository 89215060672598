import {useAlert} from 'react-alert';
import {alertTypes, clearMessage, IAlert} from '../store/app.slice';
import {useAppDispatch} from './app';

export const useAppAlert = () => {
    const alert = useAlert();
    const dispatch = useAppDispatch();

    function initAlert({text, type}:IAlert) {
        if(text.length && type.length) {
            switch (type) {
                case alertTypes.SUCCESS:
                    return alert.success(text, {
                        onClose: () => {
                            dispatch(clearMessage());
                        }
                    });
                case alertTypes.ERROR:
                    return alert.error(text, {
                        onClose: () => {
                            dispatch(clearMessage());
                        }
                    });
                default:
                    return alert.info(text, {
                        onClose: () => {
                            dispatch(clearMessage());
                        }
                    });
            }
        }
    }

    return [initAlert];
};
