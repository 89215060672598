import React from 'react';
import {Controller} from 'react-hook-form';
//import {Categories} from 'emoji-picker-react';
//import {CategoryConfig} from 'emoji-picker-react/dist/config/categoryConfig';
import {IInputProps} from './types';

// const emojiCategories: CategoryConfig[] = [
//     {category: Categories.SUGGESTED, name: 'недавно использованные'},
//     {category: Categories.SMILEYS_PEOPLE, name: 'смайлики люди'},
//     {category: Categories.ANIMALS_NATURE, name: 'животные природа'},
//     {category: Categories.FOOD_DRINK, name: 'еда, напиток'},
//     {category: Categories.TRAVEL_PLACES, name: 'путешествия места'},
//     {category: Categories.ACTIVITIES, name: 'виды деятельности'},
//     {category: Categories.OBJECTS, name: 'объекты'},
//     {category: Categories.SYMBOLS, name: 'символы'},
//     {category: Categories.FLAGS, name: 'флаги'},
// ];

export const Textarea = (
    {control, register, fieldName, placeholder, setValue}: IInputProps
) => {
    // const width = useResizeWindow();
    // const [isEmoji, setEmoji] = useState<boolean>(false);

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({
                         field: {onChange, onBlur, value, name, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                         formState,
                     }) => (
                <>
                    <textarea
                        className={error ? 'textarea textarea--error' : 'textarea'}
                        {...register(fieldName)}
                        placeholder={placeholder}
                    />
                    {/*<div className={'emoji-wrapper'}>*/}
                    {/*    <Emoji style={{cursor: 'pointer'}} onClick={() => setEmoji(!isEmoji)}/>*/}
                    {/*    {isEmoji && <div className={'emoji-box'}><EmojiPicker*/}
                    {/*        lazyLoadEmojis={true}*/}
                    {/*        searchDisabled={true}*/}
                    {/*        width={300}*/}
                    {/*        previewConfig={{*/}
                    {/*            defaultCaption: 'Добавьте свое настроение'*/}
                    {/*        }}*/}
                    {/*        categories={emojiCategories}*/}
                    {/*        onEmojiClick={emojiData => {*/}
                    {/*            setValue(fieldName, value + emojiData.emoji);*/}
                    {/*            setEmoji(false);*/}
                    {/*        }}*/}
                    {/*    /></div>}*/}
                    {/*</div>*/}
                </>
            )}
        />
    );
};
