import React from 'react';
const image = require('../../../../../../assets/images/img-tab6.jpg');
export const Tab6 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-6'}>
                <h3 className={'desktop-tab__title'}>Клиенты смогут оставлять в карточке отзывы о сотрудничестве с вашей
                    компании</h3>
                <p className={'desktop-tab__text'}>Вашу компанию будут выбирать по рекомендациям. Все отзывы тщательно
                    проверяются нашими специалистами перед размещением. На Умном Экологе будет формироваться рейтинг
                    вашей компании по оценкам.</p>
            </div>
            <div className={'desktop-tab__box'}>
                <img src={image} alt={'tab-6'} loading={'lazy'}/>
            </div>
        </div>
    );
};
