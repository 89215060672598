import React from 'react';
import {ConfirmationItem} from '../../../components/confirmationItem';
import {IconFilled, IconVerification} from '../../../icons';

interface IConfirmationProps {
    isVerify: boolean;
    isFilled: boolean;
}

export const Confirmation = ({isVerify, isFilled}: IConfirmationProps) => {
    return (
        <div className={'confirmation'}>
            <ConfirmationItem
                icon={ <IconVerification
                    color={isVerify ? '#04BF8A' : '#BFBFBF'}
                    width={24}
                    height={24}
                />}
                isActive={isVerify}
                activeText={'Лицензия проверена'}
                text={'Лицензия не проверена'}
                widthActive={145}
                width={145}
                />
            <ConfirmationItem
                icon={ <IconFilled
                    color={isFilled ? '#04BF8A' : '#BFBFBF'}
                    width={24}
                    height={24}
                />}
                isActive={isFilled}
                width={298}
                widthActive={350}
                activeText={'Владелец подтвердил Умному Экологу данные о компании'}
                text={'Данные о компании из официальных источников'}
            />
        </div>
    );
};
