import React from 'react';

export interface IBarItemProps {
    activeWidth: number;
    notActiveWidth: number;
    xNum: string;
    text: string;
    textMobile: string;
    description: string;
}

export const BarItem = ({notActiveWidth, activeWidth, xNum, text, textMobile, description}: IBarItemProps) => {
    return (
        <div className={'bar-item'}>
            <div className={'bar-item__inner'}>
                <div className={'bar-item__box'}>
                    <div
                        className={'bar-item__bar bar-item__bar--notActive'}
                        style={{height: `${notActiveWidth}px`}}
                    />
                </div>
                <div className={'bar-item__box'}>
                    <span className={'bar-item__text'}>{text}</span>
                    <span className={'bar-item__textXNum'}>{xNum}</span>
                    <div
                        className={'bar-item__bar'}
                        style={{height: `${activeWidth}px`}}
                    />
                </div>
            </div>
            <span className={'bar-item__description'}>{description}</span>
        </div>
    );
};
