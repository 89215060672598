import React, {useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {Tooltip} from '../../../components/tooltip';
import {IconCopy} from '../../../icons';
import {verifyColors} from '../../../icons/types';
import {formatPhoneNumber} from '../../../helpers/formatPhoneNumber';
import {validEmail} from '../../../helpers/validEmail';

interface IContactItemProps {
    items?: string[];
    name: string;
    text?: string;
    isCopy: boolean;
    handleShowContact?: (name: string) => void;
    innKey: string
}

const uppercaseArr = ['inn', 'ogrn'];
interface IKeys {
    [key:string]: string
}
const keys:IKeys = {
    'Тел:' : 'contactCountEmailKeys',
    'E-mail:' : 'contactCountPhoneKeys'
};

export const ContactItem = ({items, name, isCopy, text, handleShowContact, innKey}: IContactItemProps) => {
    const [isCopyText, setCopyText] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        isCopyText && setTimeout(() => setCopyText(false), 2000);
        if (name === 'Тел:') {
            if (items) {
                formatPhoneNumber(items[0]);
            }
        }
    }, [isCopyText]);

    useEffect(() => {
        const storage = sessionStorage.getItem(keys[name as keyof IKeys]);
        if (storage) {
            const keys = JSON.parse(storage);
            if (_.includes(keys, innKey)) {
                setShow(true);
            }
        }
    }, []);

    const handleCopyText = async (text: string) => {
        await navigator.clipboard.writeText(text);
        setCopyText(true);
    };

    const renderData = useMemo(() => {
        return (items && items?.length > 0)
            ?  show
                ? items?.map(i => (
                <div key={i} className={'contact-item__item'}>
                    <p className={'contact-item__text'}>{
                        name === 'Тел:'
                            ? formatPhoneNumber(i)
                            : validEmail(i)
                                ? '-'
                                : i}</p>
                    {isCopy
                        && <Tooltip
                            ieRevert={false}
                            isActive={isCopyText}
                            activeText={'Скопировано'}
                            text={'Копировать'}
                            widthActive={92}
                            width={82}
                        >
                             <span className={'copy'} onClick={() => handleCopyText(i)}>
                                     <IconCopy
                                         color={verifyColors.notActive}
                                         width={16}
                                         height={19}
                                     />
                                </span>
                        </Tooltip>}
                </div>
            )) : (<button
                    className={'contact-item__text contact-item__text--btn'}
                    onClick={() => {
                        if (handleShowContact) {
                            handleShowContact(name);
                        }
                        setShow(true);
                    }}
                >показать</button>)
            : <div className={'contact-item__item'}> - </div>;
    }, [items, isCopyText, show]);

    return (
        <div className={items && items.length > 1 ? 'contact-item contact-item--nameTop' : 'contact-item'}>
            <p className={
                _.includes(uppercaseArr, name)
                    ? 'contact-item__name contact-item__name--uppercase'
                    : 'contact-item__name'
            }>{name}</p>
            <div className={'contact-item__list'}>
                {items
                    ? renderData :
                    <div className={'contact-item__item'}>
                        <p className={'contact-item__text'}>{text}</p>
                    </div>}
            </div>
        </div>
    );
};
