import React, { useEffect } from 'react';
import {NavLink} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import {buttonTypes} from '../../fields/types';
import {IconRight} from '../../icons';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {Cover} from './icons';
import {Review, ReviewForm, Targets} from './components';
import {getAboutReview} from './store/home.slice';
import './style.scss';

export const Home = () => {
    const dispatch = useAppDispatch();
    const {currentPage} = useAppSelector(state => state.home);

    useEffect(() => {
        currentPage === 1 && dispatch(getAboutReview({page: currentPage}));
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <MainLayout title={''}>
            <div className={'container'}>
                <div className={'cover'}>
                    <div className={'cover__box'}>
                        <h1 className={'cover__title'}>50 000 пользователей решают задачи <span
                            className={'cover__title-inner--insert'}>по обращению с отходами здесь</span></h1>
                        <div className={'description'}>
                            <div className={'description__box'}>
                                <p className={'description__text'}>43 000 лицензий</p>
                            </div>
                            <div className={'description__box'}>
                                <p className={'description__text'}>Мгновенный доступ к базе без регистраций и оплат</p>
                            </div>
                        </div>
                    </div>
                    <div className={'cover__box'}>
                        <Cover/>
                    </div>
                </div>
                <div className={'cover-links'}>
                    <NavLink to={'/company'}
                        className={`button button--${buttonTypes.xl} cover-btn`} >
                        Найти компанию <IconRight color={'#ffffff'}/>
                    </NavLink>
                    <NavLink to={'/licence'}
                        className={`button button--${buttonTypes.xl} cover-btn button__outline`} >
                        Проверить лицензию <IconRight color={'#279FC2'}/>
                    </NavLink>
                </div>
            </div>
            <Targets />
            <div className={'container'}>
                <Review/>
                <ReviewForm />
            </div>
        </MainLayout>
    );
};
