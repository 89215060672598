import React from 'react';
import {IIconProps} from './types';

export const IconFilled = ({width, height, color}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.7449 19.3428C20.4839 19.3428 22.7026 17.1196 22.7026 14.3791C22.7026 11.6386 20.4839 9.41537 17.7449 9.41537C15.006 9.41537 12.7873 11.6386 12.7873 14.3791C12.7873 17.1196 15.006 19.3428 17.7449 19.3428Z"
                stroke={color} strokeWidth="1.3"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.6681 0.00720215H4.29561C2.47307 0.00720215 0.995605 1.48466 0.995605 3.3072V16.6928C0.995605 18.5154 2.47307 19.9928 4.29561 19.9928H12.6681C12.9715 19.9928 13.2653 19.9519 13.5443 19.8752C13.0988 19.5313 12.6963 19.134 12.3463 18.6928H4.29561C3.19104 18.6928 2.29561 17.7974 2.29561 16.6928V3.3072C2.29561 2.20263 3.19104 1.3072 4.29561 1.3072H12.6681C13.7726 1.3072 14.6681 2.20263 14.6681 3.3072V8.17655C15.0797 7.97022 15.5147 7.804 15.9681 7.68298V3.3072C15.9681 1.48466 14.4906 0.00720215 12.6681 0.00720215Z"
                  fill={color}/>
            <line x1="4.11143" y1="5.62454" x2="12.7871" y2="5.62454" stroke={color} strokeWidth="1.3"
                  strokeLinecap="round"/>
            <line x1="4.11143" y1="9.35" x2="11.5564" y2="9.35" stroke={color} strokeWidth="1.3"
                  strokeLinecap="round"/>
            <line x1="4.11143" y1="13.1408" x2="9.09483" y2="13.1408" stroke={color} strokeWidth="1.3"
                  strokeLinecap="round"/>
            <path
                d="M17.9069 16.4706C17.9069 16.4706 16.841 15.1799 16.158 14.3529L17.9069 16.4706ZM17.9069 16.4706L20.8219 12.9412"
                stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

