import React from 'react';
import {Controller} from 'react-hook-form';
import {latinToCyrillic} from '../helpers/latinToCyrillic';
import {IInputProps} from './types';

export const Input = (
    {
        control,
        register,
        fieldName,
        placeholder,
        getRealTimeValue,
        isRequired,
        isReadOnly = false
    }: IInputProps
) => {
    return (
        <Controller
            control={control}
            name={fieldName}
            render={({
                         field: {onChange, onBlur, value, name, ref},
                         fieldState: {invalid, isTouched, isDirty, error},
                         formState,
                     }) =>  (
                <div className={'wrapper-input'}>
                    <input
                        {...register(name)}
                        placeholder={placeholder}
                        readOnly={isReadOnly}
                        autoComplete={'off'}
                        className={error?.message ? 'input input--error' : 'input'}
                        onChange={(event ) => {
                            if( getRealTimeValue) {
                               const value = latinToCyrillic(event.target.value);

                                getRealTimeValue({
                                    value: value,
                                    name: name
                                });
                            }
                        }}
                    />
                    {isRequired && <span className={'input--required'}/>}
                </div>
            )}
        />
    );
};
