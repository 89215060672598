import React from 'react';

export const IconTab4 = () => {
    return (
        <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M49.97 26.0006C49.97 39.8079 38.7771 51.0009 24.9698 51.0012V35.6154H34.4558C35.2768 35.6154 35.9693 34.9522 35.9693 34.1019V16.9041C35.9401 16.0831 35.2768 15.3906 34.4266 15.3906H24.9698V1C38.7771 1.00028 49.97 12.1933 49.97 26.0006ZM24.9698 25.9216V16.5564H34.4558C34.6457 16.5564 34.8035 16.7142 34.8035 16.9041V24.3197L30.4179 19.4286C30.2922 19.3029 30.1344 19.2386 29.9445 19.2386C29.7546 19.2386 29.5968 19.3322 29.5033 19.4899L24.9698 25.9216ZM24.9698 31.7353V27.9561L30.0088 20.7872L34.7743 26.1196V31.7353H24.9698ZM24.9698 34.4497V32.904H34.7713V34.102C34.7743 34.2919 34.6165 34.4497 34.4266 34.4497H24.9698Z" fill="#5C6BC0"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.000732422 25.9987C0.000732422 12.192 11.1926 0.999381 24.9989 0.998047L24.9989 15.3899H14.5174C13.6964 15.3899 13.0039 16.0531 13.0039 16.9034V34.1012C13.0039 34.9222 13.6672 35.6147 14.5174 35.6147H24.9989L24.9989 50.9993C11.1926 50.9979 0.000732422 39.8053 0.000732422 25.9987ZM24.9989 32.9033V34.4489H14.5174C14.3275 34.4489 14.1697 34.2911 14.1697 34.1013V32.9033H24.9989ZM24.9989 27.9641V31.7346H17.5473L21.1441 26.9369L23.4173 29.0523C23.5429 29.178 23.7007 29.2101 23.8906 29.2101C24.0484 29.178 24.2062 29.1166 24.2997 28.9588L24.9989 27.9641ZM24.9989 16.5557V25.9296L23.7328 27.7258L21.4596 25.6455C21.334 25.5198 21.1762 25.4877 21.0184 25.4877C20.8607 25.4877 20.7029 25.5812 20.6094 25.7098L16.0952 31.7024H14.1697V16.8713C14.1697 16.7135 14.3275 16.5557 14.5174 16.5557H24.9989ZM21.5882 21.1634C21.5882 22.5834 20.4195 23.7521 18.9995 23.7521C17.5795 23.7521 16.4429 22.5834 16.4108 21.1634C16.4108 19.7434 17.5795 18.5747 18.9995 18.5747C20.4195 18.5747 21.5882 19.7434 21.5882 21.1634ZM20.4195 21.1634C20.4195 20.3745 19.7884 19.7434 18.9995 19.7434C18.2427 19.7434 17.6116 20.3745 17.5795 21.1634C17.5795 21.9523 18.2106 22.5834 18.9995 22.5834C19.7884 22.5834 20.4195 21.9523 20.4195 21.1634Z" fill="#7986CB"/>
        </svg>

    );
};
