import React from 'react';
import {IIconProps} from './types';

export const IconEcology = ({width = 36, height = 36, color= '#04BF8A'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill={color}/>
            <path
                d="M28 14.461C28 19.457 25.2637 23.6524 21.1523 25.0117C20.3695 25.3218 19.5184 25.4985 18.6254 25.4985C16.0902 25.4985 13.7348 24.0723 12.5434 21.8082C10.4289 23.7586 9.85937 25.9879 9.84961 26.0309C9.74687 26.461 9.36211 26.75 8.93828 26.75C8.86625 26.75 8.79269 26.7415 8.71945 26.7244C8.21594 26.6034 7.90539 26.0963 8.02609 25.5931C8.40101 24.0502 11.5601 16.7494 21.1237 16.7494C21.4687 16.75 21.75 16.4688 21.75 16.125C21.75 15.7813 21.4687 15.5 21.125 15.5C16.9297 15.5 13.8984 16.8516 11.7613 18.4531C11.7689 18.0305 11.8098 17.6 11.8994 17.1621C12.4256 14.5914 14.4791 12.5137 17.0322 11.9199C18.1389 11.6636 19.2072 11.6851 20.1936 11.9197C22.3811 12.44 24.8107 11.6302 26.0256 9.52754C26.2468 9.14363 26.8076 9.15582 26.9889 9.55989C27.6367 10.9985 28 12.7617 28 14.461Z"
                fill="white"/>
        </svg>
    );
};
