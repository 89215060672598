import React, {useCallback,useState} from 'react';
import './style.scss';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavLink, useLocation} from 'react-router-dom';
import {Button, Input, InputPhone} from '../../fields';
import {useAppDispatch} from '../../hooks';
import {buttonTypes} from '../../fields/types';
import {ConfirmationCheckbox} from '../confirmationCheckbox';
import {ISendRequestProps, sendRequest} from '../../store/app.slice';

const schema = yup.object({
    company_name: yup.string().required(),
    email: yup.string().email().required(),
    name: yup.string().required(),
    phone_number: yup.string().required(),
});

export const RequestForm = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState<string>('');
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const {control, register, setValue, handleSubmit} = useForm({
        resolver: yupResolver(schema)
    });

    const handlePhone = useCallback((phone: string) => {
        setPhone(phone);
        setValue('phone_number', phone);
    }, []);

    const submit = handleSubmit((values) => {
        const currentReferer: string = location.pathname.split('/')[1];
        const data: ISendRequestProps = {
            company_name: values.company_name,
            email: values.email,
            name: values.name,
            phone_number: values.phone_number,
            referer: currentReferer
        };
        dispatch(sendRequest({data}));
    });

    return (
        <div className={'request-block'}>
            <div className={'container'}>
                <div className={'request-block__inner'}>
                    <div className={'request-block__box'}>
                        <h2 className={'request-block__title'}>Оставьте заявку —
                            станьте заметнее для клиентов</h2>
                        <ul className={'request-block__list'}>
                            <li className={'request-block__item'}>Укажем ваши почту, телефон и ссылку на сайт</li>
                            <li className={'request-block__item'}>Загрузим фото места осуществления деятельности</li>
                            <li className={'request-block__item'}>Разместим отзывы о вас и благодарственные письма</li>
                        </ul>
                        <p className={'request-block__subtext'}>Пришлем наше предложение в течение 30 минут</p>
                    </div>
                    <div className={'request-block__box'}>
                        <div className={'request-form'}>
                            <div className={'request-form__box'}>
                                <Input
                                    isRequired={true}
                                    placeholder={'Компания'}
                                    control={control}
                                    register={register}
                                    // getRealTimeValue={handleRealTimeFetch}
                                    fieldName={'company_name'}/>
                                {/*<InfinityResultList*/}
                                {/*    preText={'ИНН'}*/}
                                {/*    items={autocomplete}*/}
                                {/*    handleSelect={selectedUtilize}*/}
                                {/*    fetchNextData={fetchNextList}*/}
                                {/*    hasMore={next !== null}*/}
                                {/*/>*/}
                            </div>
                            <div className={'request-form__box'}>
                                <Input
                                    isRequired={true}
                                    placeholder={'Имя'}
                                    control={control}
                                    register={register}
                                    fieldName={'name'}/>
                            </div>
                            <div className={'request-form__box'}>
                                <InputPhone
                                    isRequired={true}
                                    phone={phone}
                                    onChange={handlePhone}/>
                            </div>
                            <div className={'request-form__box'}>
                                <Input
                                    isRequired={true}
                                    placeholder={'E-mail'}
                                    control={control}
                                    register={register}
                                    fieldName={'email'}/>
                            </div>
                            <div className={'request-form__box'}>
                                <ConfirmationCheckbox
                                    isChecked={isDisabled}
                                    handleChecked={() => setDisabled(!isDisabled)}
                                    text={<>Нажимая кнопку «Отправить», я даю свое <NavLink to={'/policy'}>согласие на
                                        обработку моих персональных данных</NavLink>, в
                                        соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных
                                        данных», на
                                        условиях и
                                        для целей, определенных в Согласии на обработку персональных данных</>
                                    }
                                />
                            </div>
                            <Button
                                icon={null}
                                text={'Отправить'}
                                handler={submit}
                                disabled={isDisabled}
                                size={buttonTypes.xl}
                                outline={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
