import React from 'react';

export const IconThreeDot = () => {
    return (
        <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.598145 1.50098C0.598145 1.34147 0.648275 1.20703 0.748535 1.09766C0.853353 0.983724 0.994629 0.926758 1.17236 0.926758C1.35465 0.926758 1.49821 0.981445 1.60303 1.09082C1.70785 1.2002 1.76025 1.33691 1.76025 1.50098C1.76025 1.65592 1.70785 1.78809 1.60303 1.89746C1.49821 2.00684 1.35465 2.06152 1.17236 2.06152C0.994629 2.06152 0.853353 2.00684 0.748535 1.89746C0.648275 1.78809 0.598145 1.65592 0.598145 1.50098ZM3.94775 1.50098C3.94775 1.34147 3.99788 1.20703 4.09814 1.09766C4.20296 0.983724 4.34424 0.926758 4.52197 0.926758C4.70426 0.926758 4.84782 0.981445 4.95264 1.09082C5.05745 1.2002 5.10986 1.33691 5.10986 1.50098C5.10986 1.65592 5.05745 1.78809 4.95264 1.89746C4.84782 2.00684 4.70426 2.06152 4.52197 2.06152C4.34424 2.06152 4.20296 2.00684 4.09814 1.89746C3.99788 1.78809 3.94775 1.65592 3.94775 1.50098ZM7.29736 1.50098C7.29736 1.34147 7.34749 1.20703 7.44775 1.09766C7.55257 0.983724 7.69385 0.926758 7.87158 0.926758C8.05387 0.926758 8.19743 0.981445 8.30225 1.09082C8.40706 1.2002 8.45947 1.33691 8.45947 1.50098C8.45947 1.65592 8.40706 1.78809 8.30225 1.89746C8.19743 2.00684 8.05387 2.06152 7.87158 2.06152C7.69385 2.06152 7.55257 2.00684 7.44775 1.89746C7.34749 1.78809 7.29736 1.65592 7.29736 1.50098Z"
                fill="#1A1A1A"/>
        </svg>
    );
};
