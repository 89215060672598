import React from 'react';

export const IconError = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="33.5" stroke="#FF5F5D" strokeWidth="3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4393 26.5607C23.8536 25.9749 23.8536 25.0251 24.4393 24.4393C25.0251 23.8536 25.9749 23.8536 26.5607 24.4393L35.5 33.3787L44.4393 24.4393C45.0251 23.8536 45.9749 23.8536 46.5607 24.4393C47.1465 25.0251 47.1465 25.9749 46.5607 26.5607L37.6213 35.5L46.5607 44.4393C47.1464 45.0251 47.1464 45.9749 46.5607 46.5607C45.9749 47.1464 45.0251 47.1464 44.4393 46.5607L35.5 37.6213L26.5607 46.5607C25.9749 47.1464 25.0251 47.1464 24.4393 46.5607C23.8536 45.9749 23.8536 45.0251 24.4393 44.4393L33.3787 35.5L24.4393 26.5607Z" fill="#FF5F5D"/>
        </svg>
    );
};
