import React from 'react';
import {IAutoComplete} from '../search/types';

interface ISearchResultsProps {
    items: IAutoComplete[];
    handleSelect: (item: IAutoComplete) => void;
}

export const ResultsList = ({items, handleSelect}: ISearchResultsProps) => {
    return items.length > 0 ? (
        <ul
            className={'result-list'}
        >
            {items.map(i => (
                <li key={i.inn ?? i.id} className={'result-list__item'}
                    onClick={() => handleSelect(i)}>
                    {i.inn ? `ФККО: ${i.inn} / ${i.name}` : `${i.name}`}
                </li>
            ))}
        </ul>
    ) : <></>;
};
