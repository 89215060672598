import React, {useState} from 'react';
import {Buttons, Tab1, Tab2, Tab3, Tab4, Tab5, Tab6, Tab7} from './components';
import './style.scss';

export const TabsDesktop = () => {
    const [current, setCurrent] = useState<number>(0);

    const renderTab = () => {
        switch (current) {
            case 0:
                return <Tab1/>;
            case 1:
                return <Tab2/>;
            case 2:
                return <Tab3/>;
            case 3:
                return <Tab4/>;
            case 4:
                return <Tab5/>;
            case 5:
                return <Tab6/>;
            default:
                return <Tab7/>;
        }
    };

    return (
        <div className={'desktop-tabs'}>
            <h2 className={'desktop-tabs__title'}>Посмотрите, какой будет карточка вашей компании
                в глазах клиента</h2>
            <Buttons currentItem={current} handleClick={(index) => setCurrent(index)}/>
            {renderTab()}
        </div>
    );
};
