import React from 'react';

export const IconSuccess = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="33.5" stroke="#04BF8A" strokeWidth="3"/>
            <path d="M21 35.7469L30.253 45L49.9999 25" stroke="#04BF8A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
