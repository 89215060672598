import React from 'react';

interface ILicenseListProps {
    name: string;
    content: JSX.Element;
    type?: boolean
}

export const LicenseListItem = ({name, content, type}: ILicenseListProps) => {
    return (
        <li className={'utilizes-list__item'}>
            <span className={'utilizes-list__name'}>{name}</span>
            <span className={'utilizes-list__content'}>
                {content}
                {name === 'Дата выдачи:'
                    &&
                    <span
                        className={type
                            ? 'utilizes-list__status utilizes-list__status--active'
                            : 'utilizes-list__status utilizes-list__status--notActive'
                        }
                    >{type ? 'Действующая' : 'Недействующая'}
                    </span>}
            </span>
        </li>
    );
};
