import React from 'react';
import {IconStar} from '../../icons';
import {Tooltip} from '../tooltip';
import {activeStar} from '../../icons/types';
import {useResizeWindow} from '../../hooks';
import {IRatingProps} from './types';



export const Rating = ({num}:IRatingProps) => {
    const width = useResizeWindow();

    return (
        <div className={'rating'}>
            <IconStar color={num !== 0 ? activeStar.active : activeStar.notActive} width={12} height={11} />
            <Tooltip
                ieRevert={width < 575}
                isActive={num !== null}
                activeText={'Рейтинг на Умном Экологе'}
                text={'Отзывов пока нет'}
                widthActive={180}
                width={124}>
                <span className={'rating__count'}>{num !== null ? num : 0}</span>
            </Tooltip>
        </div>
    );
};
