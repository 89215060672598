import React from 'react';

export const IconVk = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
            <g clipPath="url(#clip0_100_1222)">
                <path d="M15.0001 0.599976C7.0471 0.599976 0.600098 7.04698 0.600098 15C0.600098 22.953 7.0471 29.4 15.0001 29.4C22.9531 29.4 29.4001 22.953 29.4001 15C29.4001 7.04698 22.9531 0.599976 15.0001 0.599976ZM20.5381 16.8465C20.5381 16.8465 21.8116 18.1035 22.1251 18.687C22.1341 18.699 22.1386 18.711 22.1416 18.717C22.2691 18.9315 22.2991 19.098 22.2361 19.2225C22.1311 19.4295 21.7711 19.5315 21.6481 19.5405H19.3981C19.2421 19.5405 18.9151 19.5 18.5191 19.227C18.2146 19.014 17.9146 18.6645 17.6221 18.324C17.1856 17.817 16.8076 17.379 16.4266 17.379C16.3782 17.3789 16.3301 17.3865 16.2841 17.4015C15.9961 17.4945 15.6271 17.9055 15.6271 19.0005C15.6271 19.3425 15.3571 19.539 15.1666 19.539H14.1361C13.7851 19.539 11.9566 19.416 10.3366 17.7075C8.3536 15.615 6.5686 11.418 6.5536 11.379C6.4411 11.1075 6.6736 10.962 6.9271 10.962H9.1996C9.5026 10.962 9.6016 11.1465 9.6706 11.31C9.7516 11.5005 10.0486 12.258 10.5361 13.11C11.3266 14.499 11.8111 15.063 12.1996 15.063C12.2724 15.0621 12.344 15.0436 12.4081 15.009C12.9151 14.727 12.8206 12.9195 12.7981 12.5445C12.7981 12.474 12.7966 11.736 12.5371 11.382C12.3511 11.1255 12.0346 11.028 11.8426 10.992C11.9203 10.8847 12.0227 10.7978 12.1411 10.7385C12.4891 10.5645 13.1161 10.539 13.7386 10.539H14.0851C14.7601 10.548 14.9341 10.5915 15.1786 10.653C15.6736 10.7715 15.6841 11.091 15.6406 12.1845C15.6271 12.495 15.6136 12.846 15.6136 13.26C15.6136 13.35 15.6091 13.446 15.6091 13.548C15.5941 14.1045 15.5761 14.736 15.9691 14.9955C16.0204 15.0276 16.0796 15.0448 16.1401 15.045C16.2766 15.045 16.6876 15.045 17.8006 13.1355C18.1439 12.5209 18.4421 11.8822 18.6931 11.2245C18.7156 11.1855 18.7816 11.0655 18.8596 11.019C18.9172 10.9896 18.981 10.9747 19.0456 10.9755H21.7171C22.0081 10.9755 22.2076 11.019 22.2451 11.1315C22.3111 11.31 22.2331 11.8545 21.0136 13.506L20.4691 14.2245C19.3636 15.6735 19.3636 15.747 20.5381 16.8465Z" fill="url(#paint0_linear_100_1222)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_100_1222" x1="1.00049" y1="29.0004" x2="29.0005" y2="1.00037" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#279FC2"/>
                    <stop offset="1" stopColor="#3252B1"/>
                </linearGradient>
                <clipPath id="clip0_100_1222">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
