import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Review} from '../../../components/review';
import {ICard} from '../store/types';
import {useAppDispatch} from '../../../hooks';
import {getLicenseByInn, setCurrentUtilize, setScrollToLicense} from '../../../components/card/store/card.slice';
import {formatName} from '../../../helpers/formatName';
import {Confirmation} from './Confirmation';
import {License} from './License';


export const CardItem = (
    {
        name,
        legal_address,
        ogrn,
        phone_numbers,
        is_phones_verified,
        is_website_url_verified,
        logo,
        rating,
        is_emails_verified,
        website_url,
        emails,
        inn,
        activities,
        licenses,
        number_of_reviews,
        is_paid,
        order_issued_at,
        order_number
    }: ICard
) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectCard = async (license?: string) => {

        dispatch(setCurrentUtilize({
            name,
            legal_address,
            ogrn,
            phone_numbers,
            is_phones_verified,
            is_website_url_verified,
            logo,
            rating,
            is_emails_verified,
            website_url,
            emails,
            inn,
            activities,
            licenses,
            number_of_reviews,
            is_paid,
            order_issued_at,
            order_number
        }));
        license && await dispatch(setScrollToLicense(license));
        await dispatch(getLicenseByInn({inn: inn}));
        navigate('/company-card');
    };

    const renderActivities = activities.map(i => <span key={i.id} className={'card__text'}>{i.value}</span>);
    return (
        <div className={ is_paid ? 'card-wrapper-gradient card-wrapper-gradient--active'
            : 'card-wrapper-gradient card-wrapper-gradient--hover'}>
            <div className={is_paid ? 'card' : 'card card--free'}>
                <div className={'card__header'}>
                    <div className={'card__header-box'}>
                        <h3 className={'card__title'} onClick={() => selectCard()}>{formatName(name)}</h3>
                        {is_paid
                            ? <Confirmation isFilled={is_phones_verified} isVerify={true}/>
                            : <p className={'card__inn'}>
                                ИНН: {inn}
                                <span className={'card__tooltip'} onClick={() => selectCard()}>Перейти в карточку</span>
                            </p>}
                    </div>
                    <div className={'card__header-box'}>
                        {logo ? <img className={'utilizes-card__logo'} src={logo} alt={'logo'}/> : null}
                    </div>
                </div>
                {
                    is_paid
                        ? (
                            <>
                                <div className={'card__body'}>
                                    <p className={'card__inn'}>
                                        ИНН: {inn}
                                        <span className={'card__tooltip'}
                                              onClick={() => selectCard()}>Перейти в карточку</span>
                                    </p>
                                    <p className={'card__subtitle'}> Вид обращения:
                                        {renderActivities}
                                    </p>
                                </div>
                                <div className={'card__footer'}>
                                    <Review isReviewTest={true} rating={rating ? rating : 0} count={number_of_reviews}/>
                                    <License onClick={(license) => selectCard(license)} items={licenses}/>
                                </div>
                            </>
                        ) : null
                }
            </div>
        </div>

    );
};
