import React, {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavLink} from 'react-router-dom';
import {Button, Input, InputPhone, Textarea} from '../../../fields';
import {IconPen} from '../../../icons';
import {buttonTypes} from '../../../fields/types';
import {Modal} from '../../../components/modal';
import {ConfirmationCheckbox} from '../../../components/confirmationCheckbox';
import {useAppDispatch} from '../../../hooks';
import {sendAboutReview} from '../store/home.slice';

const schema = yup.object({
    company_name : yup.string().required(),
    name : yup.string().required(),
    phone_number : yup.string().required(),
    text : yup.string().required(),
});

export const ReviewForm = () => {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState<string>('');
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const {control, register, setValue, handleSubmit} = useForm({
        resolver: yupResolver(schema)
    });

    const handlePhone = useCallback((phone: string) => {
        setPhone(phone);
        setValue('phone_number', phone);
    }, []);

    const submit = handleSubmit(values => {
        const data = {
            company_name: values.company_name,
            name: values.name,
            phone_number: values.phone_number,
            text: values.text,
        };

        dispatch(sendAboutReview({data}));
        clear();
    });

    const clear = () => {
        setValue('name', '');
        setValue('phone_number', '');
        setValue('text', '');
        setPhone('');
        setShowForm(false);
    };
    return (
        <div className={'review-form'}>
            <h2 className={'review-form__title'}>Пользовались нашим сервисом?</h2>
            <div className={'review-form__body'}>
                <span className={'review-form__text'}>Поделитесь своим мнением</span>
                <Button icon={<IconPen />} text={'Оставьте отзыв'} handler={() => setShowForm(true)} size={buttonTypes.xl} outline={true} />
            </div>
            <Modal isOpen={showForm} handleClose={() => setShowForm(false)}>
                <div className={'form'}>
                    <div className={'feedback-form__box'}>
                        <Input
                            placeholder={'Компания'}
                            isRequired={true}
                            control={control}
                            register={register}
                            fieldName={'company_name'} />
                    </div>
                    <div className={'feedback-form__box'}>
                        <Input
                            placeholder={'Имя'}
                            isRequired={true}
                            control={control}
                            register={register}
                            fieldName={'name'} />
                    </div>
                    <div className={'feedback-form__box'}>
                        <InputPhone
                            isRequired={true}
                            phone={phone}
                            onChange={handlePhone}/>
                    </div>
                    <p className={'feedback-form__text'}>Наименование компании и имя не будут отражены в отзыве без вашего согласия</p>
                    <div className={'feedback-form__box'}>
                        <Textarea
                            placeholder={'Поделитесь мнением'}
                            control={control}
                            register={register}
                            fieldName={'text'}
                            setValue={setValue}
                            isRequired={true} />
                    </div>
                    <div className={'feedback-form__box'}>
                        <ConfirmationCheckbox
                            isChecked={isDisabled}
                            handleChecked={() => setDisabled(!isDisabled)}
                            text={<>Нажимая кнопку «Отправить», я даю свое <NavLink to={'/policy'}>согласие на обработку моих персональных данных</NavLink>, в
                                соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на
                                условиях и
                                для целей, определенных в Согласии на обработку персональных данных</>
                            }
                        />
                    </div>
                    <Button
                        icon={null}
                        text={'Отправить'}
                        handler={submit}
                        disabled={isDisabled}
                        size={buttonTypes.xl}
                        outline={false}/>
                </div>
            </Modal>
        </div>
    );
};
