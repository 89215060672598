import React from 'react';
const image = require('../../../../../../assets/images/img-tab1.jpg');
export const Tab1 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-1'}>
                <h3 className={'desktop-tab__title'}>Покажем в результатах
                    поиска выше компаний,
                    размещенных бесплатно</h3>
                <p className={'desktop-tab__text'}>Вспомните, когда вы ищете информацию в поисковых системах, как часто
                    вы переходите на вторую, третью страницы поисковой выдачи? Как правило, вы решаете свой вопрос на
                    первой странице. На нашем сервисе так же - на первые карточки в выдаче приходится в 8 раз больше
                    кликов.</p>
            </div>
            <div className={'desktop-tab__box'}>
                <img src={image} alt={'tab-1'} loading={'lazy'}/>
            </div>
        </div>
    );
};
