import React from 'react';
import {IIconProps} from './types';

export const IconTransport = ({width = 36, height = 36, color= '#04BF8A'}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill={color}/>
            <path
                d="M20.5 11C21.3281 11 22 11.6716 22 12.5V14H23.5844C24.1156 14 24.5969 14.2094 25 14.5844L27.4156 17C27.7906 17.375 28 17.8844 28 18.4156V22C28.5531 22 29 22.4469 29 23C29 23.5531 28.5531 24 28 24H27C27 25.6562 25.6562 27 24 27C22.3156 27 21 25.6562 21 24H17C17 25.6562 15.6562 27 14 27C12.3156 27 11 25.6562 11 24H10.5C9.67156 24 9 23.3281 9 22.5V12.5C9 11.6716 9.67156 11 10.5 11H20.5ZM22 16V19H26V18.4156L23.5844 16H22ZM14 22.5C13.1719 22.5 12.5 23.1719 12.5 24C12.5 24.8281 13.1719 25.5 14 25.5C14.8281 25.5 15.5 24.8281 15.5 24C15.5 23.1719 14.8281 22.5 14 22.5ZM24 25.5C24.8281 25.5 25.5 24.8281 25.5 24C25.5 23.1719 24.8281 22.5 24 22.5C23.1719 22.5 22.5 23.1719 22.5 24C22.5 24.8281 23.1719 25.5 24 25.5Z"
                fill="white"/>
        </svg>
    );
};
