export interface IIconProps {
    color?: string;
    width?: number;
    height?: number;
}

export const enum verifyColors {
    active = '#04BF8A',
    notActive = '#BFBFBF'
}

export const enum activeStar {
    active = '#FFD500',
    notActive = '#BFBFBF'
}
