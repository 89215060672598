import React from 'react';
import {NavLink} from 'react-router-dom';

export const Logo = () => {
    return (
        <NavLink to={'/'} className={'logo'}>
            <svg width="226" height="45" viewBox="0 0 226 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M107.283 0.867188C119.221 0.867188 128.898 10.5556 128.898 22.5068C128.898 34.458 119.221 44.1459 107.283 44.1459C95.345 44.1459 85.668 34.4575 85.668 22.5068C85.668 10.5556 95.3455 0.867188 107.283 0.867188ZM101.564 27.4974C101.564 27.4974 100.911 28.2882 100.938 27.6065C100.965 26.9248 102.086 23.4537 104.591 21.988C107.096 20.5223 109.079 20.1945 111.806 20.9228C114.533 21.651 118.719 23.4193 118.993 24.6561C119.102 25.1463 117.574 26.5907 117.005 27.11C116.437 27.6293 113.599 30.7348 110.306 31.0359C105.471 31.4781 103.396 30.0594 102.805 29.8805C102.755 29.8654 102.694 29.9454 102.694 29.9454C102.694 29.9454 101.156 31.6356 100.965 32.7537C100.774 33.8713 100.282 34.2805 99.8191 34.2805C99.3561 34.2805 98.5681 34.2262 98.786 33.0538C99.004 31.8815 100.881 29.1828 102.49 27.9279C104.151 26.6329 105.417 25.7063 107.12 25.2835C108.822 24.8607 112.131 24.629 112.131 24.629C112.131 24.629 111.89 24.4438 109.602 24.3895C107.314 24.3352 104.724 24.3347 101.565 27.4974H101.564ZM99.36 23.3208C99.6622 24.3798 99.5779 24.7386 98.7609 24.3022C97.9438 23.8658 95.3256 22.0704 95.5474 18.7404C95.7925 15.0594 97.7801 12.7428 98.7066 11.6518C99.6326 10.5614 99.4689 10.5609 100.177 11.4879C100.885 12.415 102.41 14.4596 102.683 16.3957C103.051 19.0148 102.729 21.3446 101.539 22.7754C100.348 24.2062 100.292 24.3269 99.8191 22.3662C99.3469 20.4054 98.9517 15.7411 98.9517 15.7411C98.9517 15.7411 97.8625 19.3945 99.3604 23.3208H99.36Z"
                      fill="#E8AA8C"/>
                <path
                    d="M12.1572 13.4531L7.085 25.3093L1.43792 13.4531H0L6.43118 26.8797L6.11734 27.6128C5.17633 29.8112 3.89532 30.7014 1.93434 30.4919V31.7744C4.41789 31.9576 6.22195 30.7799 7.50296 27.7699L13.5946 13.4531H12.1567H12.1572ZM27.7908 18.6876H26.2221L21.4642 26.1995L16.7059 18.6876H15.1633V31.7739H16.4705V20.6241L21.3853 28.3978H21.5683L26.4575 20.6765V31.7739H27.7908V18.6876ZM41.2813 18.6876V24.6029H33.1245V18.6876H31.8174V31.7739H33.1245V25.8586H41.2813V31.7739H42.6146V18.6876H41.2813ZM51.817 23.765H47.9478V18.6876H46.6407V31.7739H51.817C54.1437 31.7739 56.1308 30.1772 56.1308 27.7695C56.1308 25.3617 54.1437 23.765 51.817 23.765ZM58.1441 18.6876V31.7739H59.4512V18.6876H58.1441ZM51.817 30.4914H47.9478V25.0475H51.817C53.4642 25.0475 54.7975 26.0943 54.7975 27.7695C54.7975 29.4184 53.4642 30.4914 51.817 30.4914ZM65.5424 16.4103H72.1567V15.2587H65.5424V16.4103ZM73.1243 18.6876L64.7845 29.497V18.6876H63.4773V31.7739H64.6275L72.9412 20.9645V31.7739H74.2746V18.6876H73.1243Z"
                    fill="#5E616A"/>
                <path
                    d="M146.997 13.2031C143.651 13.2031 140.67 14.9307 139.154 17.6003L140.383 18.3067C141.612 16.056 144.147 14.5379 146.997 14.5379C151.572 14.5379 154.736 17.8097 155.023 21.9974H144.696V23.3061H155.023C154.735 27.4938 151.572 30.7656 146.997 30.7656C144.095 30.7656 141.559 29.2213 140.356 26.9182L139.102 27.6251C140.592 30.3209 143.598 32.1009 146.997 32.1009C152.565 32.1009 156.435 27.887 156.435 22.6525C156.435 17.418 152.565 13.2041 146.997 13.2041V13.2031ZM169.689 31.8119L162.396 25.0069L169.428 18.7256H167.598L160.984 24.667V18.7256H159.677V31.8119H160.984V25.3735L167.912 31.8119H169.689ZM176.983 32.0999C180.774 32.0999 183.833 29.1423 183.833 25.2688C183.833 21.3948 180.774 18.4376 176.983 18.4376C173.167 18.4376 170.108 21.3952 170.108 25.2688C170.108 29.1423 173.167 32.0999 176.983 32.0999ZM176.983 30.8436C173.872 30.8436 171.441 28.3835 171.441 25.2688C171.441 22.154 173.873 19.6939 176.983 19.6939C180.095 19.6939 182.5 22.1545 182.5 25.2688C182.5 28.3835 180.095 30.8436 176.983 30.8436ZM197.062 18.7256H187.859V26.3156C187.859 29.6921 186.787 30.7651 184.774 30.5818V31.8119C187.232 31.9952 189.166 30.896 189.166 26.3156V20.0076H195.754V31.8119H197.062V18.7256ZM207.075 32.0999C210.865 32.0999 213.924 29.1423 213.924 25.2688C213.924 21.3948 210.865 18.4376 207.075 18.4376C203.258 18.4376 200.199 21.3952 200.199 25.2688C200.199 29.1423 203.258 32.0999 207.075 32.0999ZM207.075 30.8436C203.964 30.8436 201.532 28.3835 201.532 25.2688C201.532 22.154 203.964 19.6939 207.075 19.6939C210.186 19.6939 212.591 22.1545 212.591 25.2688C212.591 28.3835 210.186 30.8436 207.075 30.8436ZM225.245 18.7256H217.062V31.8119H218.369V20.0076H225.245V18.7251V18.7256Z"
                    fill="#5E616A"/>
                <path
                    d="M128.304 22.5063C128.304 34.1816 118.85 43.6454 107.189 43.6454C95.5282 43.6454 86.0742 34.1816 86.0742 22.5063C86.0742 10.831 95.5282 1.36719 107.189 1.36719C118.85 1.36719 128.304 10.831 128.304 22.5063Z"
                    fill="#FEFEFE" stroke="#3252B1"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M99.3609 23.3213C99.6632 24.3802 99.5789 24.739 98.7618 24.3026C97.9448 23.8662 95.3266 22.0708 95.5484 18.7408C95.7935 15.0598 97.7811 12.7432 98.7076 11.6523C99.6336 10.5618 99.4699 10.5613 100.178 11.4884C100.886 12.4154 102.411 14.4601 102.684 16.3961C103.052 19.0153 102.73 21.345 101.54 22.7758C100.349 24.2066 100.293 24.3273 99.8201 22.3666C99.3479 20.4058 98.9527 15.7415 98.9527 15.7415C98.9527 15.7415 97.8635 19.3949 99.3614 23.3213H99.3609Z"
                      fill="url(#paint0_linear_1321_2248)"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M101.565 27.4988C101.565 27.4988 100.912 28.2896 100.939 27.6079C100.966 26.9262 102.087 23.4551 104.592 21.9894C107.098 20.5237 109.08 20.1959 111.807 20.9242C114.535 21.6524 118.72 23.4207 118.995 24.6575C119.103 25.1477 117.575 26.5921 117.007 27.1114C116.438 27.6307 113.6 30.7362 110.307 31.0373C105.472 31.4795 103.397 30.0608 102.806 29.8819C102.756 29.8668 102.696 29.9468 102.696 29.9468C102.696 29.9468 101.157 31.637 100.966 32.7551C100.775 33.8727 100.283 34.2819 99.8204 34.2819C99.3574 34.2819 98.5694 34.2276 98.7873 33.0552C99.0053 31.8829 100.882 29.1842 102.491 27.9293C104.153 26.6343 105.419 25.7077 107.121 25.2849C108.823 24.8621 112.132 24.6304 112.132 24.6304C112.132 24.6304 111.891 24.4452 109.603 24.3909C107.316 24.3366 104.725 24.3361 101.566 27.4988H101.565Z"
                      fill="url(#paint1_linear_1321_2248)"/>
                <defs>
                    <linearGradient id="paint0_linear_1321_2248" x1="95.4963" y1="23.9282" x2="106.523" y2="18.4141"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1DE2CE"/>
                        <stop offset="1" stopColor="#3D0AA4"/>
                        <stop offset="1" stopColor="#3D0AA4"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1321_2248" x1="98.6422" y1="33.7235" x2="111.788" y2="15.5479"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1DE2CE"/>
                        <stop offset="1" stopColor="#3D0AA4"/>
                        <stop offset="1" stopColor="#3D0AA4"/>
                    </linearGradient>
                </defs>
            </svg>

        </NavLink>
    );
};
