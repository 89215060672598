import React from 'react';
import {Tooltip} from '../tooltip';
import {IConfirmationItemProps} from './types';

export const ConfirmationItem = (
    {
        isActive,
        activeText,
        text,
        widthActive,
        width,
        icon
    }: IConfirmationItemProps) => {
    return (
        <Tooltip ieRevert={false}
            isActive={isActive}
            widthActive={widthActive}
            width={width}
            text={text}
            activeText={activeText}
        >
            {icon}
        </Tooltip>
    );
};
