import React from 'react';
import {IFkko} from '../../types';

export const RowContent = ({fkko_code, hazard_class, activities}:IFkko) => {
    const activitiesStr = activities.map(i => i.value).join(', ');

    return (
        <div className={'utilizes-location-inner__row'}>
            <div className={'utilizes-location-inner__box'}>
                <span className={'utilizes-location-inner__number'}>{fkko_code.formatted_number}</span>
                <p className={'utilizes-location-inner__content'}>{fkko_code.description}</p>
            </div>
            <div className={'utilizes-location-inner__box'}>
                <p className={'utilizes-location-inner__content'}>{hazard_class.value}</p>
            </div>
            <div className={'utilizes-location-inner__box'}>
                <p className={'utilizes-location-inner__content'}>{activitiesStr}</p>
            </div>
        </div>
    );
};
