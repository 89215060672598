import {useEffect, useState} from 'react';

export const useResizeWindow = () => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const width = document.querySelector('body')?.offsetWidth;
        if(width) {
            setWidth(width);
            window.addEventListener('resize', function () {
                const width = this.innerWidth;
                setWidth(width);
            });
        }
    },[]);

    return width;
};
