import React from 'react';
import './style.scss';
import {NavLink} from 'react-router-dom';
import {IconEmail, IconPhone, IconVk, IconWhatsapp} from '../../icons';
import {Form} from './components';

export const Footer = () => {
    return (
        <div className={'footer'}>
            <div className={'container'}>
                <div className={'footer__inner'}>
                    <div className={'footer__box'}>
                        <p className={'footer__name'}>ООО “Утилизируй онлайн”</p>
                        <p className={'footer__inn'}>Инн 2366036757</p>
                        <NavLink className={'footer__link'} to={'/policy'}>Политика обработки персональных
                            данных</NavLink>
                        <NavLink className={'footer__link'} to={'/contract'}>Договор-оферта</NavLink>
                        <NavLink className={'footer__link'} to={'/contract-tariff10'}>Договор-оферта "Гарантия 10 обращений"</NavLink>
                        <div className={'footer__social'}>
                            <a className={'footer__social-icon'} target={'_blank'} href={'https://vk.com/uecolog'}
                               rel="noreferrer"><IconVk/></a>
                            <a className={'footer__social-icon'} target={'_blank'} href={'https://wa.me/78622910601'}
                               rel="noreferrer"><IconWhatsapp/></a>
                        </div>
                    </div>
                    <div className={'footer__box'}>
                        <h6 className={'footer__box-title'}>Контакты</h6>
                        <a href={'tel:+78002221542'} className={'footer__contact'}>
                            <IconPhone color={'#3252B1'} height={20} width={20}/>
                            +7 (800) 222 15 42
                        </a>
                        <span className={'footer__subtext'}>бесплатно для всех регионов РФ</span>
                        <a href={'mailto:info@uecolog.ru'} className={'footer__contact'}>
                            <IconEmail color={'#3252B1'} height={20} width={20}/>
                            info@uecolog.ru
                        </a>
                        <h6 className={'footer__box-title footer--mb15 footer--mt30'}>Техническая поддержка</h6>
                        <a href={'tel:+78622910601'} className={'footer__contact footer--mb15'}>
                            <IconPhone color={'#3252B1'} height={20} width={20}/>
                            +7 (862) 291 06 01
                        </a>
                        <a href={'mailto:support@uecolog.ru'} className={'footer__contact '}>
                            <IconEmail color={'#3252B1'} height={20} width={20}/>
                            support@uecolog.ru
                        </a>
                    </div>
                    <div className={'footer__box'}>
                        <Form/>
                    </div>
                </div>
            </div>
        </div>
    );
};
