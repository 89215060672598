export const formatPhoneNumber = (phone:string) => {
    const phoneNums = phone?.replaceAll(/[^0-9]/gim,'').split('') ?? 0;

    if(phoneNums.length === 11){
        const firstNum =
            phoneNums[0] === '8'
                ? '+7'
                : phoneNums[0] === '+7' ? phoneNums[0] : `+${phoneNums[0]}`;
        return `${firstNum}${phoneNums[1]}${phoneNums[2]}${phoneNums[3]}${phoneNums[4]}${phoneNums[5]}${phoneNums[6]}${phoneNums[7]}${phoneNums[8]}${phoneNums[9]}${phoneNums[10]}`;
    }
    if(phoneNums.length === 10) {
        return `+7${phoneNums[0]}${phoneNums[1]}${phoneNums[2]}${phoneNums[3]}${phoneNums[4]}${phoneNums[5]}${phoneNums[6]}${phoneNums[7]}${phoneNums[8]}${phoneNums[9]}`;
    }

    // if(phoneNums.length === 6) {
    //     return `${phoneNums[0]}${phoneNums[1]}${phoneNums[2]}${phoneNums[3]}${phoneNums[4]}${phoneNums[5]}`;
    // }

    return phone;
};
