import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.scss';

interface IInfonityScrollProps {
    items: any;
    fetchData: () => void;
    hasMore: boolean;
    children: JSX.Element;
    className: string;
}

export const CustomInfinityScroll = (
    {items, fetchData, hasMore, children, className}: IInfonityScrollProps
) => {
    return (
        <InfiniteScroll
            className={className}
            dataLength={items.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<span className={'infinity-scroll__info'}/>}
            height={450}
        >
            {children}
        </InfiniteScroll>
    );
};
