import React from 'react';
const image = require('../../../../../../assets/images/img-tab2.jpg');
export const Tab2 = () => {
    return (
        <div className={'desktop-tab'}>
            <div className={'desktop-tab__box box-2'}>
                <h3 className={'desktop-tab__title'}>Выделим в поисковой выдаче</h3>
                <p className={'desktop-tab__text'}>Уже на этапе запроса карточка вашей компании будет выглядеть
                    привлекательней карточки бесплатного размещения. Клиенты будут выбирать вас чаще.</p>
            </div>
            <div className={'desktop-tab__box'}>
                <img src={image} alt={'tab-2'} loading={'lazy'}/>
            </div>
        </div>
    );
};
