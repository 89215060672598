import React, {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavLink} from 'react-router-dom';
import {Button, Input, InputPhone, Textarea} from '../../../fields';
import {ConfirmationCheckbox} from '../../../components/confirmationCheckbox';
import {useAppDispatch} from '../../../hooks';
import {sendFeedback} from '../store/feedback.slice';
import {buttonTypes} from '../../../fields/types';
import {RadioButton} from './RadioButton';

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: yup.string().required(),
    occupation: yup.string(),
    text: yup.string().required(),
});

const radioButtons = [
    'Я — эколог, специалист по охране окружающей среды и тп',
    'Я — представитель компании по обращению с отходами',
    'Другое'
];

export const FeedbackForm = () => {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState<string>('');
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [userType, setUserType] = useState<number | null>(null);

    const {control, register, setValue, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const handlePhone = useCallback((phone: string) => {
        setPhone(phone);
        setValue('phone_number', phone);
    }, []);


    const submit = handleSubmit((values) => {
        const data = {
            name: values.name,
            email: values.email,
            phone_number: values.phone_number,
            text: values.text,
            occupation: values.occupation === '' ? radioButtons[userType ?? 0] : values.occupation
        };
        dispatch(sendFeedback({data}));
        clear();
    });

    const clear = () => {
        setValue('name', '');
        setValue('email', '');
        setValue('phone_number', '');
        setValue('text', '');
        setValue('occupation', '');
        setPhone('');
        setUserType(null);
    };

    return (
        <div className={'feedback-form-page'}>
            <div className={'feedback-form__box'}>
                <label className={'feedback-form-page__label'}>Как вас зовут?</label>
                <Input
                    placeholder={'Имя'}
                    control={control}
                    register={register}
                    fieldName={'name'}/>
            </div>
            <div className={'feedback-form__box'}>
                <label className={'feedback-form-page__label'}>Ваш номер телефона</label>
                <InputPhone
                    isRequired={false}
                    phone={phone}
                    onChange={handlePhone}/>
            </div>
            <div className={'feedback-form__box'}>
                <label className={'feedback-form-page__label'}>Ваша почта</label>
                <Input
                    placeholder={'E-mail'}
                    control={control}
                    register={register}
                    fieldName={'email'}/>
            </div>
            <div className={'feedback-form__box'}>
                <label className={'feedback-form-page__label'}>В качестве кого вы пользуетесь нашим сервисом</label>
                {radioButtons.map((i, index) =>
                    <RadioButton
                        key={index}
                        name={i}
                        index={index}
                        isSelected={index === userType}
                        handleSelect={setUserType}/>)}
                <Input
                    placeholder={'Свой вариант'}
                    control={control}
                    register={register}
                    getRealTimeValue={({value, name}) => {
                        value.length > 0 && setUserType(null);
                    }}
                    fieldName={'occupation'}/>
            </div>
            <div className={'feedback-form__box'}>
                <label className={'feedback-form-page__label'}>Здесь напишите свой комментарий: запрос на корректировку
                    чего-то на сайте, ваше к нам предложение или отзыв
                    об использовании нашего сервиса</label>
                <Textarea
                    placeholder={'Комментарий'}
                    control={control}
                    register={register}
                    fieldName={'text'}
                    isRequired={true}/>
            </div>
            <div className={'feedback-form__box'}>
                <ConfirmationCheckbox
                    isChecked={isDisabled}
                    handleChecked={() => setDisabled(!isDisabled)}
                    text={<>Нажимая кнопку «Отправить», я даю свое <NavLink to={'/policy'}>согласие на обработку моих персональных данных</NavLink>, в
                        соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на
                        условиях и
                        для целей, определенных в Согласии на обработку персональных данных</>
                }
                />
            </div>
            <Button
                icon={null}
                text={'Отправить'}
                handler={submit}
                disabled={isDisabled}
                size={buttonTypes.xl}
                outline={false}/>
        </div>
    );
};
