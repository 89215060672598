import React from 'react';

export const IconPrise = () => {
    return (
        <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.7245 10.6633H35.4082C38.3163 9.69388 40.7653 8.41837 41.6837 6.83674L41.7347 6.78572C42.1939 5.86735 42.5 4.64286 41.1224 2.44898C39.7449 0.255103 38.4694 0 37.602 0C33.6225 0 27.8571 7.34694 25.6123 10.5102L25.5102 10.6633H24.4388L24.3367 10.5612C22.0918 7.39797 16.3776 0 12.3469 0C11.1735 0 10 0.816328 8.92857 2.44898C8.52041 3.16327 7.29593 5.25511 8.36736 6.83674L8.46938 6.93878C9.43876 8.57143 11.8367 9.79592 14.4388 10.6633H1.27551C0.561225 10.6633 0 11.2245 0 11.9388V20.3571C0 21.0714 0.561225 21.6327 1.27551 21.6327H2.70408V46.7347C2.70408 47.449 3.2653 48.0102 3.97959 48.0102H46.0204C46.7347 48.0102 47.2959 47.449 47.2959 46.7347V21.6327H48.7245C49.4388 21.6327 50 21.0714 50 20.3571V11.9388C50 11.2755 49.4388 10.6633 48.7245 10.6633ZM37.602 2.60204C37.7551 2.60204 38.2143 2.60204 38.9796 3.82653C39.6939 5 39.5919 5.35714 39.4898 5.61224C38.7245 6.88775 34.949 8.52041 29.5918 9.54082C33.3163 4.79592 36.2755 2.60204 37.602 2.60204ZM10.6122 5.61224L10.5102 5.45919L10.4592 5.40817C10.4592 5.25511 10.5102 4.79592 11.0714 3.82653C11.7347 2.80612 12.1939 2.60204 12.2959 2.60204C13.6224 2.60204 16.5816 4.79592 20.3572 9.64286C14.7449 8.52041 11.2755 6.88775 10.6122 5.61224ZM2.55102 18.8776V13.5204C2.55102 13.3673 2.65306 13.2653 2.80612 13.2653H23.7245V19.1327H2.80612C2.65306 19.1327 2.55102 19.0306 2.55102 18.8776ZM5.2551 21.6837H23.7245V45.5102H5.2551V21.6837ZM44.7449 45.5102H26.2755V21.6837H44.7449V45.5102ZM47.1939 19.1327H26.2245V13.2653H47.1939C47.3469 13.2653 47.449 13.3673 47.449 13.5204V18.8776C47.449 19.0306 47.3469 19.1327 47.1939 19.1327Z" fill="url(#paint0_linear_24_7972)"/>
            <defs>
                <linearGradient id="paint0_linear_24_7972" x1="-1.24334e-06" y1="48" x2="50" y2="2.18657e-06" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#279FC2"/>
                    <stop offset="1" stopColor="#3252B1"/>
                </linearGradient>
            </defs>
        </svg>
    );
};
