import React, {useState} from 'react';

interface IRealTimeProps {
    placeholder: string;
    handler: (text:string) => void;
}

export const RealTimeSearch = ({placeholder, handler}:IRealTimeProps) => {
    const [value, setValue] = useState<string>('');

    const handleChange = (text:string) => {
        setValue(text);
        handler(text);
    };

    return (
        <input
            placeholder={placeholder}
            className={'input'}
            value={value}
            onChange={event =>  handleChange(event.target.value)}
        />
    );
};
