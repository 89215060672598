import React from 'react';

export const IconOffer1 = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.9985 49.9963C38.8045 49.9961 49.9963 38.8041 49.9963 24.9982C49.9963 11.1922 38.8045 0.000218484 24.9985 0V17.7384L26.2747 19.0167C26.7138 19.4571 26.7138 20.1704 26.2741 20.6107C26.0547 20.8305 25.7668 20.9407 25.4787 20.9407C25.3147 20.9407 25.1503 20.9048 24.9985 20.8331V24.1367H36.0999C36.5964 24.1367 37.0002 24.5406 37.0002 25.0385C37.0002 25.5363 36.5964 25.9405 36.0999 25.9405H24.9985V29.5038H36.0999C36.5964 29.5038 37.0002 29.9077 37.0002 30.4055C37.0002 30.9039 36.5964 31.3073 36.0999 31.3073H24.9985V34.875H36.0999C36.5964 34.875 37.0002 35.2789 37.0002 35.7767C37.0002 36.2749 36.5964 36.6785 36.0999 36.6785H24.9985V49.9963ZM36.0968 15.1986H28.2636C27.766 15.1986 27.3633 14.7947 27.3633 14.2963C27.3633 13.7984 27.766 13.3945 28.2636 13.3945H36.0968C36.5939 13.3945 36.9971 13.7984 36.9971 14.2963C36.9971 14.7947 36.5939 15.1986 36.0968 15.1986ZM36.0968 20.5697H30.0448C29.5472 20.5697 29.1445 20.1658 29.1445 19.6674C29.1445 19.1695 29.5472 18.7656 30.0448 18.7656H36.0968C36.5939 18.7656 36.9971 19.1695 36.9971 19.6674C36.9971 20.1658 36.5939 20.5697 36.0968 20.5697Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.9958 0C11.1908 0.00127672 1.20691e-06 11.1928 0 24.9982C-1.20689e-06 38.8035 11.1908 49.995 24.9958 49.9963L24.9958 36.6787H24.7033C24.2057 36.6787 23.803 36.2754 23.803 35.777C23.803 35.2791 24.2057 34.8752 24.7033 34.8752H24.9958V31.3037H24.7033C24.2057 31.3037 23.803 30.9004 23.803 30.402C23.803 29.9041 24.2057 29.5002 24.7033 29.5002H24.9958V25.9407H24.7033C24.2057 25.9407 23.803 25.5366 23.803 25.0387C23.803 24.5409 24.2057 24.137 24.7033 24.137H24.9958V20.8319C24.8827 20.7781 24.7766 20.7044 24.6828 20.6107L20.9268 16.8484V35.8745C20.9268 36.4973 20.423 37.0019 19.8017 37.0019C19.1798 37.0019 18.676 36.4973 18.676 35.8745V16.8507L14.9215 20.6107C14.7021 20.8307 14.4134 20.9409 14.1261 20.9409C13.8376 20.9409 13.5495 20.8307 13.3295 20.6107C12.8904 20.1704 12.8904 19.4571 13.33 19.0167L18.965 13.3734C18.9782 13.3587 18.9918 13.3444 19.0059 13.3303C19.2259 13.1102 19.5144 13.0001 19.8028 13.0002C20.0911 13 20.3795 13.1099 20.5995 13.3303C20.6118 13.3425 20.6237 13.355 20.6352 13.3676L24.9958 17.7357L24.9958 0Z"
                  fill="#F2F2F2"/>
        </svg>
    );
};
