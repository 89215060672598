const errorsMapping:any = {
    name: 'Имя',
    email: 'Email',
    phone_number: 'Номер телефона',
    text: 'Комментарий',
    inn: 'Компания',
    period_id: 'Срок размещения',
    company_name: 'Название компании',
    raiting: 'Рейтинг отзыва',
    utilizer: 'Компания',
    non_field_errors: 'Ошибки не связанные с полями'
};

export const getError = async (obj:any) => {
    const keys = Object.keys(obj);
    let str:string = '';
    for (let i = 0; i < keys.length; i++) {
        str = str.concat(`${errorsMapping[keys[i]]} - ${obj[keys[i]]}`);
    }
    return str;
};
