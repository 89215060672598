import React from 'react';

export const IconTab6 = () => {
    return (
        <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M49.97 26.0006C49.97 39.8079 38.7771 51.0009 24.9698 51.0012V36.8642C25.0114 36.8713 25.0541 36.875 25.0976 36.875H36.9696C37.3839 36.875 37.7196 36.5392 37.7196 36.125C37.7196 35.7108 37.3839 35.375 36.9696 35.375H25.0976C25.0541 35.375 25.0114 35.3787 24.9698 35.3858V30.8751L34.5223 21.7003C36.0589 20.2243 36.0589 17.8184 34.5223 16.3424C34.5148 16.3352 34.5071 16.3282 34.4993 16.3213L34.3037 16.1485C34.2959 16.1416 34.288 16.1349 34.2799 16.1283C32.7531 14.8885 30.4739 14.9606 29.0355 16.3492L24.9698 20.2742V1C38.7771 1.00028 49.97 12.1933 49.97 26.0006ZM24.9698 28.7953V22.3591L30.0773 17.4284C30.9593 16.5769 32.3771 16.525 33.3231 17.2836L33.4937 17.4343C34.4053 18.3207 34.4018 19.7362 33.4832 20.6185L33.2618 20.8312L32.2969 19.9044C31.9981 19.6175 31.5233 19.627 31.2364 19.9258C30.9495 20.2245 30.9591 20.6993 31.2578 20.9862L32.179 21.8711L24.9698 28.7953Z" fill="#AB47BC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.000732422 25.9987C0.000732422 12.192 11.1926 0.999381 24.9989 0.998047L24.9989 19.0291C24.406 19.4602 23.7787 19.9332 23.1369 20.4424C19.9542 22.967 16.2882 26.4746 14.7895 30.2254C14.3461 31.3351 13.9648 32.7865 13.6976 33.9349C13.5627 34.5143 13.4547 35.0266 13.3803 35.3942C13.343 35.5781 13.3142 35.7261 13.2946 35.8286L13.2802 35.9043L13.2721 35.947L13.2663 35.9784L13.2648 35.9867L13.2643 35.989L13.2642 35.9897L13.2642 35.9899C13.2642 35.99 13.2642 35.99 14.002 36.1248L13.2642 35.99C13.2186 36.2395 13.3022 36.4951 13.4864 36.6695C13.6706 36.8438 13.9304 36.9133 14.1771 36.854L20.1131 35.4287C20.2426 35.3976 20.3614 35.3326 20.4575 35.2403L24.9989 30.8785V35.3859C24.6476 35.4482 24.3809 35.7551 24.3809 36.1243C24.3809 36.4935 24.6476 36.8003 24.9989 36.8627L24.9989 50.9993C11.1926 50.9979 0.000732422 39.8053 0.000732422 25.9987ZM24.9989 20.8969V28.7986L19.5651 34.0176L14.9694 35.1211C15.0241 34.8662 15.0875 34.58 15.1585 34.2749C15.4227 33.1398 15.7834 31.7807 16.1824 30.782C17.5243 27.4236 20.9101 24.1234 24.0691 21.6175C24.3833 21.3683 24.6941 21.1278 24.9989 20.8969Z" fill="#BA68C8"/>
        </svg>
    );
};
