import React from 'react';

export const IconTab7 = () => {
    return (
        <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M49.97 26.0006C49.97 39.8079 38.7771 51.0009 24.9698 51.0012V36.4361C25.1361 36.442 25.3032 36.4449 25.471 36.4449C32.2607 36.4449 37.9712 31.528 37.9712 25.2225C37.9712 18.917 32.2607 14 25.471 14C25.3032 14 25.1362 14.003 24.9698 14.0089V1C38.7771 1.00028 49.97 12.1933 49.97 26.0006ZM24.9698 34.4346C25.1359 34.4415 25.3029 34.4449 25.471 34.4449C31.3841 34.4449 35.9712 30.2085 35.9712 25.2225C35.9712 20.2365 31.3841 16 25.471 16C25.3028 16 25.1358 16.0034 24.9698 16.0102V24.3569C25.1172 24.2714 25.2884 24.2225 25.471 24.2225H25.4837C26.036 24.2225 26.4837 24.6702 26.4837 25.2225C26.4837 25.7748 26.036 26.2225 25.4837 26.2225H25.471C25.2884 26.2225 25.1172 26.1735 24.9698 26.088V34.4346ZM30.5822 24.2225C30.0299 24.2225 29.5822 24.6702 29.5822 25.2225C29.5822 25.7748 30.0299 26.2225 30.5822 26.2225H30.595C31.1473 26.2225 31.595 25.7748 31.595 25.2225C31.595 24.6702 31.1473 24.2225 30.595 24.2225H30.5822Z" fill="#EC407A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.000732422 25.9987C0.000732422 12.192 11.1926 0.999381 24.9989 0.998047L24.9989 14.0084C18.4335 14.2451 13.0039 19.0733 13.0039 25.2218C13.0039 27.267 13.6134 29.1802 14.6687 30.8234L13.0676 35.0931C12.9401 35.4331 13.0069 35.8154 13.2421 36.092C13.4773 36.3686 13.844 36.496 14.2 36.4248L19.9434 35.2762C21.4792 35.9616 23.1917 36.3707 24.9989 36.4352L24.9989 50.9993C11.1926 50.9979 0.000732422 39.8053 0.000732422 25.9987ZM24.9989 16.0096C19.3318 16.2429 15.0039 20.3788 15.0039 25.2218C15.0039 27.0109 15.584 28.6859 16.6004 30.1098C16.7938 30.3808 16.8397 30.7302 16.7228 31.0419L15.5719 34.1108L19.8706 33.2511C20.0826 33.2087 20.3027 33.2362 20.4978 33.3294C21.8438 33.9725 23.3706 34.366 24.9989 34.4337V26.0849C24.7029 25.9112 24.5042 25.5897 24.5042 25.2218C24.5042 24.8538 24.7029 24.5323 24.9989 24.3586V16.0096ZM20.393 24.2218C19.8407 24.2218 19.393 24.6695 19.393 25.2218C19.393 25.774 19.8407 26.2218 20.393 26.2218H20.4057C20.958 26.2218 21.4057 25.774 21.4057 25.2218C21.4057 24.6695 20.958 24.2218 20.4057 24.2218H20.393Z" fill="#F06292"/>
        </svg>
    );
};
