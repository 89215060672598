import React from 'react';
import './style.scss';
import {DefaultBanner} from './DefaultBanner';

export const AdvertisingBanners = () => {
    return (
        <div className={'advertising-banners'}>
            <div className={'advertising-banners__box'}>
                <div className={'advertising-banners__main'}>
                    <p className={'advertising-banners__text'}>
                        <span className={'advertising-banners__text--insert'}>Умный эколог</span> – сервис,<br />
                        где заказчики находят исполнителей
                    </p>
                </div>
            </div>
            <div className={'advertising-banners__box'}>
                <DefaultBanner />
            </div>
        </div>
    );
};
