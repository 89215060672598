import React from 'react';
import {IIconProps} from './types';

export const IconClose = ({width, height, color = '#BFBFBF' }:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#F7F7F7"/>
            <path d="M10 10L20 20" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M20 10L10 20" stroke={color} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
};
