import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import {IconPhone} from '../../icons';
import {FeedbackForm} from './components';

import './style.scss';

export const Feedback = () => {

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <MainLayout title={''}>
            <div className={'container'}>
                <div className={'feedback'}>
                    <div className={'feedback__box'}>
                        <div className={'feedback__body'}>
                            <h1 className={'feedback__title'}>Мы всегда рады получать
                                от вас любые сообщения по работе сервиса:</h1>
                            <ul className={'feedback__list'}>
                                <li className={'feedback__list-item'}>Предлагайте улучшения</li>
                                <li className={'feedback__list-item'}>Подсказывайте, если заметили неточность</li>
                                <li className={'feedback__list-item'}>Делитесь впечатлениями</li>
                            </ul>
                        </div>
                        <p className={'feedback__subtext'}>Отправляйте своё сообщение через форму
                            или звоните нам
                            <a href={'tel:+78002221542'} className={'feedback__subtext feedback__subtext--insert'}>
                                <IconPhone color={'#3252B1'} width={15} height={15}/> +7 (800) 222 15 42
                            </a>
                        </p>
                    </div>
                    <div className={'feedback__box'}>
                        <FeedbackForm />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};
