import React from 'react';

export const IconTab2 = () => {
    return (
        <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.97 51.0012C38.7772 51.0009 49.9701 39.8079 49.9701 26.0006C49.9701 12.1933 38.7772 1.00028 24.97 1V13H36.2799C37.2185 13 37.9688 13.7562 37.9688 14.689V38.311C37.9688 39.2497 37.2127 40 36.2799 40H24.97V51.0012ZM24.97 38.3132C30.7044 38.3132 36.2813 38.3128 36.2813 38.311C36.2813 38.311 36.2836 14.6875 36.2799 14.6875C36.2799 14.6875 30.7039 14.687 24.97 14.6868V38.3132ZM32.9031 17.2109H31.4062H26.1531C25.6871 17.2109 25.3093 17.5887 25.3093 18.0547C25.3093 18.5207 25.6871 18.8984 26.1531 18.8984H32.0593V24.8047C32.0593 25.2707 32.4371 25.6484 32.9031 25.6484C33.3691 25.6484 33.7468 25.2707 33.7468 24.8047V18.0547C33.7468 17.5887 33.3691 17.2109 32.9031 17.2109Z" fill="#29B6F6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.9989 0.998047C11.1926 0.999381 0.000732422 12.192 0.000732422 25.9987C0.000732422 39.8053 11.1926 50.9979 24.9989 50.9993V39.9993H12.6909C11.7523 39.9993 11.002 39.2431 11.002 38.3103V14.6882C11.002 13.7496 11.7581 12.9993 12.6909 12.9993H24.9989V0.998047ZM24.9989 14.6861C18.9319 14.686 12.6895 14.6863 12.6895 14.6882C12.6895 14.6882 12.6872 38.3118 12.6909 38.3118C12.6909 38.3118 18.9323 38.3124 24.9989 38.3125V14.6861ZM16.0605 35.7739H17.5574H22.8105C23.2765 35.7739 23.6543 35.3962 23.6543 34.9302C23.6543 34.4642 23.2765 34.0864 22.8105 34.0864H16.9043V28.1802C16.9043 27.7142 16.5265 27.3365 16.0605 27.3365C15.5946 27.3365 15.2168 27.7142 15.2168 28.1802V34.9302C15.2168 35.3962 15.5946 35.7739 16.0605 35.7739Z" fill="#4FC3F7"/>
        </svg>
    );
};
