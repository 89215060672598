import React from 'react';
import {CustomInfinityScroll} from '../custonInfinityScroll/CustomInfinityScroll';
import {IAutoComplete} from './types';

interface ISearchResultsProps {
    items: IAutoComplete[];
    handleSelect: (item: IAutoComplete) => void;
    fetchNextData: () => void;
    hasMore: boolean;
    preText: string;
}

export const InfinityResultList = ({items, handleSelect, hasMore, fetchNextData, preText}: ISearchResultsProps) => {

    return items.length > 0 ? (
        <CustomInfinityScroll
            className={'result-list'}
            items={items}
            hasMore={hasMore}
            fetchData={fetchNextData}
        >
            <>
                {items.map((i,index:number) => (
                    <li key={`${index}-${i.name}`} className={'result-list__item'}
                        onClick={() => handleSelect(i)}>
                        {i.inn ? `${preText}: ${i.inn} / ${i.name}` : `${i.name}` }
                    </li>
                ))}
            </>
        </CustomInfinityScroll>
    ) : <></>;
};
