import React, {useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Controller, Navigation, Pagination} from 'swiper';
import {ILetters} from '../types';
import {Modal} from '../../modal';
import {IconNext, IconPrev} from '../../../icons';
import {LettersItem} from './components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style.scss';


export const Letters = ({letters = []}: { letters: ILetters[] }) => {
    const sliderRef = useRef() as any;
    const [isOpen, setOpen] = useState<boolean>(false);
    const [currentIndex, setIndex] = useState<number>(0);
    const images = letters.map(i => i.photo);

    return (
        <div className={'letters-wrapper'}>
            <h2 className={'letters-wrapper__title'}>Благодарственные письма</h2>
            <div className={'letters'}>
                <button className={'letters-next'} onClick={() => sliderRef.current.swiper.slideNext()}>
                    <IconPrev width={10} height={15}/>
                </button>
                <div className={'letters-slider'}>
                    <Swiper
                        onInit={(core: any) => sliderRef.current = core.el}
                        spaceBetween={50}
                        modules={[Controller, Pagination, Navigation]}
                        breakpoints={{
                            640: {
                                width: 640,
                                slidesPerView: 1,
                            },
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                            991: {
                                width: 991,
                                slidesPerView: 4,
                            }
                        }}>
                        {letters.map((i, index: number) => (
                            <SwiperSlide key={i.id}>
                                <LettersItem
                                    key={i.id}
                                    title={i.name}
                                    date={i.date}
                                    src={i.photo}
                                    handleClick={() => {
                                        setIndex(index);
                                        setOpen(true);
                                    }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <button className={'letters-prev'} onClick={() => sliderRef.current.swiper.slidePrev()}>
                    <IconNext width={10} height={15}/>
                </button>
            </div>
            <Modal className={'letters-modal'} isOpen={isOpen} handleClose={() => setOpen(false)}>
                <div className={'gallery-in-modal'}>
                    <img src={images[currentIndex]} alt={'image'}/>
                </div>
            </Modal>
        </div>
    );
};
