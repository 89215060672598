import React, {useMemo, useState} from 'react';
import {useAppSelector} from '../../../hooks';
import {LicenseTab} from './LicenseTab';
import {ReviewTab} from './ReviewTab';
import {ITabProps} from './types';

const tabsButton: string[] = ['Лицензии', 'Отзывы'];


export const Tabs = (
    {reviews = [], rating}: ITabProps
) => {
    const {currentUtilize, loading} = useAppSelector(state => state.card);
    const [indexTab, setIndexTab] = useState<number>(0);

    const renderTab = useMemo(() => {
        switch (indexTab) {
            case 0:
                return <LicenseTab/>;
            default:
                return <ReviewTab rating={rating} reviews={reviews}/>;
        }
    }, [indexTab, currentUtilize, reviews, loading]);

    const amount = [currentUtilize?.licensesList.filter(i => i.is_active).length, reviews?.length];

    const renderButtons = tabsButton.map((i, index) => (
        <button
            className={
                indexTab === index
                    ? 'tabs-buttons__btn tabs-buttons__btn--active'
                    : 'tabs-buttons__btn'}
            key={`${index}-${i}`}
            onClick={() => setIndexTab(index)}>
            {i}
            <span className={'tabs-buttons__count'}>{amount[index]}</span>
        </button>
    ));

    return (
        <div className={'tabs'}>
            <div className={'tabs-buttons'}>
                {currentUtilize?.is_paid ? renderButtons : (
                    <button
                        className={'tabs-buttons__btn tabs-buttons__btn--active'}
                        onClick={() => null}>
                        Лицензии
                        <span className={'tabs-buttons__count'}>{amount[0]}</span>
                    </button>
                )}
            </div>
            <div className={'tabs-content'}>
                {renderTab}
            </div>
        </div>
    );
};
