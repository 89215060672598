import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import _ from 'lodash';
import {InfinityResultList, ResultsList} from '../../components/search';
import {IAutoComplete, IRealTimeParams} from '../../components/search/types';
import {MainLayout} from '../../layouts';
import {useAppDispatch, useAppSelector, useDebounce, useResizeWindow} from '../../hooks';
import {Button, Input, Selector} from '../../fields';
import {Tooltip} from '../../components/tooltip';
import {IconInfo, IconSearch} from '../../icons';
import {buttonTypes} from '../../fields/types';
import {clearCurrentUtilise, clearScrollToLicense} from '../../components/card/store/card.slice';
import {CustomPagination} from '../../components/pagination';
import {AdvertisingBanners} from '../../components/advertisingBanners';
import {RequestForm} from '../../components/requestForm';
import {sendYandexAction} from '../../helpers/sendYandexAction';
import {deleteSpacesInString} from '../../helpers/deleteSpacesInString';
import {Loader} from '../../components/loader';
import {InputTypes} from './store/types';
import {
    clearExecutor,
    getExecutorCards,
    setDefaultValue,
    setRegionId,
    uploadNextSelectList,
    uploadRegionsSelect,
    uploadSelectList
} from './store/executor.slice';
import {AdvancedSearch, CardItem, TooltipAccordion} from './components';

const schema = yup.object({
    search: yup.string().required(),
    regions: yup.string(),
    activities: yup.string(),
});



export const ExecutorPage = () => {
    const dispatch = useAppDispatch();
    const width = useResizeWindow();
    const {activitiesOptions} = useAppSelector(state => state.app);
    const {
        cards,
        select_regions,
        select_search,
        searchFkkoNext,
        isEmptyCards,
        defaultInput,
        count,
        currentPage,
        regionId,
        loading
    } = useAppSelector(state => state.executor);
    const {currentUtilize} = useAppSelector(state => state.card);

    const [autoComplete, setAutoComplete] = useState<IAutoComplete[]>([]);
    const [showSelect, setShowSelect] = useState<boolean>(false);

    const [inputType, setCurrentInput] = useState<string>('');
    const [search, setSearch] = useState<string>(defaultInput.defaultSearch);
    const [regions, setRegions] = useState<string>(defaultInput.defaultRegions);
    const [activities, setActivities] = useState<string>('');


    const debouncedSearch = useDebounce({value: search, delay: 400});
    const debouncedRegions = useDebounce({value: regions, delay: 400});


    useEffect(() => {
        currentUtilize !== null
        && dispatch(clearCurrentUtilise());
        dispatch(clearScrollToLicense());

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);


    useEffect(() => {
        if(defaultInput.defaultSearch?.length) {
            setValue('search', defaultInput.defaultSearch);
        }
        if(defaultInput.defaultRegions.length > 0) {
            setValue('regions', defaultInput.defaultRegions);
        }
        if(defaultInput.defaultActivities.length > 0) {
            setValue('activities', defaultInput.defaultActivities);
        }
    }, []);

    useEffect(
        () => {
            if (debouncedSearch?.length > 0) {
                dispatch(uploadSelectList({search, activities, inputType}));
            }
        }, [debouncedSearch]);

    useEffect(() => {
        if (debouncedRegions?.length > 0) {
            dispatch(uploadRegionsSelect({search: regions, inputType}));
        }
    }, [debouncedRegions]);


    useEffect(() => {
        if (select_regions?.length > 0) {
            const items = select_regions.map(i => ({id: i.id, name: i.name}));
            setAutoComplete(items);
        } else if (select_search?.length > 0) {
            const items = select_search.map(i => ({inn: i.number, name: i.description}));
            setAutoComplete(items);
        } else {
            setAutoComplete([]);
        }
    }, [select_regions, select_search]);

    const fetchNextData = () => {
        dispatch(uploadNextSelectList({path: searchFkkoNext, inputType}));
    };

    const {control, register, handleSubmit, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const handleSubmitForm = handleSubmit(values => {
        sendYandexAction('reachGoal', 'find-company');
        const arrACT = values?.activities && values.activities.split(',');
        const idsACT = activitiesOptions.filter(i => _.includes(arrACT, i.value)).map(i => i.id).join(',');
        dispatch(clearExecutor());
        dispatch(getExecutorCards({
            activities: idsACT,
            fkko_codes: values.search.split('/')[0].trim(),
            regions: regionId,
            page: 1
        }));
    });

    const handleRealTimeFetch = ({value, name}: IRealTimeParams) => {
        setCurrentInput(name);

        switch (name) {
            case 'search':
                value.length > 0
                    ? setSearch(deleteSpacesInString(value))
                    : dispatch(setDefaultValue({...defaultInput, defaultSearch: ''}));
                break;
            case 'regions':
              value.length > 0
                 ?   setRegions(value)
                 :  dispatch(setRegionId({id: ''}));
                break;
            default:
                setActivities(value);
                setValue(name, value);
                dispatch(setDefaultValue({...defaultInput, defaultActivities: value}));
        }

        if (value.length === 0) {
            clearComponentState();
        }
    };

    const selectedCardItem = (value: IAutoComplete) => {
        if (inputType === InputTypes.search) {
            setValue('search', `${value.inn} / ${value.name}`);
            const fkko = value?.inn?.toString();
           // setFkkoCodes(fkko);
            dispatch(setDefaultValue({
                ...defaultInput,
                defaultSearch: `${value.inn} / ${value.name}`,
                currentFkko: fkko
            }));
        }

        if (inputType === InputTypes.regions) {
            setValue('regions', value.name);
            const id = value?.id?.toString();
            dispatch(setRegionId({id}));
            dispatch(setDefaultValue({...defaultInput, defaultRegions : value.name}));
        }
        clearComponentState();
    };

    function clearComponentState() {
        dispatch(clearExecutor());
        setAutoComplete([]);
    }

    const handlePagination = (page:number) => {
        const arrACT = activities.split(',');
        const idsACT = activitiesOptions.filter(i => _.includes(arrACT, i.value)).map(i => i.id).join(',');
        dispatch(clearExecutor());

        dispatch(getExecutorCards({
            activities: idsACT,
            fkko_codes: defaultInput.currentFkko,
            regions: regionId,
            page: page
        }));
    };

    const handleClearSearch = () => {
        dispatch(clearExecutor());
        setSearch('');
        setRegions('');
        setActivities('');
        dispatch(setDefaultValue({
            ...defaultInput,
            defaultActivities: '',
            defaultSearch: '',
            defaultRegions: '',
            currentFkko: ''
        }));
        setValue('search', '');
        setValue('regions', '');
        setValue('activities', '');
    };

    return (
        <MainLayout title={currentUtilize ? '':'Найти компанию в вашем регионе'}>
            <div className={'container'}>
                    <div className={'search'}>
                        <TooltipAccordion />
                        <div className={'search__box'}>
                            <Input
                                placeholder={'Название отхода'}
                                control={control}
                                register={register}
                                fieldName={'search'}
                                getRealTimeValue={handleRealTimeFetch}
                            />
                            {inputType === 'search'
                                &&
                                <InfinityResultList
                                    preText={'ФККО'}
                                    items={autoComplete}
                                    handleSelect={selectedCardItem}
                                    fetchNextData={fetchNextData}
                                    hasMore={searchFkkoNext !== null}
                                />
                            }
                        </div>
                        <div className={'search__box'}>
                            <Input
                                placeholder={'Регион'}
                                control={control}
                                register={register}
                                fieldName={'regions'}
                                getRealTimeValue={handleRealTimeFetch}
                            />
                            <div className={'search__box--info'}>
                                {width > 450 ? <Tooltip isActive={true}
                                                        activeText={'При отсутствии компаний в вашем регионе Умный Эколог\n' +
                                                            'покажет компании ближайших соседних регионов'} text={''}
                                                        widthActive={324} width={0} ieRevert={true}>
                                    <IconInfo/>
                                </Tooltip> : (
                                    <>
                                        <IconInfo/>
                                        <p className={'search__box--info-text'}>При отсутствии компаний в вашем регионе
                                            Умный Эколог покажет компании ближайших соседних регионов</p>
                                    </>
                                )}
                            </div>
                            {inputType === 'regions'
                                && <ResultsList
                                    items={autoComplete}
                                    handleSelect={selectedCardItem}
                                />}
                        </div>
                        {showSelect
                            ? (
                                <div className={'search__box'}>
                                    <Selector
                                        defaultValue={defaultInput.defaultActivities}
                                        name={'activities'}
                                        placeholder={'Выберите виды деятельности'}
                                        options={activitiesOptions}
                                        handle={handleRealTimeFetch}/>
                                </div>
                            ) : null}
                        <div className={'search__box search__box--last'}>
                            <AdvancedSearch
                                text={showSelect ? 'Скрыть расширенный поиск' : 'Расширенный поиск'}
                                handle={() => setShowSelect(!showSelect)}
                            />
                            <div className={'search__row'}>
                                <Button
                                    icon={
                                        <div className={'mr-10'}>
                                            <IconSearch color={'#ffffff'} width={19} height={19}/>
                                        </div>
                                    }
                                    text={'Найти'}
                                    handler={handleSubmitForm}
                                    size={buttonTypes.md}
                                    outline={false}
                                />
                                <Button icon={null} text={'Очистить поиск'} handler={handleClearSearch} size={buttonTypes.md} outline={true} />
                            </div>
                        </div>
                    </div>
                    <div className={'page-inner'}>
                        <div className={'page-inner__box'}>
                            {isEmptyCards && <span className={'page-empty-text'}>{isEmptyCards}</span>}
                            {loading ? <Loader /> : null}
                            {cards.map(i => (
                                <div className={'card-wrapper'} key={i.inn}>
                                    <CardItem
                                        inn={i.inn}
                                        name={i.name}
                                        activities={i.activities}
                                        ogrn={i.ogrn}
                                        logo={i.logo}
                                        phone_numbers={i.phone_numbers}
                                        emails={i.emails}
                                        rating={i.rating}
                                        is_paid={i.is_paid}
                                        is_phones_verified={i.is_phones_verified}
                                        is_emails_verified={i.is_emails_verified}
                                        is_website_url_verified={i.is_website_url_verified}
                                        website_url={i.website_url}
                                        licenses={i.licenses}
                                        number_of_reviews={i.number_of_reviews}
                                        order_number={i.order_number}
                                        order_issued_at={i.order_issued_at}
                                        legal_address={i.legal_address}/>
                                </div>
                            ))}
                            <div className={'card-wrapper'}>
                                <CustomPagination
                                    defaultCurrent={currentPage}
                                    count={count}
                                    handle={handlePagination}
                                />
                            </div>
                        </div>
                        <div className={'page-inner__box'}>
                            <AdvertisingBanners />
                        </div>
                    </div>
            </div>
            <RequestForm />
        </MainLayout>
    );
};


