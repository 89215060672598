import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import {Accordion} from '../../accordeon';
import {RealTimeSearch} from '../../realTimeSearch';
import {useAppDispatch, useAppSelector, useDebounce} from '../../../hooks';
import {Selector} from '../../../fields';
import {Loader} from '../../loader';
import {getFkkosByPlaceId, getPlacesCurrentLicense, getSearchByPlaces} from '../store/card.slice';
import {CustomPagination} from '../../pagination';
import {deleteSpacesInString} from '../../../helpers/deleteSpacesInString';
import {LicenseListItem, RowContent} from './components';

export const LicenseTab = () => {
    const accordionRef = useRef<any>();
    const dispatch = useAppDispatch();
    const {activitiesOptions, hazardClasses} = useAppSelector(state => state.app);

    const {
        currentUtilize,
        fkkos,
        placeCurrentLicense,
        count,
        currentPage,
        scrollToLicense
    } = useAppSelector(state => state.card);

    const [changeOpenLicenses, setChangeOpenLicenses] = useState<number | null>(0);
    const [changeOpenFkkos, setChangeOpenFkkos] = useState<number | null>(0);

    const [search, setSearch] = useState<string>('');
    const [dangerWasteClasses, setDangerWasteClasses] = useState<string>('');
    const [activities, setActivities] = useState<string>('');
    const [placeId, setPlaceId] = useState<number>(0);

    const debouncedSearchTerm = useDebounce({value: search, delay: 800});

    const uploadFkkos = (placeId: number) => {
        setPlaceId(placeId);
        dispatch(getFkkosByPlaceId({placeId}));
        setTimeout(() => {
            if (accordionRef.current) {
                accordionRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }, 1000);
    };

    const getLicenses = (id: number) => {
        dispatch(getPlacesCurrentLicense({id}));
    };


    useEffect(() => {
        if (scrollToLicense !== '') {
            const indexElement = currentUtilize?.licensesList.findIndex(i => i.number === scrollToLicense) as number;
            setChangeOpenLicenses(indexElement);
        }
    }, [scrollToLicense]);

    useEffect(() => {
        const placeId = placeCurrentLicense[0]?.id;
        if (placeId) {
            setPlaceId(placeId);
            dispatch(getFkkosByPlaceId({placeId}));
        }
    }, [placeCurrentLicense]);

    useEffect(() => {
        const arrHC = dangerWasteClasses.split(',');
        const idsHC = hazardClasses.filter(i => _.includes(arrHC, i.value)).map(i => i.id).join(',');

        const arrACT = activities.split(',');
        const idsACT = activitiesOptions.filter(i => _.includes(arrACT, i.value)).map(i => i.id).join(',');

        dispatch(getSearchByPlaces(
            {
                search: deleteSpacesInString(debouncedSearchTerm),
                hazard_classes: idsHC,
                activities: idsACT,
                placeId: placeId,
                page: 1
            }
        ));

    }, [debouncedSearchTerm]);

    const handleSearchFkko = (value: string, placeId: number) => {
        setPlaceId(placeId);
        setSearch(value);
    };

    const handleDangerWasteClasses = (item: any, placeId: number) => {
        setPlaceId(placeId);
        setDangerWasteClasses(item.value);
        const arrHC = item.value.split(',');
        const idsHC = hazardClasses.filter(i => _.includes(arrHC, i.value)).map(i => i.id).join(',');

        const arrACT = activities.split(',');
        const idsACT = activitiesOptions.filter(i => _.includes(arrACT, i.value)).map(i => i.id).join(',');

        dispatch(getSearchByPlaces({
                search: search,
                hazard_classes: idsHC,
                activities: idsACT,
                placeId,
                page: 1
            }
        ));
    };

    const handleSearchActivities = (item: any, placeId: number) => {
        setPlaceId(placeId);
        setActivities(item.value);

        const arrHC = dangerWasteClasses.split(',');
        const idsHC = hazardClasses.filter(i => _.includes(arrHC, i.value)).map(i => i.id).join(',');

        const arrACT = item.value.split(',');
        const idsACT = activitiesOptions.filter(i => _.includes(arrACT, i.value)).map(i => i.id).join(',');

        dispatch(getSearchByPlaces({
                search: search,
                hazard_classes: idsHC,
                activities: idsACT,
                placeId,
                page: 1
            }
        ));
    };

    const handlePagination = (page: number) => {
        const arrHC = dangerWasteClasses.split(',');
        const idsHC = hazardClasses.filter(i => _.includes(arrHC, i.value)).map(i => i.id).join(',');

        const arrACT = activities.split(',');
        const idsACT = activitiesOptions.filter(i => _.includes(arrACT, i.value)).map(i => i.id).join(',');
        dispatch(getSearchByPlaces(
            {
                search: search,
                hazard_classes: idsHC,
                activities: idsACT,
                placeId: placeId,
                page: page
            }
        ));
        setTimeout(() => {
            if (accordionRef.current) {
                accordionRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }, 1000);

    };

    if (currentUtilize && 'is_active' in currentUtilize.licenses) {
        return <Loader/>;
    }

    return (
        <div className={'tab-item'}>
            {currentUtilize?.licensesList?.map((i, index) => (
                <Accordion
                    key={`${index}-${i.number}`}
                    isInner={false}
                    isActive={i.is_active}
                    images={null}
                    elementId={i.id}
                    title={i.number ?? '-/-/-'}
                    handleUploadData={() => {
                        const currentIndex = index !== changeOpenLicenses ? index : null;
                        getLicenses(i.id);
                        setChangeOpenLicenses(currentIndex);
                        setChangeOpenFkkos(null);
                    }}
                    initialState={changeOpenLicenses === index}
                >
                    <>
                        <ul className={'license-list'}>
                            <LicenseListItem
                                name={'Лицензирующий орган:'}
                                content={<span>{i.authority}</span>}/>
                            <LicenseListItem
                                type={i.is_active}
                                name={'Дата выдачи:'}
                                content={<div className={'utilizes-list__content-box'}>
                                    <span
                                        className={'utilizes-list__content--span'}>{`Приказ №${i.order_number} `}</span>
                                    <span className={'utilizes-list__content--span'}>{`от ${i.order_issued_at}`}</span>
                                </div>}
                            />
                            <LicenseListItem name={'Виды работ:'}
                                             content={<span>{i.activities?.map(i => i.value).join(', ')}</span>}/>
                            <LicenseListItem name={'Класс опасности отхода:'}
                                             content={<span>{i.hazard_classes?.map(i => i.value).join(', ')}</span>}/>
                        </ul>
                        {i.is_active
                            ? (
                                <div className={'utilizes-location'}>
                                    <p className={'utilizes-location__name'}>Места осуществления деятельности</p>
                                    {placeCurrentLicense.map((i, idx) => (
                                        <Accordion
                                            key={i.id}
                                            isActive={true}
                                            isInner={true}
                                            isPaid={currentUtilize?.is_paid}
                                            title={i.address}
                                            images={i.photos}
                                            handleUploadData={() => {
                                                const currentIndex = idx !== changeOpenFkkos ? idx : null;
                                                uploadFkkos(i.id);
                                                setChangeOpenFkkos(currentIndex);
                                            }}
                                            initialState={changeOpenFkkos === idx}
                                        >
                                            <div className={'utilizes-location-inner'} ref={accordionRef}>
                                                <div className={'utilizes-location-inner__header'}>
                                                    <div className={'utilizes-location-inner__row'}>
                                                        <div className={'utilizes-location-inner__box'}>
                                                            <p className={'utilizes-location-inner__name'}>Отход</p>
                                                            <RealTimeSearch
                                                                handler={text => handleSearchFkko(text, i.id)}
                                                                placeholder={''}/>
                                                        </div>
                                                        <div className={'utilizes-location-inner__box'}>
                                                            <p className={'utilizes-location-inner__name'}>Класс
                                                                опасности</p>
                                                            <Selector
                                                                defaultValue={''}
                                                                placeholder={''}
                                                                options={hazardClasses}
                                                                handle={(values) => handleDangerWasteClasses(values, i.id)}
                                                            />
                                                        </div>
                                                        <div className={'utilizes-location-inner__box'}>
                                                            <p className={'utilizes-location-inner__name'}>Вид
                                                                обращения</p>
                                                            <Selector
                                                                defaultValue={''}
                                                                placeholder={''}
                                                                options={activitiesOptions}
                                                                handle={(values) => handleSearchActivities(values, i.id)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {fkkos?.map((i, index) => (
                                                    <RowContent
                                                        key={index}
                                                        activities={i.activities}
                                                        hazard_class={i.hazard_class}
                                                        fkko_code={i.fkko_code}
                                                    />
                                                ))}
                                                <CustomPagination
                                                    count={count}
                                                    handle={handlePagination}
                                                    defaultCurrent={currentPage}/>
                                            </div>
                                        </Accordion>
                                    ))}
                                </div>
                            ) : null}

                    </>
                </Accordion>
            ))}
        </div>
    );
};
