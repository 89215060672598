import React from 'react';

export const IconTab5 = () => {
    return (
        <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.9698 51.0012C38.7771 51.0009 49.97 39.8079 49.97 26.0006C49.97 12.1933 38.7771 1.00028 24.9698 1V16.25H27.6969C29.1935 16.25 30.4108 17.4455 30.4461 18.9336H33.9693C35.4881 18.9336 36.7193 20.1648 36.7193 21.6836V31.9997C36.7193 33.5185 35.4881 34.7497 33.9693 34.7497H24.9698V51.0012ZM24.9698 31.7266V33.2497H33.9693C34.6596 33.2497 35.2193 32.6901 35.2193 31.9997V21.6836C35.2193 20.9932 34.6596 20.4336 33.9693 20.4336H29.6969H28.9469V19.6836V19C28.9469 18.3096 28.3872 17.75 27.6969 17.75H24.9698V21.2736C27.6344 21.5258 29.7189 23.7695 29.7189 26.5001C29.7189 29.2307 27.6344 31.4744 24.9698 31.7266ZM24.9698 30.217C26.8041 29.9722 28.2189 28.4014 28.2189 26.5001C28.2189 24.5989 26.8041 23.0281 24.9698 22.7832V30.217Z" fill="#7E57C2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.9989 0.998047C11.1926 0.999381 0.000732422 12.192 0.000732422 25.9987C0.000732422 39.8053 11.1926 50.9979 24.9989 50.9993L24.9989 34.749H15.002C13.4832 34.749 12.252 33.5178 12.252 31.999V21.6829C12.252 20.1641 13.4832 18.9329 15.002 18.9329H18.5261C18.5614 17.4448 19.7787 16.2493 21.2753 16.2493H24.9989L24.9989 0.998047ZM24.9989 21.2725V17.7493H21.2753C20.5849 17.7493 20.0253 18.3089 20.0253 18.9993V19.6829V20.4329H19.2753H15.002C14.3116 20.4329 13.752 20.9925 13.752 21.6829V31.999C13.752 32.6894 14.3116 33.249 15.002 33.249H24.9989V31.7263C24.8353 31.7417 24.6696 31.7495 24.5021 31.7495C21.6025 31.7495 19.252 29.3989 19.252 26.4994C19.252 23.5998 21.6025 21.2493 24.5021 21.2493C24.6696 21.2493 24.8353 21.2571 24.9989 21.2725ZM24.9989 22.7819C24.8363 22.7604 24.6705 22.7493 24.5021 22.7493C22.4309 22.7493 20.752 24.4283 20.752 26.4994C20.752 28.5705 22.4309 30.2495 24.5021 30.2495C24.6705 30.2495 24.8363 30.2384 24.9989 30.2169V22.7819Z" fill="#9575CD"/>
        </svg>
    );
};
