import React from 'react';
import {
    IconIndicator,
    IconIndicatorActive,
} from '../../icons';
import {tabsConfig} from '../../config';

interface IButtonProps {
    currentItem: number;
    handleClick: (index: number) => void;
}

export const Buttons = ({currentItem, handleClick}: IButtonProps) => {

    return (
        <div className={'desktop-tab-buttons'}>
            {tabsConfig.map((i, index: number) => (
                <div key={`${i.title}-${index}`} className={'desktop-tab-buttons-wrapper'}>
                    <div className={
                        currentItem === index
                        ? 'card-wrapper-gradient card-wrapper-gradient--active'
                    : 'card-wrapper-gradient card-wrapper-gradient--hover'} onClick={() => handleClick(index)}>
                        <div className={'desktop-tab-buttons__btn'}>
                            <div className={'desktop-tab-buttons__icon'}>
                                {i.icon}
                            </div>
                            <p className={'desktop-tab-buttons__text'}>
                                <span className={'desktop-tab-buttons__text--insert'}>{i.title}</span>
                                {i.text}
                            </p>
                        </div>
                    </div>
                    <div className={'desktop-tab-buttons__indicator'}>
                        {currentItem === index
                            ? <IconIndicatorActive/>
                            : <IconIndicator/>}
                    </div>
                </div>
            ))}
        </div>
    );
};
