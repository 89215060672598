import {createAsyncThunk, createSlice, Draft} from '@reduxjs/toolkit';
import {api} from '../../../api';
import {createQueryParams, IQueryProps} from '../../../helpers/createQueryParams';
import {alertTypes, setMessage} from '../../../store/app.slice';
import {IStateUtilizes} from './types';


const initialState: IStateUtilizes = {
    loadingLicence:false,
    loading: false,
    select_utilizes: [],
    utilise: null,
    count: '',
    previous: null,
    next: null,
    nextPlaceFkkos: null,
    searchInnNext: null,
    additionalLoading: false,
    nextInFilter: null
};

interface IUtilizesSelectReq extends IQueryProps{
    path?: string | null
}

export const getUtilizesSelect = createAsyncThunk(
    'utilise/getUtilizesSelect',
    async ({search, path}: IUtilizesSelectReq, {dispatch}) => {
        try {
            const q =
                path !== undefined
                    ? path
                    : await createQueryParams({search});

            const res = await api.get(`/utilizer?${q}`);
            return {
                count: res.data.count,
                previous: res.data.previous,
                next: res.data?.next !== null ? res.data?.next.split('utilizer/?')[1] : null,
                results: res.data.results
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);


const utilizesSlice = createSlice({
    name: 'utilise',
    initialState,
    reducers: {
        clearUtilize: (state: Draft<any>) => {
            state.select_utilizes = [];
            state.currentUtilize = null;
            state.count = '';
            state.previous = null;
            state.next = null;
        },
        clearNextPagesSource: (state) => {
            state.nextPlaceFkkos = null;
            state.next = null;
        }
    },
    extraReducers: builder => {
        builder.addCase(getUtilizesSelect.pending, (state: Draft<any>) => {
            state.loading = true;
        });
        builder.addCase(getUtilizesSelect.fulfilled, (state: Draft<any>, {payload}) => {
            state.loading = false;
            state.select_utilizes = [...state.select_utilizes, ...payload?.results];
            state.count = payload?.count;
            state.previous = payload?.previous;
            state.searchInnNext = payload.next;
        });
        builder.addCase(getUtilizesSelect.rejected, (state: Draft<any>) => {
            state.loading = false;
        });
    }
});

export const {
    clearUtilize,
    clearNextPagesSource,
} = utilizesSlice.actions;

export default utilizesSlice.reducer;
