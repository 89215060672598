import React from 'react';
import {IIconProps} from './types';

export const IconEmail = ({width, height, color}:IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5321 0.0895781C22.3399 0.0300937 22.136 0 21.92 0H2.07998C1.86398 0 1.66003 0.0300937 1.46784 0.0895781C0.612422 0.34725 0 1.13363 0 2.08003V16.32C0 16.8956 0.226641 17.4118 0.596484 17.7861C0.609469 17.8054 0.623672 17.8237 0.639984 17.84C0.743813 17.9334 0.855844 18.0158 0.973734 18.087C1.26698 18.2693 1.6102 18.3807 1.9808 18.3975C2.01384 18.3991 2.04689 18.4 2.07994 18.4H21.9199C21.953 18.4 21.9861 18.3991 22.0191 18.3975C23.132 18.3469 24 17.4467 24 16.32V2.08003C24 1.13363 23.3875 0.34725 22.5321 0.0895781ZM14.3654 9.555L22.8433 2.2002C22.9434 2.38371 23 2.59605 23 2.82462V16.32C23 16.5743 22.9299 16.8085 22.8077 17.0053L14.3654 9.555ZM21.8965 1.2002C21.92 1.2002 21.9429 1.20085 21.9653 1.20198C21.9765 1.20254 21.9876 1.20357 21.9987 1.20441C22.0107 1.2053 22.0229 1.20605 22.0347 1.20732C22.0405 1.20793 22.0461 1.20877 22.0518 1.20943C22.1835 1.22532 22.3001 1.26202 22.4165 1.32024L13.6298 9.15816C13.5778 9.17658 13.5271 9.2047 13.48 9.24005L13.16 9.52003C12.48 10.16 11.44 10.16 10.76 9.52003L10.7167 9.4822L1.56 1.32024C1.67634 1.26207 1.79292 1.22532 1.92464 1.20943C1.93036 1.20877 1.93598 1.20793 1.94175 1.20732C1.95356 1.20605 1.96575 1.20535 1.97784 1.20441C1.98895 1.20357 1.99997 1.20254 2.01117 1.20198C2.03362 1.20085 2.0565 1.2002 2.08003 1.2002H21.8965ZM1.16528 16.9596C1.05995 16.7726 1 16.5549 1 16.32V2.80287C1 2.5743 1.05663 2.36196 1.1567 2.17844L9.59625 9.51919L1.16528 16.9596ZM2.47231 17.2002C2.34256 17.2002 2.21281 17.1738 2.08302 17.1425C2.05484 17.1339 2.02723 17.1241 2 17.1136L10.2177 10.0993L10.24 10.1201C10.4048 10.2595 10.5777 10.3787 10.7573 10.4767C11.7023 11.0183 12.9465 10.8997 13.7601 10.1201L13.8015 10.0814L22 17.1136C21.9727 17.124 21.9452 17.1339 21.9169 17.1425C21.7872 17.1738 21.6574 17.2002 21.5276 17.2002H2.47231Z"
                fill={color}/>
        </svg>
    );
};
