import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IUtilize} from '../../../components/card/types';
import {createQueryParams} from '../../../helpers/createQueryParams';
import {api} from '../../../api';
import {alertTypes, setMessage} from '../../../store/app.slice';
import {IOptionsSelect} from '../../../fields/CustomSelect';
import {getError} from '../../../helpers/getError';

export interface ISendMailReq {
    inn: string;
    period_id: number;
    email: string;
    phone_number: string;
    currentPath: string;
}

export interface ITariffs {
    id: number;
    name: string;
}

export interface IOffer {
    id: number,
    name: string
}

interface IPartnerState {
    autocomplete: IUtilize[];
    count: number;
    previous: string | null;
    next: string | null;
    offerPeriods: IOptionsSelect[],
    allTariffs: IOptionsSelect[]
}

const initialState:IPartnerState ={
    autocomplete: [],
    count: 0,
    next: null,
    previous: null,
    offerPeriods: [],
    allTariffs: []
};

export const getAutocomplete = createAsyncThunk(
    'partner/getAutocomplete',
    async ({search, path}:{search?:string, path?: string}, {dispatch}) => {
        try {
            const q =
                path !== undefined
                    ? path
                    : await createQueryParams({search});

            const res = await api.get(`/utilizer?${q}`);
            return {
                count: res.data.count,
                previous: res.data.previous,
                next: res.data?.next !== null ? res.data?.next.split('utilizer/?')[1] : null,
                results: res.data.results
            };
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }

    }
);

export const getTariffs = createAsyncThunk(
    'partner/getTariffs',
    async (_, {dispatch}) => {
        try {
            const res = await api.get<ITariffs[]>('/offer/tariffs');
            return  res.data.map(i => {
                return {
                    label: i.name,
                    value: i.id.toString()
                };
            });
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const getOfferPeriod = createAsyncThunk(
    'partner/getOfferPeriod',
    async ({tariff_id}:{tariff_id: number}, {dispatch}) => {
        try {
            const res = await api.get<IOffer[]>(`/offer/tariff/${tariff_id}/periods`);
            return  res.data.map(i => {
                return {
                    label: i.name,
                    value: i.id.toString()
                };
            });
        } catch (e) {
            dispatch(setMessage({type: alertTypes.ERROR, text: 'Ошибка', title: ''}));
            throw e;
        }
    }
);

export const sendMail = createAsyncThunk(
    'partner/sendMail',
    async ({data}:{data:ISendMailReq}, {dispatch}) => {
        try {
            const res = await api.post('/offer/bill', data, {
                headers : {
                    'Referer': `http://localhost:3000/${data.currentPath}`,
                }
            });
            if(res.status === 201) {
                dispatch(setMessage({type: alertTypes.SUCCESS, title: 'Спасибо, что выбрали нас!', text: 'Перезвоним в течение 30 минут!'}));
            }
        } catch (e:any) {
            const str = await getError(e.response.data);
            dispatch(setMessage({type: alertTypes.ERROR, text: str, title: ''}));
            throw e;
        }
    }
);

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setAutoComplete: (state, {payload}) => {
            state.autocomplete = payload;
        },
        clear: (state) => {
            state.next = null;
            state.previous = null;
            state.autocomplete = [];
            state.count = 0;
        }
    },
    extraReducers: builder => {
        builder.addCase(getAutocomplete.fulfilled, (state, {payload}) => {
            state.autocomplete = payload.next ? [...state.autocomplete, ...payload?.results] : payload.results;
            state.count = payload?.count;
            state.previous = payload?.previous;
            state.next = payload.next;
        });
        builder.addCase(getOfferPeriod.fulfilled, (state, {payload}) => {
            state.offerPeriods = payload;
        });
        builder.addCase(getTariffs.fulfilled, (state, {payload}) => {
            state.allTariffs = payload;
        });
    }
});

export const {setAutoComplete, clear} = partnerSlice.actions;

export default partnerSlice.reducer;
