import React, {useCallback, useState} from 'react';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {IconLocation} from '../../../icons';
import {Review} from '../../review';
import {Modal} from '../../modal';
import {Button, Input, InputPhone} from '../../../fields';
import {buttonTypes} from '../../../fields/types';
import {useAppDispatch} from '../../../hooks';
import {IUtilizeCard} from '../types';
import {countEmail, countPhone, sendApplication} from '../store/card.slice';
import {formatName} from '../../../helpers/formatName';
import {VerifyComponent} from './VerifyComponent';
import {ContactItem} from './ContactItem';
import {Services} from './Services';


const schema = yup.object({
    name: yup.string().required(),
    phone_number: yup.string().required(),
});

export const UtilizesCard = (
    {
        name,
        inn,
        ogrn,
        emails,
        is_emails_verified,
        is_phones_verified,
        is_website_url_verified,
        website_url,
        phone_numbers,
        logo,
        rating,
        legal_address,
        activities,
        reviews,
        is_paid
    }: IUtilizeCard
) => {
    const dispatch = useAppDispatch();
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('');
    const {handleSubmit, control, setValue, register, reset} = useForm({
        resolver: yupResolver(schema)
    });

    const handlePhone = useCallback((phone: string) => {
        setPhone(phone);
        setValue('phone_number', phone);
    }, []);


    const submit = handleSubmit(values => {
        const data = {
            name: values.name,
            phone_number: values.phone_number,
            utilizer: inn.toString()
        };
        dispatch(sendApplication({data}));
        setOpenModal(false);
        reset();
    });

    return (
        <div className={'utilizes-card'}>
            <div className={'utilizes-card__header'}>
                <div className={'utilizes-card__header-box'}>
                    <div className={'utilizes-card__header-head'}>
                        <h2 className={'utilizes-card__title'}>
                            {formatName(name)}
                            <Review isReviewTest={false} count={reviews} rating={rating}/>
                        </h2>
                        {is_website_url_verified
                            ? <a
                                href={website_url}
                                target={'_blank'}
                                className={'utilizes-card__url'}
                                rel="noreferrer"
                            >{website_url.split('?')[0]}</a>
                            : <span className={'utilizes-card__url utilizes-card__url--my-company'}
                                    onClick={() => setOpenModal(true)}>Это моя компания</span>

                            // <a
                            //     href={'https://ed89428624194aa027472212741d63a3.mtmba.ru/'}
                            //     className={'utilizes-card__url utilizes-card__url--my-company'}
                            //     target={'_blank'} rel="noreferrer">
                            //     Это моя компания
                            // </a>
                        }
                    </div>
                    <VerifyComponent isPhoneVerify={is_phones_verified} isEmailVerify={is_emails_verified}/>
                </div>
                <div className={'utilizes-card__header-box'}>
                    {logo ? <img className={'utilizes-card__logo'} src={logo} alt={'logo'}/> : null}
                </div>
            </div>
            {legal_address ? <address className={'utilizes-card__address'}>
                <IconLocation width={12} height={16}/>
                <p className={'utilizes-card__address--text'}>{legal_address}</p>
            </address> : null}
            <div className={'utilizes-card__body'}>
                <div className={'utilizes-card__col'}>
                    <div className={'utilizes-card__box'}>
                        <div className={'utilizes-card__box--inner'}>
                            <ContactItem innKey={inn} isCopy={false} text={inn} name={'ИНН:'}/>
                            <ContactItem innKey={inn} isCopy={false} text={ogrn} name={'ОГРН:'}/>
                        </div>
                        <div className={'utilizes-card__box--inner'}>
                            <ContactItem
                                innKey={inn}
                                handleShowContact={() => dispatch(countEmail({inn}))}
                                isCopy={true}
                                items={emails}
                                name={'E-mail:'}/>
                            <ContactItem
                                innKey={inn}
                                handleShowContact={() => dispatch(countPhone({inn}))}
                                isCopy={true}
                                items={phone_numbers}
                                name={'Тел:'}/>
                        </div>
                    </div>
                </div>
                <div className={'utilizes-card__col'}>
                    <Services activities={activities}/>
                </div>
            </div>
            <Modal
                className={'utilise-form-content'}
                isOpen={isOpenModal}
                handleClose={() => setOpenModal(false)}>
                <form className={'utilise-form'}>
                    <div className={'utilise-form__box'}>
                        <Input
                            placeholder={'Имя'}
                            isRequired={true}
                            control={control}
                            register={register}
                            fieldName={'name'}/>
                    </div>
                    <div className={'utilise-form__box'}>
                        <InputPhone
                            isRequired={true}
                            phone={phone}
                            onChange={handlePhone}/>
                    </div>
                    <div className={'utilise-form__box'}>
                        <Button
                            icon={null}
                            text={'Позвоните мне'}
                            handler={submit}
                            size={buttonTypes.xl}
                            outline={false}/>
                    </div>
                </form>
            </Modal>
        </div>
    );
};
