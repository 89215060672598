export interface IQueryProps {
    search?: string;
    activities?: string;
    hazard_classes?: string;
    fkko_codes?: string;
    regions?: string;
    page?: number;
}


export const createQueryParams = async ({search, hazard_classes, activities, fkko_codes, regions, page}: IQueryProps) => {
    const url = new URL('https://api.uecolog.ru');
    const params = new URLSearchParams(url.search);

    search && search?.length > 0
        ? await params.set('search', search)
        : await params.delete('search');

    hazard_classes && hazard_classes?.length > 0
        ? await params.set('hazard_classes', hazard_classes)
        : await params.delete('hazard_classes');


    activities && activities?.length > 0
        ? await params.set('activities', activities)
        : await params.delete('activities');


    fkko_codes && fkko_codes?.length > 0
        ? await params.set('fkko_codes', fkko_codes ?? '')
        : await params.delete('fkko_codes');


    regions && regions?.length > 0
        ? await params.set('regions', regions)
        : await params.delete('regions');

    page
        ? await params.set('page', page.toString())
        : await params.delete('page');


    return params.toString();
};
