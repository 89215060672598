import React from 'react';

export const IconTab3 = () => {
    return (
        <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M49.9701 26.0006C49.9701 39.8079 38.7772 51.0009 24.97 51.0012V35.6999C26.5934 36.4716 28.311 37.0504 30.0883 37.418C30.307 37.4661 30.5301 37.4906 30.754 37.4913C31.17 37.4936 31.5824 37.4136 31.9674 37.256C32.3524 37.0983 32.7025 36.8661 32.9974 36.5726L35.5935 34.0031C35.7334 33.8589 35.8389 33.685 35.902 33.4943C35.9651 33.3036 35.9842 33.1011 35.958 32.9019C35.9317 32.7028 35.8608 32.5121 35.7504 32.3443C35.64 32.1765 35.493 32.0358 35.3206 31.9328L30.2947 28.6843C30.1046 28.5704 29.8843 28.517 29.6632 28.5313C29.442 28.5455 29.2304 28.6268 29.0565 28.7642L26.9863 30.4084C26.2818 30.1091 25.6072 29.748 24.97 29.3303V1C38.7772 1.00028 49.9701 12.1933 49.9701 26.0006ZM24.97 34.2206V30.8963C25.5969 31.2512 26.2532 31.5559 26.933 31.8063C27.0366 31.8388 27.1465 31.8455 27.2533 31.8258C27.36 31.8061 27.4603 31.7605 27.5454 31.6932L29.7688 29.9225L34.6216 33.0578L32.0255 35.6273C31.8105 35.8407 31.5459 35.9974 31.2554 36.0834C30.965 36.1694 30.6578 36.1819 30.3613 36.12C28.4775 35.7308 26.665 35.0896 24.97 34.2206Z" fill="#42A5F5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.000732422 25.9987C0.000732422 12.192 11.1926 0.999381 24.9989 0.998047L24.9989 29.3255C24.2739 28.8496 23.5974 28.3003 22.9806 27.6851C21.8126 26.5681 20.8702 25.237 20.2048 23.7642L21.8756 21.7672C22.0216 21.592 22.1075 21.3745 22.1207 21.1468C22.1339 20.9192 22.0737 20.6933 21.9488 20.5024L18.6204 15.583C18.5116 15.4218 18.3688 15.2865 18.202 15.1864C18.0353 15.0863 17.8486 15.024 17.6552 15.0039C17.4687 14.9883 17.2809 15.0123 17.1043 15.0743C16.9277 15.1362 16.7661 15.2347 16.6301 15.3633L13.9673 17.9262C13.5857 18.2841 13.3025 18.7342 13.1451 19.2331C12.9877 19.7321 12.9614 20.2632 13.0687 20.7753C13.9784 24.8843 16.0593 28.6416 19.0598 31.5926C20.815 33.2784 22.8223 34.6608 24.9989 35.6962L24.9989 50.9993C11.1926 50.9979 0.000732422 39.8053 0.000732422 25.9987ZM24.9989 30.892V34.2168C23.1717 33.2793 21.4811 32.0771 19.9851 30.6407C17.1771 27.8663 15.2326 24.3381 14.3867 20.4824C14.3241 20.1957 14.3372 19.8976 14.4248 19.6175C14.5123 19.3374 14.6713 19.0848 14.886 18.8848L17.5487 16.3219L20.7839 21.0682L18.9866 23.2184C18.9079 23.3012 18.8519 23.4029 18.8239 23.5136C18.7959 23.6244 18.7969 23.7405 18.8268 23.8507C19.5328 25.6721 20.641 27.3104 22.0687 28.6436C22.939 29.5277 23.9257 30.2836 24.9989 30.892Z" fill="#64B5F6"/>
        </svg>
    );
};
