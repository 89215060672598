import React from 'react';
import './style.scss';
import {CardSVG} from '../../../../icons';
import {useResizeWindow} from '../../../../hooks';

export const Banner = () => {
    const width = useResizeWindow();

    if(width > 450) {
       return (
           <div className={'container'}>
               <div className={'banner-partner'} >
                   <div className={'banner-partner__box'}>
                       <h2 className={'banner-partner__title'}>
                           Станьте <span className={'banner-partner__title--insert'}>заметнее </span>
                           для клиентов
                       </h2>
                       <p className={'banner-partner__text'}>Получайте больше заявок
                           с услугой размещения</p>
                   </div>
                   <div className={'banner-partner__box'}>
                       <CardSVG />
                   </div>
               </div>
           </div>
       );
    }

    return (
        <div className={'banner-partner'} >
            <div className={'banner-partner__box'}>
                <h2 className={'banner-partner__title'}>
                    Станьте <span className={'banner-partner__title--insert'}>заметнее </span>
                    для клиентов
                </h2>
                <p className={'banner-partner__text'}>Получайте больше заявок
                    с услугой размещения</p>
            </div>
            <div className={'banner-partner__box'}>
                <CardSVG />
            </div>
        </div>
    );
};
