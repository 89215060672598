import React from 'react';

export const IconThreeLineMenu = () => {
    return (
        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H31" stroke="#3252B1" strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 11H31" stroke="#3252B1" strokeWidth="2" strokeLinecap="round"/>
            <path d="M1 21H31" stroke="#3252B1" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
};
