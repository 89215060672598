import React from 'react';
import './style.scss';
import {MainLayout} from '../../layouts';

export const ErrorPage = () => {
    return (
        <MainLayout title={''}>
            <div className={'container error-page'}>
                <p className={'error-page__title'}>404</p>
                <p className={'error-page__text'}>Страница не найдена</p>
            </div>
        </MainLayout>
    );
};
