import React from 'react';
import {IIconProps} from './types';

export const IconRevers = ({width = 36, height = 36, color = '#04BF8A'}: IIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill={color}/>
            <path
                d="M9.875 9.625L15.5 9.625C16.191 9.625 16.75 10.184 16.75 10.875L16.75 16.5C16.75 17.191 16.191 17.75 15.5 17.75C14.809 17.75 14.25 17.191 14.25 16.5L14.25 14.0238C12.6992 15.1945 11.7586 17.0414 11.7586 19.0082C11.7586 22.4492 14.5586 25.25 18 25.25C21.4414 25.25 24.2422 22.4496 24.2422 19.0078C24.2422 17.6453 23.8113 16.3504 22.9945 15.2625C22.5832 14.707 22.6918 13.9258 23.2436 13.5121C23.7967 13.0984 24.5803 13.2094 24.9939 13.7611C26.1377 15.2857 26.7463 17.0955 26.7463 19.0033C26.7461 23.8242 22.8203 27.75 18 27.75C13.1797 27.75 9.25391 23.8242 9.25391 19.0039C9.25391 16.3027 10.5223 13.7695 12.618 12.125L9.875 12.125C9.18398 12.125 8.625 11.566 8.625 10.875C8.625 10.184 9.18398 9.625 9.875 9.625Z"
                fill="white"/>
        </svg>
    );
};
