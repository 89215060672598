import React from 'react';

export const IconBadge1 = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25 50C38.8062 49.9989 49.9981 38.8065 49.9981 25C49.9981 11.1935 38.8062 0.00105353 25 0V11.6641H33.3375C33.7946 11.6641 34.1654 12.0372 34.1654 12.4974C34.1654 12.9608 33.7947 13.3307 33.3375 13.3307H25V14.9974H36.6626C37.1226 14.9974 37.4987 15.3705 37.4987 15.8307C37.4987 16.2942 37.1244 16.6641 36.6626 16.6641H25V18.3281H38.3306C39.2576 18.3281 39.9987 19.0762 39.9987 19.9989V36.6573C39.9987 37.5806 39.2519 38.3281 38.3306 38.3281H25V50ZM25 36.6607C31.3317 36.6601 38.332 36.6591 38.332 36.6573C38.332 36.6573 38.3337 19.9948 38.3306 19.9948C38.3306 19.9948 31.3314 19.9951 25 19.9959V36.6607Z"
                  fill="#4CAF50"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.998 0C11.1918 0.00105391 1.207e-06 11.1935 0 25C-2.18557e-06 38.8065 11.1918 49.9989 24.998 50V38.3281H13.3341C12.4071 38.3281 11.666 37.5801 11.666 36.6573V19.9989C11.666 19.0756 12.4129 18.3281 13.3341 18.3281H24.998V16.6641H15.0021C14.5421 16.6641 14.166 16.291 14.166 15.8307C14.166 15.3673 14.5403 14.9974 15.0021 14.9974H24.998V13.3307H18.3272C17.8701 13.3307 17.4993 12.9576 17.4993 12.4974C17.4993 12.0339 17.87 11.6641 18.3272 11.6641H24.998V0ZM24.998 19.9957C19.1653 19.9963 13.3327 19.9973 13.3327 19.9989C13.3327 19.9989 13.331 36.6615 13.3341 36.6615C13.3341 36.6615 19.1658 36.6612 24.998 36.6606L24.998 19.9957Z"
                  fill="#66BB6A"/>
        </svg>
    );
};
