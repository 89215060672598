import React from 'react';
import {IButtonProps} from './types';

export const Button = (
    {text, icon, handler, size, outline, disabled}: IButtonProps
) => {
    return (<button
        type={'button'}
        disabled={disabled}
        className={`button button--${size} ${outline && 'button__outline'}`}
        onClick={handler}>
        {icon}{text}
    </button>);
};
